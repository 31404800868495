import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { openBasketAction } from '@/store/reducers/basket.reducer';
import { getCurrentUserAction } from '@/store/reducers/user.reducer';
import { getBasketDataSelector } from '@/store/selectors/basket.selector';

import { ReactComponent as BasketIcon } from '@/assets/icons/basket.svg';

const Button = styled('div')({
  position: 'relative',
  marginLeft: '2rem',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const BasketQuantity = styled('p')({
  position: 'absolute',
  zIndex: 100,
  margin: 0,
  marginTop: '3px',
  fontSize: '10px',
  fontWeight: 'bold',
});

export const BasketBtn = () => {
  const dispatch = useDispatch();
  const basketItem = useSelector(getBasketDataSelector);

  const openBasket = () => {
    dispatch(openBasketAction());
  };

  useEffect(() => {
    dispatch(getCurrentUserAction());
  }, []);

  return (
    <Button onClick={openBasket}>
      <BasketIcon />
      {!!basketItem.length && <BasketQuantity>{basketItem.length}</BasketQuantity>}
    </Button>
  );
};
