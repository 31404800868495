import { ImgVideo } from '@/components/img-video/img-video';
import { Button, styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #000',
  padding: '2rem',
  [theme.breakpoints.down('laptop')]: {
    padding: '1rem',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  margin: '0 -1rem',
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '1rem',
  width: '20%',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    width: '33.3333%',
    padding: '1rem',
  },
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));

export const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  paddingBottom: '100%',
  placeholder: {}
});

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const FoundText = styled('div')({
  fontSize: '0.7rem',
  marginBottom: '0.4rem',
  marginLeft: '0.7rem',
  opacity: 0.3,
});

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '1rem',
  },
}));

export const Clear = styled(Button)(({ theme }) => ({
  fontSize: '1.125rem',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#000',
  padding: 0,
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem',
  },
}));

export const Name = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
});

export const SubName = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
  fontSize: 24
});

export const TopName = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
});
