import { ReactComponent as FilterIcon } from '@/assets/icons/filter.svg';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onChangeShowFilter: () => void;
  onChangeShowFilterSlide: () => void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '3.875rem',
  borderBottom: '1px solid #000',
  cursor: 'pointer',
  [theme.breakpoints.down('tablet')]: {
    height: '2.5rem',
  },
}));

const FilterButton = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '8.44rem',
  padding: '0.5rem',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: '1px solid #000',
  cursor: 'pointer',
  [theme.breakpoints.down('tablet')]: {
    width: '3rem',
  },
}));

const FilterIconStyled = styled(FilterIcon)({
  width: '2.5rem',
  height: '2rem',
});

const SearchIconContainer = styled('div')(({ theme }) => ({
  transform: 'rotate(90deg)',
  margin: '0 2rem',
  '& svg': {
    transform: 'scale(1.5)',
  },
  [theme.breakpoints.down('tablet')]: {
    margin: '0.5rem',
  },
}));

const InputStyled = styled('input')({
  height: 'calc(100% - 2px)',
  border: 'none',
  outline: 'none',
  width: '100%',
  fontSize: '1.125rem',
});

export const SearchInput: FC<Props> = (props) => {
  const { value, onChange, onChangeShowFilter, onChangeShowFilterSlide } = props;
  const { t } = useTranslation();
  return (
    <Container>
      <FilterButton onClick={onChangeShowFilterSlide}>
        <FilterIconStyled />
      </FilterButton>
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <InputStyled
        placeholder={t('header.nav.search')}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </Container>
  );
};
