import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { YarnColorName } from '@/components/yarn-color-name';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { Container, Inner } from './styles';

interface Props {
  yarnColors: YarnColorCollectionItem[];
  name: string;
}

export const Colors: FC<Props> = (props) => {
  const { yarnColors, name } = props;
  return (
    <Container>
      <Typography variant='h3'>{name}</Typography>
      <Inner>
        {yarnColors.map((item) => (
          <YarnColorName key={item.id} photo={item.image} name={item.name} readonly={true} />
        ))}
      </Inner>
    </Container>
  );
};
