import { styled } from '@mui/material/styles';
import { PageContainer } from '@/components/page-container/page-container';

export const PageContainerEl = styled(PageContainer)({
  padding: '0 2rem 2rem',
});

export const NameInput = styled('input')({
  padding: '2rem 0',
  border: 'none',
  display: 'block',
  fontSize: '1.875rem',
  width: '100%',
  ':focus': {
    outline: 'none',
  },
});

export const TextArea = styled('textarea')({
  display: 'block',
  width: '100%',
  resize: 'none',
  border: 'none',
  padding: '2rem 0',
  ':focus': {
    outline: 'none',
  },
});
