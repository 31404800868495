import { YarnManufacturerCollectionItem } from '@/common/interfaces/collection.interface';
import { getYarnByManufacturerAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container, Item } from './styles';

interface Props {
  selectedYarn?: YarnCollectionItemWithStage;
  setSelectedYarn: (yarn: YarnCollectionItemWithStage) => void;
  selectedManufacturer?: YarnManufacturerCollectionItem;
  setSelectedManufacturer: (manufacturer: YarnManufacturerCollectionItem) => void;
}

export const Selector: FC<Props> = (props) => {
  const { selectedYarn, setSelectedYarn, selectedManufacturer, setSelectedManufacturer } = props;
  const dispatch = useDispatch();
  const { YarnManufacturer, Yarn } = useSelector(getCollectionDataSelector);

  const handleChangeManufacturer = (manufacturer: YarnManufacturerCollectionItem) => {
    setSelectedManufacturer(manufacturer);
  };

  const handleChangeYarn = (yarn: YarnCollectionItemWithStage) => {
    setSelectedYarn(yarn);
  };

  useEffect(() => {
    if (!YarnManufacturer.length) return;
    setSelectedManufacturer(YarnManufacturer[0]);
  }, [YarnManufacturer]);

  useEffect(() => {
    if (!selectedManufacturer) return;
    dispatch(getYarnByManufacturerAction(selectedManufacturer?.id));
  }, [selectedManufacturer]);

  useEffect(() => {
    if (!Yarn.length) return;
    setSelectedYarn(Yarn[0]);
  }, [Yarn]);

  return (
    <Container>
      <Column>
        {YarnManufacturer.map((el) => (
          <Item
            key={el.id}
            className={el.id === selectedManufacturer?.id ? 'selected' : ''}
            onClick={() => handleChangeManufacturer(el)}
            variant='h5'
          >
            {el.name}
          </Item>
        ))}
      </Column>
      <Column style={{ borderLeft: `1px solid #000` }}>
        {Yarn.map((el) => (
          <Item
            key={el.id}
            className={el.id === selectedYarn?.id ? 'selected' : ''}
            onClick={() => handleChangeYarn(el)}
            variant='h5'
          >
            {el.name}
          </Item>
        ))}
      </Column>
    </Container>
  );
};
