import { Collections } from '@/common/interfaces/collection.interface';
import {
  AccordionWithBlock,
  AccordionWithBlockData,
  AccordionWithBlockItemData,
} from '@/components/accordion-with-block';
import { getCollectionsAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  onChangeAdditional: (value: number, type: Collections | string) => void;
}
export const ResourceFilter: FC<Props> = (props) => {
  const { onChangeAdditional } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Craft, ResourceType, ResourceContentType, Technics } =
    useSelector(getCollectionDataSelector);

  const [accordionData, setAccordionData] = useState<AccordionWithBlockData>({
    expanded: null,
    data: [],
  });

  useEffect(() => {
    dispatch(
      getCollectionsAction([
        Collections.Craft,
        Collections.ResourceType,
        Collections.ResourceContentType,
        Collections.Technics,
      ]),
    );
  }, []);

  useEffect(() => {
    if (Craft.length && ResourceType.length && ResourceContentType.length && Technics.length) {
      setAccordionData({
        expanded: null,
        data: [
          {
            label: t('search.resourceFilter.craft'),
            type: Collections.Craft,
            items: Craft.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.resourceFilter.type'),
            type: Collections.ResourceType,
            items: ResourceType.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.resourceFilter.contains'),
            type: Collections.ResourceContentType,
            items: ResourceContentType.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.resourceFilter.technique'),
            type: Collections.Technics,
            items: Technics.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
        ],
      });
    }
  }, [Craft]);

  const handleExpanded = (value: Collections | string) => {
    setAccordionData((p) => ({
      ...p,
      expanded: p.expanded === value ? null : value,
    }));
  };

  const handleSelect = (value: AccordionWithBlockItemData, type: Collections | string) => {
    if (type === Collections.Craft) {
      setAccordionData((p) => ({
        ...p,
        data: p.data.map((item) => {
          if (item.type === Collections.Craft) {
            return {
              ...item,
              selected: item.selected.includes(value) ? [] : [value],
            };
          } else {
            return item;
          }
        }),
      }));
    } else {
      setAccordionData((p) => ({
        ...p,
        data: p.data.map((item) => {
          if (item.type === type) {
            return {
              ...item,
              selected: item.selected.includes(value)
                ? item.selected.filter((item) => item !== value)
                : [...item.selected, value],
            };
          } else {
            return item;
          }
        }),
      }));
    }
    onChangeAdditional(value.id, type);
  };

  return (
    <>
      <AccordionWithBlock
        data={accordionData}
        onExpanded={handleExpanded}
        onSelect={handleSelect}
      />
    </>
  );
};
