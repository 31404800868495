import { authorizationReducer } from './authorization.reducer';
import { basketReducer } from './basket.reducer';
import { collectionReducer } from './collection.reducer';
import { patternViewReducer } from './pattern-view.reducer';
import { patternReducer } from './pattern.reducer';
import { patternizerReducer } from './patternizer.reducer';
import { resourceReducer } from './resource.reducer';
import { searchReducer } from './search.reducer';
import { systemReducer } from './system.reducer';
import { userReducer } from './user.reducer';
import { yarnSupplierReducer } from './yarn-supplier.reducer';
import { yarnViewReducer } from './yarn-view.reducer';
import { yarniversReducer } from './yarnivers.reducer';
import { pagesContentReducer } from './pages-content.reducer';
import { paymentReducer } from './payment.reducer';
import { resourceAllReducer } from './resource-all.reducer';
import { yarnPromotedReducer } from './yarn-promoted.reducer';
import { yarnUploadedReducer } from './yarn-uploaded.reducer';
import { yarnManufactureWeekReducer } from './yarn-manufacturer-week.reducer';
import { paymentPlanReducer } from './payment-plan.reducer';

export const rootReducer = {
  authorization: authorizationReducer,
  patternizer: patternizerReducer,
  pattern: patternReducer,
  payment: paymentReducer,
  paymentPlan: paymentPlanReducer,
  user: userReducer,
  collection: collectionReducer,
  search: searchReducer,
  patternView: patternViewReducer,
  resource: resourceReducer,
  resourceAll: resourceAllReducer,
  system: systemReducer,
  basket: basketReducer,
  yarnivers: yarniversReducer,
  yarnView: yarnViewReducer,
  yarnSupplier: yarnSupplierReducer,
  pagesContent: pagesContentReducer,
  yarnPromoted: yarnPromotedReducer,
  yarnUploaded: yarnUploadedReducer,
  yarnManufactureWeek: yarnManufactureWeekReducer
};
