import { InputButtonSelect } from '@/components/input-button-select';
import { Grid } from '@mui/material';
import { FC } from 'react';

interface Props {
  items: {
    label: string;
    value: number;
  }[];
  selectedItems: number[];
  onChange: (value: number) => void;
}
export const Selectors: FC<Props> = (props) => {
  const { items, selectedItems, onChange } = props;
  return (
    <Grid container>
      {items.map((el) => (
        <InputButtonSelect
          key={el.value + el.label}
          label={el.label}
          value={el.value}
          checked={selectedItems.includes(el.value)}
          onChange={() => onChange(el.value)}
        />
      ))}
    </Grid>
  );
};
