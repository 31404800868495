import { Dropdown } from '@/components/dropdown';
import { Input } from '@/components/input/input';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setYarnDataAction } from '@/store/reducers/yarnivers.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarnivers';
import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';

interface Props {
  yarn: Yarn;
}

export const Amount: FC<Props> = (props) => {
  const { yarn } = props;

  const dispatch = useDispatch();

  const { Units } = useSelector(getCollectionDataSelector);

  const UnitOptions = useMemo(() => {
    return Units?.map((el) => ({ label: el.name, value: el.id }));
  }, [Units]);

  // Weight
  const handleTypeWeightChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, amount_weight: value }));
  };

  const [typeWeight, setTypeWeight] = useInputWithDelay(
    yarn.amount_weight || '',
    handleTypeWeightChange,
    1000,
  );

  const handleAmountWeightUnitChange = (value: string | number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        amount_weight_unit: value as number,
      }),
    );
  };

  // Length
  const handleTypeLengthChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, amount_lenght: value }));
  };

  const [typeLength, setTypeLength] = useInputWithDelay(
    yarn.amount_lenght || '',
    handleTypeLengthChange,
    1000,
  );

  const handleAmountLengthUnitChange = (value: string | number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        amount_lenght_unit: value as number,
      }),
    );
  };

  return (
    <Container>
      <Grid container direction='row'>
        <Input
          placeholder='Type weight'
          value={typeWeight}
          onChange={(e) => setTypeWeight(e.target.value)}
        />
        <Dropdown
          value={yarn?.amount_weight_unit}
          options={UnitOptions}
          onChange={handleAmountWeightUnitChange}
          placeholder='Select unit of measurement' variant={'filled'}        />
      </Grid>

      <Grid container direction='row'>
        <Input
          placeholder='Type length'
          value={typeLength}
          onChange={(e) => setTypeLength(e.target.value)}
        />
        <Dropdown
          value={yarn?.amount_lenght_unit}
          options={UnitOptions}
          onChange={handleAmountLengthUnitChange}
          placeholder='Select unit of measurement' variant={'filled'}        />
      </Grid>
    </Container>
  );
};
