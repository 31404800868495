import { Grid, styled, Typography } from '@mui/material';

export const arrowDown =  styled(Grid)({
  transform: 'rotate(90deg)'
})

export const Container = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    marginTop: '1rem',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'scroll',
  },
}));

export const MenuItem = styled(Grid)(({ theme }) => ({
  padding: '2rem',
  borderTop: '1px solid #000',
  cursor: 'pointer',
  alignItems: 'center',
  ':last-child': {
    borderBottom: '1px solid #000',
  },
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    borderBottom: '1px solid #000',
    flexWrap: 'nowrap',
    padding: '1rem',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    whiteSpace: 'nowrap',
  },
}));

export const MenuDescriptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '1rem',
  [theme.breakpoints.down('laptop')]: {
    whiteSpace: 'nowrap',
  },
}));

export const MenuDescription = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  marginRight: '1rem',
  textAlign: 'right',
}));
