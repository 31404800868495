import React, { FC } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export interface LinkProps extends RouterLinkProps {}

const LinkEl = styled(RouterLink)({
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  textDecoration: 'underline',
  color: '#000',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const Link: FC<LinkProps> = ({ children, ...rest }) => {
  return <LinkEl {...rest}>{children}</LinkEl>;
};
