import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  padding: '2rem',
});

export const Label = styled(Typography)({
  // marginBottom: '2rem',
});

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});
