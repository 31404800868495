import { PATH } from '@/common/constants/path';
import { Button } from '@/components/button/button';
import { PageContainer } from '@/components/page-container/page-container';
import {
  getYarnColorsAction,
  getYarnPricesAction,
  publishYarnAction,
} from '@/store/reducers/yarnivers.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import {
  getYarniversColorsSelector,
  getYarniversCurrentStepSelector,
  getYarniversDataSelector,
  getYarnPricesSelector,
} from '@/store/selectors/yarnivers.selector';
import { styled } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { YarniversStepsKeys } from '../steps';
import { Colors } from './components/colors';
import { Info } from './components/info';
import { Photo } from './components/photo';
import { Summary } from './components/summary';
import { WashAndCare } from './components/wash-and-care';
import { Buttons, ButtonsWrapper, Header, PromotedButton } from './style';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Block = styled('div')({
  width: '50%',
  padding: '2rem',
  border: '1px solid #000',
  borderTop: 'none',

  '&:first-child': {
    borderRight: 'none',
  },
});

export const Advertisement = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const yarn = useSelector(getYarniversDataSelector);
  const user = useSelector(getCurrentUserSelector);
  const yarnColors = useSelector(getYarniversColorsSelector);
  const prices = useSelector(getYarnPricesSelector);
  const currentStep = useSelector(getYarniversCurrentStepSelector);

  useEffect(() => {
    if (yarn.prices?.length && yarn.id) {
      dispatch(getYarnPricesAction(yarn.id));
    }
  }, [yarn.prices]);

  useEffect(() => {
    if (currentStep === YarniversStepsKeys.Advertisement && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
    }
  }, [currentStep, yarn.id]);

  const handlePublish = () => {
    dispatch(
      publishYarnAction({
        yarnId: yarn.id as number,
        callback() {
          navigate(PATH.PROFILE);
        },
      }),
    );
  };

  return (
    <PageContainer>
      <Header>
        <Photo yarn={yarn} yarnColors={yarnColors}/>
        <Info yarn={yarn} username={user?.username || ''} prices={prices || []} />
      </Header>

      <Row>
        <Block>
          <Summary yarn={yarn} />
        </Block>
        <Block>
          <WashAndCare yarn={yarn} />
        </Block>
      </Row>

      <Colors name={yarn.name || ''} yarnColors={yarnColors} />

      <ButtonsWrapper>
        <Buttons>
          <PromotedButton>Promote yarn</PromotedButton>
          <Button colored onClick={handlePublish}>
            Publish
          </Button>
        </Buttons>
      </ButtonsWrapper>
    </PageContainer>
  );
};
