import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { YarnManufacturerShipmentMethodWithStage } from '@/store/types/yarn-supplier';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { ColorPicker } from '../color-picker';
import { QuantityController } from '../quantity-controller';
import { Delivery } from './components/delivery';
import {
  Container,
  Image,
  Info,
  Left,
  Main,
  NameDescription,
  Price,
  Right,
  SelectColor,
} from './styles';

interface Props {
  name: string;
  description: string;
  image: string;
  price: number;
  quantity: number;
  colors?: YarnColorCollectionItem[];
  onColorChange?: (colorId: number) => void;
  onQuantityChange?: (value: number) => void;
  shipmentMethods?: YarnManufacturerShipmentMethodWithStage[];
  from?: number | null;
  to?: number | null;
}

export const YarnItem: FC<Props> = (props) => {
  const {
    name,
    description,
    image,
    price,
    quantity,
    colors,
    onColorChange,
    onQuantityChange,
    shipmentMethods,
    from,
    to,
    ...rest
  } = props;

  const [colorAnchor, setColorAnchor] = useState<HTMLDivElement | null>(null);

  const openPicker = (event: MouseEvent<HTMLDivElement>) => {
    setColorAnchor(event.currentTarget);
  };

  const closePicker = () => {
    setColorAnchor(null);
  };

  return (
    <>
      <Container {...rest}>
        <Main>
          <Image src={getAssetBackendUrl(image)} />
          <Info>
            <Left>
              <NameDescription>
                <Typography variant='h5' fontWeight='bold' style={{ textDecoration: 'underline' }}>
                  {name}
                </Typography>
                <Typography variant='body1'>{description}</Typography>
              </NameDescription>

              <SelectColor variant='body1' onClick={openPicker}>
                Select color
              </SelectColor>
            </Left>
            <Right>
              <QuantityController value={quantity} onChange={onQuantityChange} />
              <Price variant='h4'>{price}, - NOK</Price>
            </Right>
          </Info>
        </Main>
        <Delivery shipmentMethods={shipmentMethods || []} from={from} to={to} />
      </Container>
      {!!onColorChange && (
        <ColorPicker
          anchorEl={colorAnchor}
          onClose={closePicker}
          colors={colors}
          onSelect={(colorId) => !!onColorChange && onColorChange(colorId)}
        />
      )}
    </>
  );
};
