import React, { FC } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { useRadioGroup } from '@mui/material/RadioGroup';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

export interface RadioProps extends Omit<FormControlLabelProps, 'control'> {
  inputProps?: MuiRadioProps;
}

export interface CheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  inputProps?: MuiCheckboxProps;
}

const BpIcon = styled('span')({
  display: 'block',
  position: 'relative',
  border: '1px solid #000',
  width: '1.25rem',
  height: '1.25rem',
});

const BpCheckedIcon = styled(BpIcon)({
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: '#000',
    borderRadius: '50%',
  },
});

const FormControlLabelEl = styled(FormControlLabel)(({ checked }) => ({
  border: '1px solid #000',
  paddingLeft: '0.5rem',
  backgroundColor: checked ? '#F6F4ED' : 'none',
  margin: '0 0.5rem 0.5rem 0',
  transition: 'background-color 200ms',
}));

export const RadioInput: FC<RadioProps> = ({ inputProps, ...rest }) => {
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === rest.value;
  }

  return (
    <FormControlLabelEl
      labelPlacement='start'
      checked={checked}
      {...rest}
      control={<MuiRadio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...inputProps} />}
    />
  );
};

export const CheckboxInput: FC<CheckboxProps> = ({ inputProps, ...rest }) => {
  return (
    <FormControlLabelEl
      labelPlacement='start'
      {...rest}
      control={<MuiCheckbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...inputProps} />}
    />
  );
};
