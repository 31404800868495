import { PATH } from '@/common/constants/path';
import { TermsPrivacyAboutPageType } from '@/pages/terms-privacy-about-page/terms-privacy-about-page';
import { List, ListItem, ListItemProps, ListItemText, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../assets/logo.svg';

export interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation();


  const StackFooter = styled(Stack)(({ theme }) => ({
    borderTop: '1px solid #000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1.5rem 1rem 1.5rem 1rem',
    [theme.breakpoints.down('tablet')]: {
      display: "none"
    },
  }));

  const StackLinksAndText = styled(Stack)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
  }));

  const ListItemStyled = styled(ListItem)<ListItemProps>(({ theme }) => ({
    paddingBottom: '0!important',
    paddingTop: '0!important',
    a: {
      textDecoration: 'none',
      color: '#000000',
    },
  }));

  return (
    <StackFooter>
      <StackLinksAndText>
        <List component='nav'>
          <ListItemStyled component='div'>
            <RouterLink to={`${PATH.HOME}`}>
              <img src={Logo} className='footer__logo' alt={t('main.title')} />
            </RouterLink>
          </ListItemStyled>

          <ListItemStyled component='div'>
            <RouterLink to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.ABOUT}`}>
              <ListItemText primary={t('footer.about')} />
            </RouterLink>
          </ListItemStyled>
          <ListItemStyled component='div'>
            <RouterLink
              to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.PRIVACY}`}
            >
              <ListItemText primary={t('footer.privacyPolicy')} />
            </RouterLink>
          </ListItemStyled>
          <ListItemStyled component='div'>
            <RouterLink to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.TERMS}`}>
              <ListItemText primary={t('footer.termsConditions')} />
            </RouterLink>
          </ListItemStyled>
          <ListItemStyled component='div'>
            <ListItemText primary={t('footer.copyright')} />
          </ListItemStyled>
        </List>
      </StackLinksAndText>

      <StackLinksAndText>
        <List component='nav'>
          <ListItemStyled component='div'>
            <ListItemText primary={t('footer.contact')} />
          </ListItemStyled>
          <ListItemStyled component='div'>
            <RouterLink to={`mailto:${t('footer.emailAddress')}`}>{t('footer.emailAddress')}</RouterLink>
          </ListItemStyled>
        </List>
      </StackLinksAndText>
    </StackFooter>
  );
};
