import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PageContainer } from '@/components/page-container/page-container';
import { TextEditor } from '@/components/text-editor/text-editor';

export const PageContainerEl = styled(PageContainer)(({ theme }) => ({
  padding: '0 0',
  borderTop: '1px solid #000',
  display: 'flex',
  [theme.breakpoints.down('tablet')]: {
    display: 'block',
  },
}));

export const TopSection = styled('section')({
  borderTop: '1px solid #000',
  padding: '1.25rem 0',
});

export const TopContainer = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ContentEl = styled('div')({
  flexGrow: 1,
});

export const TabsTitle = styled('p')(({ theme }) => ({
  margin: '0',
  fontSize: '1.25rem',
  fontWeight: 500,
  minWidth: '22rem',
  width: '22rem',
  paddingRight: '2rem',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    marginBottom: '2rem',
    paddingRight: 0,
  },
}));

export const TabsSection = styled('section')({
  borderTop: '1px solid #000',
  padding: '1.25rem 0',
});

export const TabsContainer = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '0 1rem',
  },
}));

export const TextEditorEl = styled(TextEditor)({
  minHeight: '15rem',
  display: 'flex',
  flexDirection: 'column',
  '.ql-container': {
    flexGrow: 1,
  },
});
