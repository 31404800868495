import {
  YarnColorCollectionItem,
  YarnImageCollectionItem,
} from '@/common/interfaces/collection.interface';
import { PhotoUploader } from '@/components/photo-uploader';
import {
  getYarnImagesAction,
  setYarnDataAction,
  uploadYarnImageAction,
} from '@/store/reducers/yarnivers.reducer';
import { getYarnImagesSelector } from '@/store/selectors/yarnivers.selector';
import { Yarn } from '@/store/types/yarnivers';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { YarnColorName } from '@/components/yarn-color-name';

interface Props {
  yarnColors: YarnColorCollectionItem[];
  yarn: Yarn;
}

export const Photo: FC<Props> = (props) => {
  const { yarn, yarnColors, ...rest } = props;
  const images = useSelector(getYarnImagesSelector);
  const dispatch = useDispatch();

  const onChange = (file: File) => {
    dispatch(
      uploadYarnImageAction({
        yarn,
        image: file,
      }),
    );
  };

  const setMainImage = (img: YarnImageCollectionItem) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        images: images.map((image) => image.id),
        main_image: img?.id,
      }),
    );
  };

  useEffect(() => {
    console.log('yarn.images', yarn.images);
    if (yarn.images?.some((image) => !images.map((el) => el.id).includes(image))) {
      dispatch(
        getYarnImagesAction({
          yarn,
        }),
      );
    }
  }, [yarn.images]);

  useEffect(() => {
    // If there is no main image and there are yarn colors with images
    console.log('yarnColors', yarnColors);

    if (yarnColors.length > 0) {
      console.log('yarnColors', yarnColors);
      const firstColorWithImage = yarnColors.find((color) => color.image);
      console.log('firstColorWithImage', firstColorWithImage);
      if (firstColorWithImage) {
        // const imageId =  firstColorWithImage.image; //parseInt(firstColorWithImage.id, 10); // Convert string to number
        // console.log('imageId', imageId);
        // if (imageId) {
        //   dispatch(
        //     setYarnDataAction({
        //       ...yarn,
        //       images: [...(yarn.images || []), parseInt(imageId, 10)],
        //       main_image: parseInt(imageId, 10), // Set the first color's image as the main image
        //     }),
        //   );
        // }
      }
    }
  }, [yarnColors]);

  return (
    <img src={getAssetBackendUrl(yarnColors[0]?.image)} />
    // <YarnColorName
    //   key={yarnColors[0].id}
    //   id={yarnColors[0].id}
    //   photo={yarnColors[0].image}
    //   name={yarnColors[0].name}
    //   yarnId={yarn.id as number}
    // />
    // <PhotoUploader
    //   mainImageId={yarn.main_image}
    //   images={images}
    //   onMain={setMainImage}
    //   onUpload={onChange}
    //   buttonText='Upload Yarn Image'
    //   {...rest}
    // />
  );
};