import { CollectionState } from '@/store/reducers/collection.reducer';

export const getSelectedLabels = (
  collectionData: CollectionState['data'][keyof CollectionState['data']],
  categorizationData: number | number[],
): string => {
  const isArray = Array.isArray(categorizationData);
  if (isArray && !categorizationData.length) {
    return '';
  }

  return (isArray ? categorizationData : [categorizationData])
    .map((itemId) => {
      // @ts-ignore
      return collectionData.find(({ id }) => id === itemId)?.name;
    })
    .filter(Boolean)
    .join(', ');
};
