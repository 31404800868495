import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';
import { decrementItemInBasketAction, incrementItemInBasketAction } from '@/store/reducers/basket.reducer';
import { BasketItemData, BasketItem } from '@/store/types/basket';
import { ProductType } from '@/common/constants/product-type';

interface Props {
    id: number;
    type: ProductType;
    data?: BasketItemData;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '2rem',
  width: '8rem',
});

const ClickerButton = styled('button')(({ theme }) => ({
  flex: 1,
  ...theme.typography.h4,
  textAlign: 'center',
  padding: 0,
  cursor: 'pointer',
  border: `1px solid ${theme.palette.primary.text}`,
  backgroundColor: 'transparent',
  '&:disabled': {
    cursor: 'initial',
    opacity: 0.5,
  },
}));

const ClickerCount = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.text}`,
  borderBottom: `1px solid ${theme.palette.primary.text}`,
  flex: 2,
  textAlign: 'center',
}));

export const CounterClicker: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { id, type, data} = props;

  const quantity = data?.quantity ?? 0;

  const handleIncrementItem = async (payload: BasketItem) => {
    dispatch(incrementItemInBasketAction(payload));
  }
  const handleDecrementItem = async (payload: BasketItem) => {
    dispatch(decrementItemInBasketAction(payload));
  }

  return (
    <Container>
      <ClickerButton 
        onClick={() => handleDecrementItem({ id, type, data })}
        disabled={quantity === 1}
      >
        -
      </ClickerButton>
      <ClickerCount>{quantity}</ClickerCount>
      <ClickerButton onClick={() => handleIncrementItem({ id, type, data })}>+</ClickerButton>
    </Container>
  );
};

export default CounterClicker;