import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material';
import { ImgVideo } from '@/components/img-video/img-video';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    height: '40vw',
  },
}));

export const PictureContainer = styled('div')({
  position: 'relative',
  height: '100%',
  width: '100%',
});

export const Picture = styled(ImgVideo)({
  width: '100%',
  height: '100%',
  display: 'block',
});

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  justifyContent: 'space-between',
  padding: '1rem 2rem',
  zIndex: 2,
  backgroundColor: 'rgba(255,255,255, .5)',
  [theme.breakpoints.down('laptop')]: {
    padding: '0.5rem',
  },
}));

export const ButtonText = styled('div')(({ theme }) => ({
  fontSize: '1.375rem',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '0.8rem',
  },
}));

export const MainCheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const MainCheckbox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '2rem',
  height: '2rem',
  background: '#fff',
  border: '1px solid #000',
  marginLeft: '1rem',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    width: '1rem',
    height: '1rem',
    marginLeft: '0.5rem',
  },
  '&.checked:before': {
    content: '""',
    display: 'block',
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    background: '#000',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

export const Prev = styled(ChevronLeft)({
  position: 'absolute',
  left: '1rem',
  top: '50%',
  bottom: '50%',
  transform: 'scale(2.5)',
  cursor: 'pointer',
});

export const Next = styled(ChevronRight)({
  position: 'absolute',
  right: '1rem',
  top: '50%',
  bottom: '50%',
  transform: 'scale(2.5)',
  cursor: 'pointer',
});
