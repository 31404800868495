import { DirectusFields } from '@/common/constants/directus-fields';
import { Collections, DiagramCollectionItem } from '@/common/interfaces/collection.interface';
import { BoughtPatternWithCollection, normalizePattern, PatternWithCollection } from '@/utils/normalizePattern';
import { normalizeResource, ResourceWithCollection } from '@/utils/normalizeResource';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  getBoughtPatternByIdAction,
  getBoughtPatternByIdErrorAction,
  getBoughtPatternByIdSuccessAction,
  getDiagramByIdAction,
  getDiagramByIdErrorAction,
  getDiagramByIdSuccessAction,
  getPatternByIdAction,
  getPatternByIdErrorAction,
  getPatternByIdSuccessAction,
  getResourceByIdAction,
  getResourceByIdErrorAction,
  getResourceByIdSuccessAction,
} from '../reducers/pattern-view.reducer';
import { BoughtPatternWithStage } from '../types/pattern';

function* getPatternViewRequest(api: ApiType, action: ReturnType<typeof getPatternByIdAction>) {
  try {
    const id = action.payload;

    const response: PatternWithCollection = yield call(
      api.getCollectionItemById,
      Collections.Patterns,
      id,
      {
        fields: DirectusFields.Pattern,
      },
    );

    if (response) {
      yield put(getPatternByIdSuccessAction(normalizePattern(response)));
    } else {
      throw new Error();
    }
  } catch (err) {
    yield put(getPatternByIdErrorAction());
  }
}

function* getBoughtPatternViewRequest(api: ApiType, action: ReturnType<typeof getBoughtPatternByIdAction>) {
  try {
    const id = action.payload;

    const response: BoughtPatternWithStage = yield call(
      api.getCollectionItemById,
      Collections.Payments,
      id,
      {
        fields: DirectusFields.BoughtPatternProfile,
      },
    );

    if (response) {
      yield put(getBoughtPatternByIdSuccessAction(response));
    } else {
      throw new Error();
    }
  } catch (err) {
    yield put(getBoughtPatternByIdErrorAction());
  }
}

function* getResourceByIdRequest(api: ApiType, action: ReturnType<typeof getResourceByIdAction>) {
  try {
    const id = action.payload;

    const response: ResourceWithCollection = yield call(
      api.getCollectionItemById,
      Collections.Resources,
      id,
      {
        fields: DirectusFields.Resource,
      },
    );
    if (!response) {
      throw new Error();
    }
    yield put(getResourceByIdSuccessAction(normalizeResource(response)));
  } catch (err) {
    yield put(getResourceByIdErrorAction());
  }
}

function* getDiagramByIdRequest(api: ApiType, action: ReturnType<typeof getDiagramByIdAction>) {
  try {
    const id = action.payload;

    const response: DiagramCollectionItem = yield call(
      api.getCollectionItemById,
      Collections.Diagrams,
      id,
    );

    if (!response) {
      throw new Error();
    }
    yield put(getDiagramByIdSuccessAction(response));
  } catch (err) {
    yield put(getDiagramByIdErrorAction());
  }
}

export const patternViewSaga = function* (api: ApiType) {
  yield all([takeLatest(getPatternByIdAction.type, getPatternViewRequest, api)]);
  yield all([takeLatest(getBoughtPatternByIdAction.type, getBoughtPatternViewRequest, api)]);
  yield all([takeLatest(getResourceByIdAction.type, getResourceByIdRequest, api)]);
  yield all([takeLatest(getDiagramByIdAction.type, getDiagramByIdRequest, api)]);
};
