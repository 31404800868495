import { Grid, styled, Typography } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {},
}));

export const MenuCol = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {},
}));

export const ContentCol = styled(Grid)(({ theme }) => ({
  borderLeft: '1px solid #000',
  overflow: 'auto',
  [theme.breakpoints.down('laptop')]: {
    borderLeft: 'none',
    minHeight: 'auto',
  },
}));

export const TopContainer = styled('div')(({ theme }) => ({
  padding: '2rem',
  [theme.breakpoints.down('laptop')]: {
    padding: '1rem',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: '0 0 2rem',
  [theme.breakpoints.down('laptop')]: {
    margin: '0 0 1rem',
    fontSize: '1.5rem',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  margin: '0',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem',
  },
}));
