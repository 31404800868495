import { Input } from '@/components/input/input';
import { styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, Grid } from '@mui/material';

export const Container = styled(Grid)({
  border: '1px solid #000',
});

export const FilterCol = styled(Grid)(({ theme }) => ({
  borderRight: '1px solid #000',
  [theme.breakpoints.down('laptop')]: {
    borderRight: 'none',
  },
}));

export const InputStyled = styled(Input)({
  width: '100%',

  '& .MuiInput-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root:before': {
    border: 'none !important',
  },
});

export const SearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  borderTop: '1px solid #000',
  alignItems: 'center',
});

export const SearchIconStyled = styled(SearchIcon)({
  marginLeft: '1rem',
});

export const Title = styled(Typography)(({ theme }) => ({
  padding: '2rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem',
    fontSize: '2.5rem',
  },
}));
