import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Typography } from '@mui/material';
import { FC } from 'react';
import {
  Container,
  Item,
  Title,
  WithoutColorButton,
  WithoutColorContainer,
  Wrapper,
} from './styles';

interface Props {
  yarn?: YarnCollectionItemWithStage;
  title?: string;
  onAddYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
}

export const Colors: FC<Props> = (props) => {
  const { yarn, title, onAddYarn } = props;

  return (
    <Container>
      <Title variant='h2'>{title}</Title>
      <Wrapper>
        {yarn?.colors.map((color) => (
          <Item key={color.id} onClick={() => onAddYarn(yarn, color)}>
            <img src={getAssetBackendUrl(color?.image)}></img>
            <Typography variant='h5' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {color?.name}
            </Typography>
          </Item>
        ))}
      </Wrapper>
      {!yarn?.colors.length && (
        <WithoutColorContainer>
          <WithoutColorButton onClick={() => onAddYarn(yarn as YarnCollectionItemWithStage, null)}>
            Add yarn without color
          </WithoutColorButton>
        </WithoutColorContainer>
      )}
    </Container>
  );
};
