import { YarnPriceCollectionItem } from '@/common/interfaces/collection.interface';
import { PageContainer } from '@/components/page-container/page-container';
import {
  getMeasurementsByPatternAction,
  getYarnAlternativeByPatternAction,
  setAlternativeItemsColorAction,
  setQuantityWithSizeAction,
} from '@/store/reducers/collection.reducer';
import { getYarnManufacturerBySupplierAction } from '@/store/reducers/yarn-supplier.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { getYarnManufacturerBySupplierSelector } from '@/store/selectors/yarn-supplier.selector';
import { QuantityWithSizeCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Alternative } from './components/alternative';
import { Info } from './components/info';
import { Item } from './components/item';
import { MeasurementSize } from './components/measurement-size';
import { Photo } from './components/photo';
import {
  Container,
  Header,
  ItemContainer,
  NeedleDescription,
  NeedleTitle,
  PublishButton,
  Row,
  TotalQuantity,
  TotalTitle,
  YarnItemStyled,
} from './styles';

interface Props {
  onPressNextButton: () => void;
}

export const Advertisement: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pattern = useSelector(getPatternDataSelector);
  const availableSizes = useSelector(getPatternAvailableSizesSelector);
  const yarnManufacturer = useSelector(getYarnManufacturerBySupplierSelector);

  const { currentStep } = useSelector(getPatternizerSelector);

  const { YarnAlternative, Measurement } = useSelector(getCollectionDataSelector);
  const { Needle } = useSelector(getCollectionDataSelector);

  const [selectedSize, setSelectedSize] = useState<number>(availableSizes[0] || 0);
  const [selectedYarnAlternative, setSelectedYarnAlternative] = useState<number>(
    YarnAlternative[0]?.id || 0,
  );

  const isTabOpen = useMemo(
    () => currentStep === PatternizerStepsKeys.ADVERTISEMENT,
    [currentStep],
  );

  const mockPriceForNeedle: YarnPriceCollectionItem = {
    id: 1,
    price: 100,
    currency: {
      id: 1,
      name: 'NOK',
      code: 'NOK',
      flag: null,
    },
  };

  const yarnTotal = useMemo(() => {
    return YarnAlternative.reduce(
      (acc, crr) =>
        (acc += crr.alternativ_items.reduce(
          (a, c, i, arr) =>
            (a +=
              c?.yarn?.prices[0]?.price *
                arr[i].quantity_with_size.reduce((aq, cq) => (aq += cq.quantity || 0), 0) || 0),
          0,
        )),
      0,
    );
  }, [YarnAlternative]);

  const needleTotal = useMemo(() => {
    return Needle.reduce(
      (acc, crr, i, arr) => (acc += mockPriceForNeedle.price * arr[i].quantity || 0),
      0,
    );
  }, [Needle]);

  const total = useMemo(
    () => needleTotal + yarnTotal + (pattern.price || 0),
    [needleTotal, yarnTotal, pattern.price],
  );

  const getQuantity = useCallback(
    (id: number) => {
      const quantity = YarnAlternative.find((el) => el.id === selectedYarnAlternative)
        ?.alternativ_items.find((el) => el.id === id)
        ?.quantity_with_size.find((el) => el.size_chart === selectedSize);
      return {
        quantityId: quantity?.id,
        value: quantity?.quantity || 0,
      };
    },
    [YarnAlternative, selectedYarnAlternative, selectedSize],
  );

  const handleColorChange = (colorId: number, alternativeId: number) => {
    dispatch(setAlternativeItemsColorAction({ color: colorId, alternative: alternativeId }));
  };

  const handleQuantityChange = (alternativeId: number, quantityId: number, value: number) => {
    dispatch(
      setQuantityWithSizeAction({
        alternativeItemId: alternativeId,
        quantityId,
        sizeId: selectedSize,
        value,
      }),
    );
  };

  const calculateYarnAlternativeQuantity = () => {
    for (const alternative of YarnAlternative) {
      for (const item of alternative.alternativ_items) {
        for (const measurement of item.yarn_measurement) {
          const quantityId = (
            item.quantity_with_size as QuantityWithSizeCollectionItemWithStage[]
          ).find((el) => el.size_chart === measurement.size)?.id;
          if (!quantityId) {
            dispatch(
              setQuantityWithSizeAction({
                alternativeItemId: item.id,
                quantityId,
                sizeId: measurement.size,
                value: Math.ceil((+measurement?.value || 0) / item.yarn.amount_weight),
              }),
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isTabOpen) {
      calculateYarnAlternativeQuantity();
    }
  }, [isTabOpen]);

  useEffect(() => {
    if (isTabOpen && pattern.id) {
      dispatch(getYarnAlternativeByPatternAction());
      dispatch(getMeasurementsByPatternAction(pattern.id));
    }
  }, [isTabOpen, pattern.id]);

  useEffect(() => {
    if (!YarnAlternative.length && isTabOpen) {
      setSelectedSize(availableSizes[0]);
      setSelectedYarnAlternative(YarnAlternative[0]?.id);
    }
  }, [isTabOpen, YarnAlternative]);

  useEffect(() => {
    if (!pattern.user_created) return;
    dispatch(
      getYarnManufacturerBySupplierAction({
        userId: pattern.user_created,
      }),
    );
  }, [isTabOpen, pattern]);

  return (
    <PageContainer>
      <Header>
        <Photo pattern={pattern} />
        <Info pattern={pattern} />
      </Header>
      <Container>
        <MeasurementSize
          availableSizes={availableSizes}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          measurements={Measurement}
        />
        <Alternative
          yarnAlternative={YarnAlternative}
          selectedYarnAlternative={selectedYarnAlternative}
          setSelectedYarnAlternative={setSelectedYarnAlternative}
        />
        <ItemContainer>
          {YarnAlternative.find((el) => el?.id === selectedYarnAlternative)?.alternativ_items.map(
            (el) => (
              <YarnItemStyled
                key={el.id + '_alternative'}
                name={el?.yarn?.name}
                description={el?.yarn?.description}
                image={el?.color?.image}
                price={el?.yarn?.prices[0]?.price || 0}
                quantity={getQuantity(el.id)?.value}
                colors={el?.yarn?.colors}
                onColorChange={(colorId) => handleColorChange(colorId, el.id)}
                onQuantityChange={(value) =>
                  handleQuantityChange(el.id, getQuantity(el.id)?.quantityId as number, value)
                }
                shipmentMethods={yarnManufacturer?.shipment_methods || []}
                from={yarnManufacturer?.estimated_delivery_day_from}
                to={yarnManufacturer?.estimated_delivery_day_to}
              />
            ),
          )}
        </ItemContainer>
        <TotalTitle>{t('patternizerSteps.advertisement.yarnTotal')}</TotalTitle>
        <TotalQuantity>
          {yarnTotal}
          ,- NOK
        </TotalQuantity>
        <NeedleTitle>{t('patternizerSteps.advertisement.requiredNeedles')}</NeedleTitle>
        <NeedleDescription>{t('patternizerSteps.advertisement.needlesDescription')}</NeedleDescription>
        <ItemContainer>
          {Needle.map((el, i) => (
            <Item
              id={el.id}
              key={el.id}
              type='needle'
              name={'Needle ' + (i + 1)}
              image={getAssetBackendUrl('7b53f5a6-75b2-4598-9034-f806cbfad6c9')}
              prices={[mockPriceForNeedle]}
              description={'item description'}
              selectedSize={selectedSize}
              quantities={el.quantity}
              pattern={pattern}
            />
          ))}
        </ItemContainer>
        <Row>
          <div>
            <TotalTitle>{t('patternizerSteps.advertisement.total')}</TotalTitle>
            <TotalQuantity>{total},- NOK</TotalQuantity>
          </div>
          <PublishButton colored onClick={onPressNextButton}>
            {t('patternizerSteps.advertisement.preview')}
          </PublishButton>
        </Row>
      </Container>
    </PageContainer>
  );
};
