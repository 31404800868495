import React, { FC } from 'react';
import { styled } from '@mui/material/styles';

import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';

const TabContentEl = styled('span')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '5rem',
  fontSize: '1.375rem',
  color: '#000',
  fontWeight: 400,
  textTransform: 'none',
  textAlign: 'left',
  span: {
    fontSize: '1rem',
    marginLeft: '2rem',
    textAlign: 'right',
  },
});

interface TabContentProps {
  value: string;
  descr: string;
}

export const TabContent: FC<TabContentProps> = ({ value, descr }) => (
  <TabContentEl>
    {value}
    <span>{descr}</span>
  </TabContentEl>
);

const TabEl = styled(MuiTab)({
  maxWidth: 'none',
  paddingRight: '4rem',
  '&.Mui-selected': {
    backgroundColor: '#F6F4ED',
  },
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '1.5rem',
    margin: 'auto',
    width: '1rem',
    height: '1rem',
    border: '2px solid #000',
    borderTop: 'none',
    borderLeft: 'none',
    transform: 'rotate(-45deg)',
  },
});

interface TabProps extends MuiTabProps {}

export const Tab: FC<TabProps> = (props) => <TabEl {...props} />;
