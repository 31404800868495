import { Collections, TPACollectionItem } from '@/common/interfaces/collection.interface';
import { PageContainer } from '@/components/page-container/page-container';
import { getCollectionsAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { styled } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { TermsAndConditionsTabs } from './components/terms-and-conditions-tabs';
import { ContentContainer } from './components/content-container';


export enum TermsPrivacyAboutPageType {
  ABOUT = 'ABOUT',
  PRIVACY = 'PRIVACY',
  TERMS = 'TERMS',
}



const Container = styled(PageContainer)(({ theme }) => ({
  padding: 0,
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
  },
}));

const Title = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  padding: '2rem',
  margin: 0,
  width: '100%',
  maxHeight: '119px', // This is here to fix an issue with the Terms & Conditions page getting a different height than the other pages due to the ES Allianz Font having a different font height for the '&' characters
  borderBottom: '1px solid #000',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '2rem',
  },
}));



const TermPrivacyAboutPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { TermCondition, PrivacyPolicy, About } = useSelector(getCollectionDataSelector);
  const [type, setType] = useState<TermsPrivacyAboutPageType>();

  useEffect(() => {
    setType(searchParams.get('type') as TermsPrivacyAboutPageType);
  }, [searchParams]);

  useEffect(() => {
    if (type === TermsPrivacyAboutPageType.PRIVACY) {
      dispatch(getCollectionsAction(Collections.PrivacyPolicy));
    } else if (type === TermsPrivacyAboutPageType.TERMS) {
      dispatch(getCollectionsAction(Collections.TermCondition));
    } else {
      dispatch(getCollectionsAction(Collections.About));
    }
  }, [type]);

  const getTitle = useMemo(() => {
    switch (type) {
      case TermsPrivacyAboutPageType.TERMS:
        return 'Terms & Conditions';
      case TermsPrivacyAboutPageType.PRIVACY:
        return 'Privacy Policy';
      case TermsPrivacyAboutPageType.ABOUT:
        return 'About Us';
    }
  }, [type]);

  return (
    <Container>
      <Title>{getTitle}</Title>

      {type === TermsPrivacyAboutPageType.TERMS &&
        <TermsAndConditionsTabs data={TermCondition} />
      }

      {type === TermsPrivacyAboutPageType.PRIVACY && <ContentContainer data={PrivacyPolicy[0]} />}

      {type === TermsPrivacyAboutPageType.ABOUT && <ContentContainer data={About[0]} />}
    </Container>
  );
};




export default TermPrivacyAboutPage;
