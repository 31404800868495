import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderBottom: '1px solid #000',
  overflow: 'hidden',
  position: 'relative',
  padding: '2rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
    padding: '1rem',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'scroll',
  height: '100%',
  width: '100%',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: '0 0 3rem',
  height: '4rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
    margin: '0 0 2rem',
    fontSize: '2rem',
  },
}));

export const Item = styled('div')({
  width: '10.9375rem',
  height: '10.9375rem',
  margin: '0 0.5rem',
  cursor: 'pointer',

  '& img': {
    width: '10.9375rem',
    height: '10.9375rem',
  },

  '& h5': {
    width: '100%',
    borderBottom: '1px solid #000',
  },
});

export const WithoutColorContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  [theme.breakpoints.down('laptop')]: {
    position: 'static',
  },
}));

export const WithoutColorButton = styled(Button)({
  width: 'fit-content',
  height: 'fit-content',
});
