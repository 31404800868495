import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { PatternView } from '@/components/pattern-view/pattern-view';
import { PageContainer } from '@/components/page-container/page-container';
import { Button } from '@/components/button/button';
import { PatternizerStepsKeys } from '../patternizer-steps';

export interface ManualPatternStepProps {
  onPressNextButton: () => void;
}

export const ManualPatternStep: FC<ManualPatternStepProps> = ({ onPressNextButton }) => {
  const { t } = useTranslation();
  const { currentStep } = useSelector(getPatternizerSelector);
  const pattern = useSelector(getPatternDataSelector);

  const tabIsOpened = PatternizerStepsKeys.MANUAL_PATTERN_STEP === currentStep;

  return (
    <>
      {tabIsOpened && pattern.id && (
        <>
          <PatternView patternId={pattern.id} />
          <PageContainer>
            <Button colored onClick={onPressNextButton}>
              {t('common.next')}
            </Button>
          </PageContainer>
        </>
      )}
    </>
  );
};
