import { Errors } from '@/common/constants/errors';
import { FileFolders } from '@/common/constants/fileFolders';
import { ProductStatus } from '@/common/constants/product-status';
import {
  Collections,
  FileCollectionItem,
  YarnColorCollectionItem,
  YarnImageCollectionItem,
  YarnManufacturerCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { User } from '@/common/interfaces/user.interface';
import { ApiType } from '@/services/api';
import {
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  getYarnImagesAction,
  getYarnImagesSuccessAction,
  getYarniversDataAction,
  getYarniversDataSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  removeYarnColorAction,
  setYarnPriceAction,
  setYarnWashAndCareAction,
  setYarnWashAndCareSuccessAction,
  uploadYarnImageAction,
  uploadYarnImageSuccessAction,
} from '@/store/reducers/yarnivers.reducer';
import { TransportResponse } from '@directus/sdk';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  setYarnColorAction,
  setYarnColorSuccessAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  yarniversErrorAction,
} from '../reducers/yarnivers.reducer';
import { getCurrentUserSelector } from '../selectors/user.selector';
import { Yarn } from '../types/yarnivers';

function* setYarnDataRequest(api: ApiType, action: ReturnType<typeof setYarnDataAction>) {
  try {
    const data = action.payload;
    const response: Yarn = yield call(
      data.id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.Yarn,
      data,
    );

    if (!response) {
      throw new Error('Something went wrong');
    }

    if (!response.yarn_manufacturer) {
      const user: User = yield select(getCurrentUserSelector);

      const responseManufacturer: TransportResponse<YarnManufacturerCollectionItem[]> = yield call(
        api.getCollection,
        Collections.YarnManufacturer,
        {
          filter: {
            user_created: {
              _eq: user.id,
            },
          },
        },
      );

      let manufactureId = null;

      if (!responseManufacturer.data?.length) {
        const responseNewManufacturer: YarnManufacturerCollectionItem = yield call(
          api.createCollectionItem,
          Collections.YarnManufacturer,
          {
            name: user.username || 'Unknown',
            status: ProductStatus.Published,
          },
        );

        manufactureId = responseNewManufacturer.id;
      } else {
        manufactureId = responseManufacturer.data[0].id;
      }

      yield call(api.updateCollectionItem, Collections.Yarn, {
        id: response.id,
        yarn_manufacturer: manufactureId,
      });
    }

    yield put(setYarnDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* getYarnColorsRequest(api: ApiType, action: ReturnType<typeof getYarnColorsAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnColorCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnColor,
      {
        filter: {
          yarn: yarnId,
        },
      },
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    }
    yield put(getYarnColorsSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

export const setYarnColorsAction = (payload: { colors: File[]; yarnId: number; }) => ({
  type: 'SET_YARN_COLORS',
  payload,
});

function* createYarnColorsAction(api: ApiType, action: ReturnType<typeof setYarnColorsAction>): Generator<any, void, any> {
  console.log('Saga received action:', action.payload); // Start of saga

  try {
    const { colors, yarnId } = action.payload;
    console.log('Processing colors:', colors); // Debugging log

    if (!yarnId) {
      throw new Error('Yarn ID is missing');
    }

    const results = yield all(
      colors.map((color) => {
        console.log('Processing file:', color.name); // Debugging log
        return call(function* () {
          let responseFile: FileCollectionItem | undefined;

          if (color) {
            responseFile = yield call(api.uploadFile, FileFolders.Yarns, color);
          }

          const payload: {
            name?: string;
            image?: string;
          } = {};

          if (color.name) {
            payload.name = color.name.split('.')[0];
          }

          if (responseFile?.id) {
            payload.image = responseFile?.id;
          }

          const response: YarnColorCollectionItem = yield call(
            api.createCollectionItem,
            Collections.YarnColor,
            {
              ...payload,
              yarn: yarnId,
            },
          );

          if (!response) {
            throw new Error('Failed to create yarn color');
          }

          return response;
        });
      }),
    );

    console.log('All files processed:', results); // Debugging log
    yield put(getYarnColorsAction(yarnId));
    yield put(setYarnColorSuccessAction());
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* createYarnColorAction(api: ApiType, action: ReturnType<typeof setYarnColorAction>) {
  try {
    const { color, yarnId, name } = action.payload;
    
    yield delay(500); 

    console.log('Processing file in saga:', color?.name); // Debugging log

    if (!yarnId) {
      throw new Error('Yarn ID is missing');
    }

    let responseFile: FileCollectionItem | undefined;

    if (color) {
      responseFile = yield call(api.uploadFile, FileFolders.Yarns, color);
    }

    const payload: {
      name?: string;
      image?: string;
    } = {};

    if (name) {
      payload.name = name;
    }

    if (responseFile?.id) {
      payload.image = responseFile?.id;
    }

    const response: YarnColorCollectionItem = yield call(api.createCollectionItem, Collections.YarnColor, {
      ...payload,
      yarn: yarnId,
    });

    if (!response) {
      throw new Error('Failed to create yarn color');
    }

    yield put(getYarnColorsAction(yarnId));
    yield put(setYarnColorSuccessAction());
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* uploadYarnImageRequest(api: ApiType, action: ReturnType<typeof uploadYarnImageAction>) {
  try {
    const { yarn, image } = action.payload;
    const responseFile: FileCollectionItem = yield call(api.uploadFile, FileFolders.Yarns, image);

    if (!responseFile) {
      throw new Error(Errors.Default);
    }

    const collectionResponse: YarnImageCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnImages,
      {
        yarn: yarn.id,
        image: responseFile.id,
      },
    );

    if (!collectionResponse) {
      throw new Error(Errors.Default);
    }

    const payload = {
      images: [...(yarn.images || []), collectionResponse.id],
      main_image: yarn.main_image,
    };

    if (!yarn.main_image) {
      payload.main_image = collectionResponse.id;
    }

    yield put(
      setYarnDataAction({
        id: yarn.id,
        ...payload,
      }),
    );
    yield put(uploadYarnImageSuccessAction());
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* getYarnImagesRequest(api: ApiType, action: ReturnType<typeof getYarnImagesAction>) {
  try {
    const { yarn } = action.payload;
    const response: TransportResponse<YarnImageCollectionItem[]> = yield call(
      api.getCollectionItemsByIds,
      Collections.YarnImages,
      yarn.images || [],
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    } else {
      yield put(getYarnImagesSuccessAction(response.data));
    }
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* removeYarnColorRequest(api: ApiType, action: ReturnType<typeof removeYarnColorAction>) {
  try {
    const { yarnId, fileId, id } = action.payload;

    yield call(api.deleteFile, fileId);

    yield call(api.removeCollectionItem, Collections.YarnColor, id);

    yield put(setYarnColorSuccessAction());

    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* setYarnWashAndCareRequest(
  api: ApiType,
  action: ReturnType<typeof setYarnWashAndCareAction>,
) {
  try {
    const { yarnId, washAndCare } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      wash_and_care: washAndCare,
    });

    yield put(setYarnWashAndCareSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* getYarniversDataRequest(api: ApiType, action: ReturnType<typeof getYarniversDataAction>) {
  try {
    const yarnId = action.payload;
    const response: Yarn = yield call(api.getCollectionItemById, Collections.Yarn, yarnId);

    if (!response) {
      throw new Error('Something went wrong');
    }

    yield put(getYarnColorsAction(response.id as number));
    if (response.images?.length) {
      yield put(getYarnImagesAction({ yarn: response }));
    }

    yield put(getYarniversDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* setYarnPriceRequest(api: ApiType, action: ReturnType<typeof setYarnPriceAction>) {
  try {
    const { yarnId, priceId, value } = action.payload;

    const response: YarnPriceCollectionItem = yield call(
      priceId ? api.updateCollectionItem : api.createCollectionItem,
      Collections.YarnPrice,
      {
        id: priceId,
        price: value,
        yarn: yarnId,
        currency: 1,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(setYarnDataAction({ id: yarnId, prices: [response.id] }));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* getYarnPricesRequest(api: ApiType, action: ReturnType<typeof getYarnPricesAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnPriceCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnPrice,
      {
        filter: {
          yarn: yarnId,
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }

    yield put(getYarnPricesSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

function* publishYarnRequest(api: ApiType, action: ReturnType<typeof publishYarnAction>) {
  try {
    const { yarnId, callback } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      status: ProductStatus.Published,
    });

    console.log(response);

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield call(callback, true);
    yield put(publishYarnSuccessAction());
  } catch (error: any) {
    console.log(error);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniversErrorAction());
  }
}

export const yarniversSaga = function* (api: ApiType) {
  yield all([takeLatest(setYarnDataAction.type, setYarnDataRequest, api)]);
  yield all([takeLatest(setYarnColorAction.type, createYarnColorAction, api)]);
  yield all([takeLatest(getYarnColorsAction.type, getYarnColorsRequest, api)]);
  yield all([takeLatest(uploadYarnImageAction.type, uploadYarnImageRequest, api)]);
  yield all([takeLatest(getYarnImagesAction.type, getYarnImagesRequest, api)]);
  yield all([takeLatest(removeYarnColorAction.type, removeYarnColorRequest, api)]);
  yield all([takeLatest(setYarnWashAndCareAction.type, setYarnWashAndCareRequest, api)]);
  yield all([takeLatest(getYarniversDataAction.type, getYarniversDataRequest, api)]);
  yield all([takeLatest(setYarnPriceAction.type, setYarnPriceRequest, api)]);
  yield all([takeLatest(getYarnPricesAction.type, getYarnPricesRequest, api)]);
  yield all([takeLatest(publishYarnAction.type, publishYarnRequest, api)]);
  yield all([takeLatest('SET_YARN_COLORS', createYarnColorsAction, api)]);  
};
