import { eventChannel } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import { sseLocalService } from '../local-storage';

export class SSEConnector {
  public retake = 0;
  public createSSE(url: string) {
    return new EventSource(url);
  }

  public getId() {
    const idFromStore = sseLocalService.sseUserId;
    if (!idFromStore) {
      const newId = uuidv4();
      sseLocalService.sseUserId = newId;
      return newId;
    }
    return idFromStore;
  }

  public createEventSourceChannel(url: string) {
    return eventChannel((emit) => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        emit({ data });
        if (data.status === 'finish') {
          eventSource.close();
        }
      };

      eventSource.onerror = () => {
        emit({ data: { status: 'reconnect' } });
      };

      // Return the unsubscribe function
      return () => {
        eventSource.close();
      };
    });
  }
}

export const sseConnector = new SSEConnector();
