import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const PatternizerPage = lazy(() => import('./patternizer-page'));

export const PatternizerPageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <PatternizerPage />
  </Suspense>
);
