import { Button, ButtonProps } from '@/components/button/button';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  onPressNext: () => void;
  align?: 'left' | 'right';
  colored?: boolean;
}

const ButtonContainer = styled('div')<{ align?: 'left' | 'right' }>(({ theme, align }) => ({
  maxWidth: '1600px',
  margin: '0 auto',
  padding: '2rem',
  display: 'flex',
  justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem',
  },
}));

const StyledButton = styled(Button)<{ colored?: boolean }>(({ theme, colored }) => ({
  padding: '0.9375rem 2.5rem',
  border: '1px solid #000',
  color: '#000',
  backgroundColor: colored ? theme.palette.primary.main : 'transparent',
  ...theme.typography.body2,
  '&:hover': {
    backgroundColor: colored ? 'transparent' : theme.palette.primary.main,
  },
}));

export const NextButton: FC<Props> = ({ onPressNext, align = 'left', colored = false, ...rest }) => {
  const { t } = useTranslation();
  return (
    <ButtonContainer align={align}>
      <StyledButton colored={colored} onClick={onPressNext} {...rest}>
        {t('common.next')}
      </StyledButton>
    </ButtonContainer>
  );
};