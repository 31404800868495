import React, { createContext, useContext, ReactNode } from 'react';

type FeatureFlags = {
  [role: string]: {
    [feature: string]: boolean;
  };
};

interface FeatureToggleProviderProps {
  children: ReactNode;
  features: FeatureFlags;
}

const FeatureToggleContext = createContext<FeatureFlags | undefined>(undefined);

export const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = ({ children, features }) => (
  <FeatureToggleContext.Provider value={features}>
    {children}
  </FeatureToggleContext.Provider>
);

export const useFeatureToggle = (role: string, feature: string): boolean => {
  const features = useContext(FeatureToggleContext);
  
  if (features === undefined) {
    throw new Error('useFeatureToggle must be used within a FeatureToggleProvider');
  }

  return features[role]?.[feature] ?? false;
};
