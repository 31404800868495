import React from 'react';
import { Navigate } from 'react-router-dom';
import { isPrelaunch } from '../config';
import { PATH } from '../common/constants/path';

const withPrelaunchRedirect = (Component: React.ComponentType) => {
  return (props: any) => {
    if (isPrelaunch) {
      return <Navigate to={PATH.PRELAUNCH} />;
    }
    return <Component {...props} />;
  };
};

export default withPrelaunchRedirect;