import { RoleName } from '@/common/interfaces/role.interface';
import { LocalStorageBase } from './base';

enum KEYS {
  roleName = 'roleName',
}

export class UserLocalStorage extends LocalStorageBase {
  constructor() {
    super('UserLocalStorage');
  }

  get adminSelectedRole() {
    const obj = this.get();
    return obj[KEYS.roleName] || RoleName.PatternDesigner;
  }

  set adminSelectedRole(role: RoleName) {
    this.set(KEYS.roleName, role);
  }
}
