import { DiagramCollectionItem } from '@/common/interfaces/collection.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoughtPatternWithStage, PatternWithStage, ResourceWithStage } from '../types/pattern';
import { logoutSuccessAction } from './authorization.reducer';

//import patternDataMock from '@/store/mocks/patternData.json';

export interface PatternViewState {
  isLoading: boolean;
  data: PatternWithStage;
  bought_pattern_data: BoughtPatternWithStage;
  resourceData: ResourceWithStage | null;
  diagramData: DiagramCollectionItem | null;
}

const initialState: PatternViewState = {
  isLoading: false,
  data: {},
  bought_pattern_data: {},
  resourceData: {},
  diagramData: null,
};

export const patternViewSlice = createSlice({
  name: 'pattern-view',
  initialState,
  reducers: {
    getPatternByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
      state.data = {};
    },
    getPatternByIdSuccessAction: (state, action: PayloadAction<PatternWithStage>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getPatternByIdErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get bought pattern

    getBoughtPatternByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
      state.bought_pattern_data = {};
    },
    getBoughtPatternByIdSuccessAction: (state, action: PayloadAction<BoughtPatternWithStage>) => {
      state.isLoading = false;
      // user_created
      state.bought_pattern_data = action.payload;
    },
    getBoughtPatternByIdErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get resource by id
    getResourceByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getResourceByIdSuccessAction: (state, action: PayloadAction<ResourceWithStage>) => {
      state.isLoading = false;
      state.resourceData = action.payload;
    },
    getResourceByIdErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get diagram by id
    getDiagramByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getDiagramByIdSuccessAction: (state, action: PayloadAction<DiagramCollectionItem>) => {
      state.isLoading = false;
      state.diagramData = action.payload;
    },
    getDiagramByIdErrorAction: (state) => {
      state.isLoading = false;
    },

    // Clear Resource Data
    clearResourceDataAction: (state) => {
      state.resourceData = null;
    },

    // Clear Diagram Data
    clearDiagramDataAction: (state) => {
      state.diagramData = null;
    },

    // Clear pattern data
    clearPatternViewDataAction: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  getPatternByIdAction,
  getPatternByIdSuccessAction,
  getPatternByIdErrorAction,

  getBoughtPatternByIdAction,
  getBoughtPatternByIdSuccessAction,
  getBoughtPatternByIdErrorAction,


  getResourceByIdAction,
  getResourceByIdSuccessAction,
  getResourceByIdErrorAction,
  clearResourceDataAction,
  clearPatternViewDataAction,
  clearDiagramDataAction,
  getDiagramByIdAction,
  getDiagramByIdSuccessAction,
  getDiagramByIdErrorAction,
} = patternViewSlice.actions;
export const patternViewReducer = patternViewSlice.reducer;
