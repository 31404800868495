import React, { FC } from "react";
import { Divider as MuiDivider } from "@mui/material";

interface DividerProps extends React.HTMLAttributes<HTMLHRElement>
 {
  variant: "fullWidth" | "inset" | "middle"; // Specify possible values directly
} const Divider: FC<DividerProps> = ({ variant, style }) => {
  return (
    <MuiDivider
      sx={{ borderWidth: "1px", borderBottom: 0, borderColor: "#000000" }}
      variant={variant}
      style={style}
    />
  );
};
export { Divider };
