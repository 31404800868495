import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { MeasurementWithStage } from '@/store/types/collection';
import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Description, Item, Sizes, Title } from './styles';

interface Props {
  availableSizes: number[];
  setSelectedSize: (id: number) => void;
  selectedSize: number;
  measurements: MeasurementWithStage[];
}

export const MeasurementSize: FC<Props> = (props) => {
  const { availableSizes: availableSizesIds, selectedSize, setSelectedSize, measurements } = props;
  const { AvailableSize } = useSelector(getCollectionDataSelector);
  const availableSizes = AvailableSize.filter(x => availableSizesIds.includes(x.id));

  const measurementsMemo = useMemo(() => {
    return measurements.map((el) => ({
      name: el.name,
      value: el.measurement_values.find((el) => el.size_chart.id === selectedSize)?.value + ' cm',
    }));
  }, [measurements, selectedSize]);

  return (
    <>
      <Title>Measurements and yarn alternatives</Title>
      <Description>Select available size to see measurements and yarn amount</Description>
      <Sizes>
        {availableSizes.map((el) => (
          <Item
            key={el.id}
            variant='outlined'
            style={{ backgroundColor: selectedSize === el.id ? '#f6f4ed' : 'transparent' }}
            onClick={() => setSelectedSize(el.id)}
          >
            {el.size_name}
          </Item>
        ))}
      </Sizes>

      <Typography mt='3rem' mb='1rem' variant='h3'>
        Measurements
      </Typography>

      {measurementsMemo.map((el) => (
        <div key={el.name + el.value}>
          <Typography variant='body1' mt='1rem'>
            {el.name}: {el.value}
          </Typography>
        </div>
      ))}
    </>
  );
};
