import { ScreenStep, ScreenSteps } from '@/components/screen-steps';
import { setStep } from '@/store/reducers/patternizer.reducer';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { FC, SyntheticEvent, useCallback, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Advertisement } from '../advertisement';
import { Categorization } from '../categorization';
import { ManualPatternStep } from '../manual-pattern-step/manual-pattern-step';
import { Measurement } from '../measurement';
import { PatternName } from '../pattern-name';
import { Preview } from '../preview';
import { YarnAlternatives } from '../yarn-alternatives';
// import { StartPattern } from '../start';
// import { PatternUpload } from '../pattern-upload/pattern-upload';

export enum PatternizerStepsKeys {
  // START = 'START',
  PATTERN_NAME = 'PATTERN_NAME',
  CATEGORIZATION = 'CATEGORIZATION',
  MEASUREMENT = 'MEASUREMENT',
  YARN_ALTERNATIVES = 'YARN_ALTERNATIVES',
  // PATTERN_UPLOAD = 'PATTERN_UPLOAD',
  MANUAL_PATTERN_STEP = 'MANUAL_PATTERN_STEP',
  ADVERTISEMENT = 'ADVERTISEMENT',
  COMPLETE = 'COMPLETE',
  PREVIEW = 'PREVIEW',
}

export const PatternizerSteps: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentStep } = useSelector(getPatternizerSelector);
  const { id, isManualPattern } = useSelector(getPatternDataSelector);
  const AvailableSizesCollection = useSelector(getPatternAvailableSizesSelector);

  useEffect(() => {
    const tab = searchParams.get('tab') as PatternizerStepsKeys;
    if (tab) {
      dispatch(setStep(tab));
    }
  }, [searchParams]);

  useEffect(() => {
    const searchParams: { tab: PatternizerStepsKeys; id?: string } = {
      tab: currentStep,
    };
    if (id) {
      searchParams['id'] = id.toString();
    }
    setSearchParams(searchParams);
  }, [currentStep, id]);

  const onPressNextHandler = useCallback((step: PatternizerStepsKeys) => {
    dispatch(setStep(step));
  }, []);

  const STEPS: ScreenStep[] = useMemo(
    () => [
      // {
      //   id: PatternizerStepsKeys.START,
      //   title: 'Patternizer',
      //   content: (
      //     <StartPattern
      //       onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.PATTERN_NAME)}
      //     />
      //   ),
      // },
      {
        id: PatternizerStepsKeys.PATTERN_NAME,
        title: t('patternizerSteps.patternName'),
        content: (
          <PatternName
          // onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.PATTERN_UPLOAD)}
          onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.CATEGORIZATION)}
          />
        ),
      },
      // {
      //   id: PatternizerStepsKeys.PATTERN_UPLOAD,
      //   title: t('patternizerSteps.pattern'),
      //   content: <PatternUpload
      //       onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.CATEGORIZATION)}
      //   />,
      // },
      {
        id: PatternizerStepsKeys.CATEGORIZATION,
        title: t('patternizerSteps.categorization'),
        content: (
          <Categorization
            onPressNextButton={() => onPressNextHandler(
              isManualPattern
                ? PatternizerStepsKeys.MANUAL_PATTERN_STEP
                : PatternizerStepsKeys.MEASUREMENT)}
          />
        ),
      },
      ...(isManualPattern
        ? [
            {
              id: PatternizerStepsKeys.MANUAL_PATTERN_STEP,
              title: t('patternizerSteps.manualPattern'),
              content: (
                <ManualPatternStep
                  onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.MEASUREMENT)}
                />
              ),
            },
          ]
        : []),
      {
        id: PatternizerStepsKeys.MEASUREMENT,
        title: t('patternizerSteps.measurement'),
        content: (
          <Measurement
            onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.YARN_ALTERNATIVES)}
          />
        ),
      },
      {
        id: PatternizerStepsKeys.YARN_ALTERNATIVES,
        title: t('patternizerSteps.yarnAlternatives'),
        content: (
          <YarnAlternatives
            onPressNextButton={() =>
              onPressNextHandler(PatternizerStepsKeys.ADVERTISEMENT)}
          />
        ),
      },
      {
        id: PatternizerStepsKeys.ADVERTISEMENT,
        title: 'Advertisement',
        content: (
          <Advertisement
            onPressNextButton={() => onPressNextHandler(PatternizerStepsKeys.PREVIEW)}
          />
        ),
      },
      // {
      //   id: PatternizerStepsKeys.COMPLETE,
      //   title: 'COMPLETE',
      //   content: <Complete />,
      // },

      {
        id: PatternizerStepsKeys.PREVIEW,
        title: 'Preview',
        content: <Preview />,
      },
    ],
    [isManualPattern],
  );

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    dispatch(setStep(value as PatternizerStepsKeys));
  };

  const getIsBlocked = useCallback(
    (key: string) => {
      if (!id) {
        return true;
      }
      switch (key) {
        case PatternizerStepsKeys.MEASUREMENT: {
          return !AvailableSizesCollection.length;
        }
        case PatternizerStepsKeys.MANUAL_PATTERN_STEP: {
          return !AvailableSizesCollection.length;
        }
        case PatternizerStepsKeys.ADVERTISEMENT: {
          return !YarnAlternatives.length;
        }
        default: {
          return false;
        }
      }
    },
    [id, AvailableSizesCollection],
  );
  return (
    <>
      <ScreenSteps
        currentStep={currentStep}
        steps={STEPS}
        onStepChange={handleStepChange}
        getIsBlocked={getIsBlocked}
      />
    </>
  );
};
