import { ColorPicker } from '@/components/color-picker';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setAlternativeItemsColorAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternAvailableSizesSelector } from '@/store/selectors/pattern.selector';
import { AlternativeItemCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  DeleteIcon,
  EditColor,
  IconButtonWrapper,
  Inner,
  InputStyled,
  NoPhoto,
  Photo,
  PhotoContainer,
  SizeContainer,
  SizeInput,
  Title,
} from './styles';

interface Props {
  first?: boolean;
  item: AlternativeItemCollectionItemWithStage;
  onChangeMeasurement: (id: number, value: string) => void;
  onChangeName: (name: string, alternativeItem: number) => void;
  onDeleteItem: (id: number) => void;
}

export const Item: FC<Props> = (props) => {
  const { first, item, onChangeMeasurement, onChangeName, onDeleteItem } = props;

  const dispatch = useDispatch();

  const collections = useSelector(getCollectionDataSelector);

  const getSizeLabel = (size: number) => {
    return collections.AvailableSize.find((s) => s.id === size)?.size_name || '';
  };

  const [colorAnchor, setColorAnchor] = useState<HTMLButtonElement | null>(null);

  const handleChangeName = (value: string) => {
    onChangeName(value, item.id);
  };

  const [title, setTitle] = useInputWithDelay(item.name, handleChangeName, 500);

  const openPicker = (event: MouseEvent<any>) => {
    setColorAnchor(event.currentTarget);
  };

  const closePicker = () => {
    setColorAnchor(null);
  };

  const onChangeColor = (color: number) => {
    dispatch(setAlternativeItemsColorAction({ color, alternative: item.id }));
    setColorAnchor(null);
  };

  return (
    <Container>
      {first && (
        <Title align='left' variant='h5'>
          Write yarn amount in gram
        </Title>
      )}
      <InputStyled
        placeholder='Enter your yarn name'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Inner>
        <Tooltip title={item.yarn.name} placement='bottom'>
          <PhotoContainer>
            {item?.color?.image && (
              <>
                <Photo src={getAssetBackendUrl(item?.color?.image)} />
                <EditColor onClick={openPicker}>Edit Color</EditColor>
              </>
            )}
            {!item?.color?.image && <NoPhoto></NoPhoto>}
          </PhotoContainer>
        </Tooltip>
        <Grid container direction='row'>
          {item?.yarn_measurement.map((el) => (
            <SizeContainer key={el.id + '_measurement'}>
              <div style={{ borderBottom: '1px solid black' }}>
                <Typography variant='h5'>{getSizeLabel(el.size)}</Typography>
              </div>

              <div>
                <SizeInputWrapper
                  init={el.value}
                  onChange={(value) => onChangeMeasurement(el.id, value)}
                />
              </div>
            </SizeContainer>
          ))}

          <IconButtonWrapper>
            <IconButton onClick={() => onDeleteItem(item.id)}>
              <DeleteIcon />
            </IconButton>
          </IconButtonWrapper>
        </Grid>
      </Inner>
      <ColorPicker
        colors={item.yarn.colors}
        anchorEl={colorAnchor}
        onClose={closePicker}
        onSelect={onChangeColor}
      />
    </Container>
  );
};

interface WrapperProps {
  init: string;
  onChange: (value: string) => void;
}

const SizeInputWrapper: FC<WrapperProps> = (props) => {
  const { init, onChange } = props;

  const handleChange = (value: string) => {
    onChange(value);
  };

  const [value, setValue] = useInputWithDelay(init, handleChange, 1000);

  return (
    <SizeInput
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onBlur={() => setValue(value)}
      placeholder='Gram'
      type='number'
    />
  );
};
