import { Pattern } from '@/common/interfaces/pattern.interface';
import { setPatternDataAction } from '@/store/reducers/pattern.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Content,
  Block,
  Technique,
  TechniqueTitle,
  TextArea,
  PriceInput,
  PriceSet,
  PriceTitle,
  Currency,
  Name,
  Description,
} from './styles';

interface Props {
  pattern: Pattern;
}

export const Info: FC<Props> = (props) => {
  const { pattern } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Technics } = useSelector(getCollectionDataSelector);

  const [price, setPrice] = useState(pattern.price?.toString() || '');
  const [description, setDescription] = useState(pattern.description || '');

  const handlePriceChange = (value: string) => {
    if (/^[0-9]*$/.test(value)) {
      setPrice(value);
    }
  };

  const handlePriceBlur = () => {
    dispatch(setPatternDataAction({ ...pattern, price: Number(price) }));
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const handleDescriptionBlur = () => {
    dispatch(setPatternDataAction({ ...pattern, description }));
  };

  const technics = useMemo(
    () =>
      Technics.filter((el) => pattern.technique?.includes(el.id))
        .map((el) => el.name)
        .join(', '),
    [pattern, Technics],
  );

  useEffect(() => {
    if (pattern.price) setPrice(pattern.price.toString());
    if (pattern.description) setDescription(pattern.description);
  }, [pattern.price, pattern.description]);

  return (
    <Content>
      <Block>
        <Name>{pattern.name}</Name>
        <Description>{t('patternizerSteps.advertisement.description')}</Description>
        <TextArea
          value={description}
          onChange={(ev) => handleDescriptionChange(ev.target.value)}
          placeholder={t('patternizerSteps.advertisement.descriptionPlaceholder')}
          onBlur={handleDescriptionBlur}
        />
      </Block>
      <Block>
        <Technique>
          <TechniqueTitle>{t('patternizerSteps.advertisement.techniques')}: {technics}</TechniqueTitle>
        </Technique>
        <PriceTitle>{t('patternizerSteps.advertisement.price')}</PriceTitle>
        <PriceSet>
          <PriceInput
            value={price}
            onChange={(ev) => handlePriceChange(ev.target.value)}
            placeholder='Set Price'
            type='number'
            onBlur={handlePriceBlur}
            style={{ width: price.length > 0 ? (price.length * 2.5) + 'rem' : '15rem' }}
          />
          <Currency>, - NOK</Currency>
        </PriceSet>
      </Block>
    </Content>
  );
};
