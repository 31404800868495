import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  margin: '1rem',
});

export const ButtonStyled = styled(Button)({
  marginTop: '1rem',
  fontSize: '1.2rem',
});

export const ButtonLabelStyled = styled(Typography)({
  fontSize: '1.2rem',
});
