import React, { ChangeEvent, FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DiagramCollectionItem } from '@/common/interfaces/collection.interface';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { PATH } from '@/common/constants/path';
import { useTranslation } from 'react-i18next';
import { ResourceTypes } from '@/common/constants/resources';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import { ImgVideo } from '@/components/img-video/img-video';

const apiPath = process.env.REACT_APP_API_URL || '';

export interface DiagramItemProps {
  diagram: DiagramCollectionItem;
  onDelete: () => void;
  onOpenPreview: () => void;
  onSave: (diagramData: { id: number; name: string }) => void;
}

const Diagram = styled('div')(({ theme }) => ({
  width: '25%',
  padding: '0.5rem',
  marginBottom: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    width: '50%',
  },
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));

const ImgWrapper = styled('div')({
  position: 'relative',
  ':hover .MuiButtonBase-root': {
    visibility: 'visible',
  },
});

const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  paddingBottom: '66%',
});

const Title = styled('div')({
  margin: '0.5rem 0 0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'relative',
  paddingRight: '5.5rem',
  lineHeight: '2.5rem',
});

const CopyBtn = styled(Button)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  backgroundColor: '#fff',
  color: '#000',
  visibility: 'hidden',
  ':hover': {
    backgroundColor: '#F6F4ED',
  },
});

const InputStyled = styled(Input)({
  width: '100%',
});

export const DiagramItem: FC<DiagramItemProps> = ({ diagram, onDelete, onSave, onOpenPreview }) => {
  const { t } = useTranslation();
  const { image, name, id } = diagram;
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState('');

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(
      `https://${window.location.host}${PATH.RESOURCE_VIEWER}?id=${id}&type=${ResourceTypes.DIAGRAM}`,
    );
  };

  const handleEditMode = () => {
    setEdit(true);
    setEditValue(name);
  };

  const handleCloseEditMode = () => {
    setEdit(false);
    setEditValue('');
  };

  const handleSaveData = () => {
    onSave({
      id,
      name: editValue,
    });
    handleCloseEditMode();
  };

  const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEditValue(target.value);
  };

  const SaveBtn = (
    <IconButton
      onClick={handleSaveData}
      disabled={!editValue}
      aria-label='close'
      sx={{
        position: 'absolute',
        right: '2.5rem',
        top: 0,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <SaveIcon />
    </IconButton>
  );

  const EditBtn = (
    <IconButton
      onClick={handleEditMode}
      aria-label='close'
      sx={{
        position: 'absolute',
        right: '2.5rem',
        top: 0,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <EditIcon />
    </IconButton>
  );

  const DeleteBtn = (
    <IconButton
      aria-label='close'
      onClick={onDelete}
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        color: (theme) => theme.palette.grey[500],
      }}
      disabled={edit}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <ClickAwayListener onClickAway={handleCloseEditMode}>
      <Diagram>
        <ImgWrapper>
          <Image src={`${apiPath}/assets/${image}`} onClick={onOpenPreview} />
          <CopyBtn onClick={copyLinkHandler}>{t('common.copyLink')}</CopyBtn>
        </ImgWrapper>
        <Title>
          {edit ? (
            <>
              <InputStyled value={editValue} onChange={handleNameChange} autoFocus />
              {SaveBtn}
            </>
          ) : (
            <>
              {name}
              {EditBtn}
            </>
          )}
          {DeleteBtn}
        </Title>
      </Diagram>
    </ClickAwayListener>
  );
};
