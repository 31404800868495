import React, { FC, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import { Button } from '@/components/button/button';
import { ResourceDataFields } from '@/store/types/resource';
import { FilesToUpload } from '@/store/types/resource';
import { FilePreview } from '@/components/file-preview';

export interface FileUploadProps {
  files: FilesToUpload;
  onFileChange: (files: FilesToUpload) => void;
}

const FileUploadContainer = styled('div')({
  padding: '1rem 2rem',
  margin: '0 -2rem',
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid #000',
  borderBottom: '1px solid #000',
});

export const FileUpload: FC<FileUploadProps> = ({ files, onFileChange }) => {
  const { t } = useTranslation();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const openFileUploadDialogHandler = () => {
    fileUploadRef.current?.click();
  };

  const onFileInputChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const addedFiles: FilesToUpload = {};

    for (let file of (target as any).files) {
      addedFiles[uuidv4()] = file;
    }
    target.value = '';

    onFileChange({
      ...files,
      ...addedFiles,
    });
  };

  const fileRemoveHandler = (fileId: string) => {
    const filesToUpdate: FilesToUpload = { ...files };
    delete filesToUpdate[fileId];

    onFileChange(filesToUpdate);
  };

  return (
    <FileUploadContainer>
      <input
        type='file'
        ref={fileUploadRef}
        multiple
        accept='image/png, image/jpeg, video/*'
        name={ResourceDataFields.FILES}
        onChange={onFileInputChangeHandler}
        hidden
      />
      <Button onClick={openFileUploadDialogHandler} style={{ marginRight: '3rem' }}>
        {t('common.uploadFiles')}
      </Button>
      <FilePreview files={files} onRemoveFile={fileRemoveHandler} />
    </FileUploadContainer>
  );
};
