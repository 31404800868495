import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { removeYarnColorAction, setYarnColorAction } from '@/store/reducers/yarnivers.reducer';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { styled } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../button/button';
import { Input } from '../input/input';

interface Props {
  photo: string;
  name: string;
  id?: number;
  yarnId?: number;
  readonly?: boolean;
}

export const ItemContainer = styled('div')({
  position: 'relative',
  width: '10.9375rem',
  objectFit: 'cover',

  margin: '0.5rem',

  '& img': {
    width: '100%',
    height: '10.9375rem',
    objectFit: 'cover',
  },

  '& input': {
    width: '100%',
    fontSize: '1.125rem',
  },

  input: {
    '& input.Mui-disabled': {
      color: 'green',
    },
  },
});

const DeleteButton = styled(Button)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  backgroundColor: '#fff',
  fontSize: '0.7rem',
  height: 'fit-content',
  width: 'fit-content',
  padding: 0,
  '&:hover': {
    backgroundColor: '#fff',
    opacity: 0.8,
  },
});

export const Name = styled('div')({
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.125rem',
  padding: '0.5rem 0.5rem 1rem 0.5rem',
  borderBottom: '1px solid #000',
});

export const YarnColorName: FC<Props> = (props) => {
  const { name, photo, id, yarnId, readonly = false } = props;
  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);

  const handleSave = () => {
    dispatch(
      setYarnColorAction({
        id: id,
        name: title,
        yarnId: yarnId as number,
      }),
    );
  };

  const [title, setTitle] = useInputWithDelay(name, handleSave, 500);
  const handleTitle = (value: string) => setTitle(value);

  const handleRemove = () => {
    dispatch(
      removeYarnColorAction({
        id: id as number,
        yarnId: yarnId as number,
        fileId: photo,
      }),
    );
  };

  return (
    <ItemContainer
      onMouseEnter={() => !readonly && setShowDelete(true)}
      onMouseLeave={() => !readonly && setShowDelete(false)}
    >
      {showDelete && !readonly && <DeleteButton onClick={handleRemove}>Remove</DeleteButton>}
      <img src={getAssetBackendUrl(photo)} />
      {!readonly && (
        <Input
          placeholder='Name color'
          value={title}
          onChange={(e) => handleTitle(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
          }}
        />
      )}
      {readonly && <Name>{name}</Name>}
    </ItemContainer>
  );
};
