import { Collections } from '@/common/interfaces/collection.interface';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { InputButtonSelect } from '../input-button-select';

export interface AccordionWithBlockData {
  expanded: Collections | string | null;
  data: {
    label: string;
    type: Collections | string;
    items: AccordionWithBlockItemData[];
    selected: AccordionWithBlockItemData[];
  }[];
}

export interface AccordionWithBlockItemData {
  id: number;
  label: string;
}

interface Props {
  data: AccordionWithBlockData;
  onExpanded: (value: Collections | string) => void;
  onSelect: (value: AccordionWithBlockItemData, type: Collections | string) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
});

const Block = styled('div')({
  width: '50%',
  borderBottom: '1px solid #000',
  overflow: 'auto',
});

const BlockInner = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const Items = styled('div')({
  width: '50%',
});

const Item = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '6.625rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #000',
  borderRight: '1px solid #000',
  cursor: 'pointer',
  padding: '1rem 1.5rem 1rem 2rem',
  overflow: 'hidden',
});

const IconBlock = styled('div')({
  transform: 'rotate(90deg)',

  '& svg': {
    transform: 'scale(1.5)',
  },
});

const Title = styled('div')({
  fontSize: '1.375rem',
});

const SelectedContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '1rem',
});

export const AccordionWithBlock: FC<Props> = (props) => {
  const { data, onExpanded, onSelect } = props;

  const dataBlock = useMemo(() => {
    return data.data.find((el) => el.type === data.expanded);
  }, [data]);

  return (
    <Container>
      <Items>
        {data.data.map((item) => (
          <Item
            style={{ backgroundColor: data.expanded === item.type ? '#F6F4ED' : 'transparent' }}
            key={item.type}
            onClick={() => onExpanded(item.type)}
          >
            <Title>{item.label}</Title>
            <SelectedContainer>
              {item.selected.map((el) => el.label).join(',')}
              <IconBlock>
                <ExpandLessIcon />
              </IconBlock>
            </SelectedContainer>
          </Item>
        ))}
      </Items>
      <Block style={{ height: `calc(6.625rem * ${data.data.length})` }}>
        <BlockInner>
          {dataBlock?.items.map((el) => (
            <InputButtonSelect
              key={el.id}
              label={el.label}
              value={el.id}
              checked={dataBlock.selected.map((el) => el.id).includes(el.id)}
              onChange={() => onSelect(el, dataBlock.type)}
            />
          ))}
        </BlockInner>
      </Block>
    </Container>
  );
};
