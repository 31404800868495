import { RoleName } from '@/common/interfaces/role.interface';
import { userLocalService } from '@/services/local-storage';

export const getCurrentUserRoleName = (roleName: RoleName): RoleName => {
  if (!roleName) {
    return RoleName.PatternDesigner;
  }
  if (roleName === RoleName.Administrator) {
    if (userLocalService.adminSelectedRole) {
      return userLocalService.adminSelectedRole;
    } else {
      userLocalService.adminSelectedRole = RoleName.PatternDesigner;
      return RoleName.PatternDesigner;
    }
  }
  return roleName;
};
