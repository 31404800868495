import { Button } from '@/components/button/button';
import { styled } from '@mui/material';

export const Title = styled('div')({
  fontSize: '2.5rem',
  fontWeight: 400,
});

export const Description = styled('div')({
  fontSize: '1.125rem',
  margin: '1rem 0',
});

export const Sizes = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Item = styled(Button)({
  width: '8.375rem',
  height: '3.44rem',
  borderRadius: '0',
  borderColor: '#000',
  color: '#000',
  marginRight: '1rem',
  fontSize: '1.125rem',

  '&:hover': {
    borderColor: '#000',
  },
});
