import { PATH } from '@/common/constants/path';
import { DesignerSearchItem, PatternSearchItem, ResourceSearchItem, YarnSearchItem } from '@/store/types/search';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Clear,
  Container,
  FoundText,
  Header,
  Image,
  ImageContainer,
  Inner,
  Name,
  SubName,
  TopName,
  TextContainer,
  Title,
} from './styles';
import { User } from '@/common/interfaces/user.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  patterns: PatternSearchItem[];
  yarns: YarnSearchItem[];
  recourses: ResourceSearchItem[];
  designers: DesignerSearchItem[];
  close: () => void;
  clear: () => void;
}

export const Result: FC<Props> = (props) => {
  const { patterns, yarns, recourses, close, clear, designers } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToPattern = (id: number) => {
    close();
    navigate(PATH.PATTERN + '?id=' + id);
  };

  const navigateToYarn = (id: number) => {
    close();
    navigate(PATH.YARN + '?id=' + id);
  };

  const navigateToResource = (id: number) => {
    close();
    navigate(PATH.RESOURCE_VIEWER + '?id=' + id + '&type=resource');
  };

  const navigateToDesigner = (id: string) => {
    close();
    navigate(PATH.PROFILE + '?id=' + id);
  };

  const onClear = () => {
    clear();
  };

  console.log("designers", designers)

  return (
    <Container>
      <Header>
        <TextContainer>
          <Title>{t('search.results')} {designers.length > 0 ? t('search.forDesigners'): ''}</Title>
          {!!patterns.length ||
            (!!yarns.length && <FoundText>{patterns.length + yarns.length} {t('search.resultsFound')}</FoundText>)}
        </TextContainer>
        {!designers && <Clear variant='text' onClick={onClear}>
          {t('search.clear')}
        </Clear>}
      </Header>
      {/* <Header>
        <TextContainer>
          <Title>Patterns</Title>
        </TextContainer>
      </Header> */}
     {patterns.length > 0 && <Inner>
        {patterns.map((el) => (
          <Item
            key={'pattern_' + el.id}
            id={el.id}
            name={el.name}
            img={el.main_image?.image}
            designer_name= {el.user_created?.first_name + ' ' + el.user_created?.last_name}
            price={el.pattern_price?.length > 0 ? el.pattern_price[0].price : 'None'}
            onClick={navigateToPattern}
          />
        ))}
    </Inner>}
    {/* <Header>
        <TextContainer>
          <Title>Yarns</Title>
        </TextContainer>
    </Header> */}
    {yarns.length > 0 && <Inner>

        {yarns.map((el) => (
          <Item
            key={'yarn_' + el.id}
            id={el.id}
            name={el.name}
            img={el.main_image?.image}
            designer_name= {el.user_created?.first_name + ' ' + el.user_created?.last_name}
            price={el.yarn_price?.length > 0 ? el.yarn_price[0].price : 'None'}
            onClick={navigateToYarn}
          />
        ))}
    </Inner>}
    {/* <Header>
        <TextContainer>
          <Title>Ressources</Title>
        </TextContainer>
    </Header> */}
    {recourses.length > 0 &&  <Inner>
       {recourses.map((el) => (
          <Item
            key={'resource_' + el.id}
            id={el.id}
            name={el.name}
            img={el.files[0]?.file}
            designer_name= {el.user_created?.first_name + ' ' + el.user_created?.last_name}
            price={'0'}
            onClick={navigateToResource}
          />
        ))}
      </Inner>}

      {designers.length > 0 &&  <Inner>
       {designers.map((el: User) => (
          <Item
            key={'resource_' + el.id}
            name={el.first_name}
            img={el.avatar || 'https://via.placeholder.com/150'}
            showAvatarOnly={true}
            price={'0'}
            onClick={()=>navigateToDesigner(el.id)}
          />
        ))}
      </Inner>}
    </Container>
  );
};

interface ItemProps {
  id?: number;
  name?: string;
  img?: string;
  designer_name?: string;
  price?: string;
  onClick: (id: number) => void;
  showAvatarOnly?: boolean;
}

const Item: FC<ItemProps> = (props) => {
  const { id, name, img, designer_name, price, onClick, showAvatarOnly } = props;
  const { t } = useTranslation();
  return (
    <ImageContainer onClick={() => onClick(id as number)}>
      <Tooltip title={name} placement='bottom'>
        <>
          {!showAvatarOnly && <TopName>{t('common.by')} {designer_name}</TopName>}
          <Image placeholder={name} src={getAssetBackendUrl(img || '')}></Image>
          {!showAvatarOnly && <Name>{name}</Name>}
          {!showAvatarOnly && price != '0' && (<SubName>{price},- NOK</SubName>)}
        </>
      </Tooltip>
    </ImageContainer>
  );
};
