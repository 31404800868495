import { checkIsLoggedInAction } from '@/store/reducers/authorization.reducer';
import { getCurrentUserAction } from '@/store/reducers/user.reducer';
import { getAuthorizationSelector } from '@/store/selectors/authorization.selector';
import LinearProgress from '@mui/material/LinearProgress';
import { createContext, FC, ReactNode, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AuthContext = createContext(false);

export interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, checkAuthLoading } = useSelector(getAuthorizationSelector);

  useEffect(() => {
    dispatch(checkIsLoggedInAction());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUserAction());
    }
  }, [isLoggedIn]);

  return checkAuthLoading ? (
    <LinearProgress />
  ) : (
    <AuthContext.Provider value={isLoggedIn}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
