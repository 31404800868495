import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import {
  addYarnAlternativeAction,
  addYarnAlternativeItemAction,
  getYarnAlternativeByPatternAction,
  removeAlternativeItemsAction,
  removeYarnAlternativeAction,
  updateAlternativeItemsAction,
  updateYarnMeasurementAction,
  updateYarnMeasurementSizeAction,
} from '@/store/reducers/collection.reducer';
import { displayErrorAction } from '@/store/reducers/system.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton } from '../next-button';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Alternatives } from './components/alternatives';
import { Yarnivers } from './components/yarnivers';
import { PageContainer } from '@/components/page-container/page-container';

interface Props {
  onPressNextButton: () => void;
}

export const YarnAlternatives: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const dispatch = useDispatch();

  const pattern = useSelector(getPatternDataSelector);

  const { YarnAlternative } = useSelector(getCollectionDataSelector);
  const AvailableSizes = useSelector(getPatternAvailableSizesSelector);

  const [selectedAlternative, setSelectedAlternative] = useState<number>();

  const { currentStep } = useSelector(getPatternizerSelector);

  useEffect(() => {
    if (currentStep === PatternizerStepsKeys.YARN_ALTERNATIVES) {
      dispatch(getYarnAlternativeByPatternAction());
    }
  }, [pattern, currentStep]);

  useEffect(() => {
    if (!YarnAlternative.length) return;
    dispatch(updateYarnMeasurementSizeAction());
    setSelectedAlternative(YarnAlternative[0].id);
  }, [AvailableSizes]);

  const handleChangeName = (name: string, alternativeItem: number) => {
    dispatch(
      updateAlternativeItemsAction({
        name,
        alternativeItem,
      }),
    );
  };

  const handleChangeMeasurement = (id: number, value: string) => {
    dispatch(
      updateYarnMeasurementAction({
        id,
        value,
      }),
    );
  };

  const handleAddAlternative = () => {
    dispatch(addYarnAlternativeAction());
  };

  const handleAddYarn = (
    yarn: YarnCollectionItemWithStage,
    color: YarnColorCollectionItem | null,
  ) => {
    if (!yarn.id || !selectedAlternative) {
      dispatch(displayErrorAction('No alternative added'));
      return;
    }
    dispatch(
      addYarnAlternativeItemAction({
        yarnId: yarn.id,
        colorId: color?.id || null,
        alternativeId: YarnAlternative.find((el) => el.id === selectedAlternative)?.id as number,
      }),
    );
  };

  const handleDeleteAlternative = (id: number) => {
    dispatch(removeYarnAlternativeAction(id));
    setSelectedAlternative(undefined);
  };

  const handleDeleteAlternativeItems = (id: number) => {
    dispatch(removeAlternativeItemsAction(id));
  };

  return (
    <>
      <PageContainer>
        <Yarnivers onAddYarn={handleAddYarn} />
        <Alternatives
          alternatives={YarnAlternative}
          onAdd={handleAddAlternative}
          setSelectedAlternative={setSelectedAlternative}
          selectedAlternative={selectedAlternative}
          onChangeMeasurement={handleChangeMeasurement}
          onChangeName={handleChangeName}
          onDeleteItem={handleDeleteAlternativeItems}
          onDeleteAlternative={handleDeleteAlternative}
        />
      </PageContainer>
      <NextButton onPressNext={onPressNextButton} />
    </>
  );
};
