import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material';
import { SignedIn } from '@clerk/clerk-react';

import { theme } from '@/common/mui-theme';
import { PATH } from '@/common/constants/path';

import { isPrelaunch } from '@/config';

import { usePagesContent } from '@/hooks/usePagesContent.hook';

import withPrelaunchRedirect from '@/hoc/withPrelaunchRedirect';

import { getBasketLoadingSelector } from '@/store/selectors/basket.selector';
import { getCollectionLoadingSelector } from '@/store/selectors/collection.selector';
import { getPatternViewLoadingSelector } from '@/store/selectors/pattern-view.selector';
import { getSearchLoadingSelector } from '@/store/selectors/search.selector';
import { getUserLoadingSelector } from '@/store/selectors/user.selector';
import { getYarnSupplierLoadingSelector } from '@/store/selectors/yarn-supplier.selector';
import { getYarniversLoadingSelector } from '@/store/selectors/yarnivers.selector';

import { ForgotPasswordLazy } from '@/pages/forgot-password-page/forgot-password-page-lazy';
import { HomePageLazy } from '@/pages/home-page/home-page-lazy';
import { PatternPageLazy } from '@/pages/pattern-page/pattern-page-lazy';
import { PatternizerPageLazy } from '@/pages/patternizer-page/patternizer-page-lazy';
import { PaymentErrorPageLazy } from '@/pages/payment-error-page/payment-error-page-lazy';
import { PaymentSuccessPageLazy } from '@/pages/payment-callback/payment-callback-page-lazy';
import { ProfilePageLazy } from '@/pages/profile-page/profile-page-lazy';
import { ProfileSettingPageLazy } from '@/pages/profile-setting-page/profile-setting-page-lazy';
import { ResourceUploadPageLazy } from '@/pages/resource-upload-page/resource-upload-page-lazy';
import { ResourceViewerPageLazy } from '@/pages/resource-viewer-page/resource-viewer-page-lazy';
import { SearchPageLazy } from '@/pages/search-page/search-page-lazy';
import { SignInPageLazy } from '@/pages/sign-in-page/sign-in-page-lazy';
import { SignUpPageLazy } from '@/pages/sign-up-page/sign-up-page-lazy';
import { StepsViewerPageLazy } from '@/pages/steps-viewer-page/steps-viewer-page-lazy';
import { SuccessPageLazy } from '@/pages/success-page/success-page-lazy';
import { TermsPrivacyAboutPageLazy } from '@/pages/terms-privacy-about-page/terms-privacy-about-page-lazy';
import { YarnPageLazy } from '@/pages/yarn-page/yarn-page-lazy';
import { YarniversPageLazy } from '@/pages/yarnivers-page/yarnivers-page-lazy';
import { PaymentPageLazy } from '@/pages/payment-page/payment-page-lazy';
import { NewPaymentPageLazy } from '@/pages/new-payment-wall-page/new-payment-payment-wall-page-lazy';
import { PromotedPatternPageLazy } from '@/pages/promoted-patterns/promoted-patterns-page-lazy';
import { PromotedYarnPageLazy } from '@/pages/promoted-yarn/promoted-yarn-page-lazy';
import { PrelaunchPageLazy } from '@/pages/prelaunch-page/prelaunch-page-lazy';

import { ProtectedRoute } from '@/components/protected-route/protected-route';
import { Basket } from '@/components/basket';
import { Footer } from '@/components/footer/footer';
import { Header } from '@/components/header/header';
import { PageWithLoading } from '@/components/page-with-loading';
import { Snackbar } from '@/components/snackbar';
import SocialAuthCallback from '@/components/socialAuthCallback';

const App = () => {
  const isCollectionLoading = useSelector(getCollectionLoadingSelector);
  const isSearchLoading = useSelector(getSearchLoadingSelector);
  const isPatternLoading = useSelector(getPatternViewLoadingSelector);
  const isBasketLoading = useSelector(getBasketLoadingSelector);
  const isUserLoading = useSelector(getUserLoadingSelector);
  const isYarniversLoading = useSelector(getYarniversLoadingSelector);
  const isYarnSupplierLoading = useSelector(getYarnSupplierLoadingSelector);
  const isPagesContentLoading = usePagesContent();

  const isLoading = useMemo(() => {
    return (
      isPagesContentLoading ||
      isCollectionLoading ||
      isSearchLoading ||
      isPatternLoading ||
      isBasketLoading ||
      isUserLoading ||
      isYarniversLoading ||
      isYarnSupplierLoading
    );
  }, [
    isPagesContentLoading,
    isCollectionLoading,
    isSearchLoading,
    isPatternLoading,
    isBasketLoading,
    isUserLoading,
    isYarniversLoading,
    isYarnSupplierLoading,
  ]);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('no');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PageWithLoading isLoading={isLoading}>
        <Wrapper />
        <Snackbar />
        {!isPrelaunch && <Basket />}
      </PageWithLoading>
    </ThemeProvider>
  );
};

const Wrapper: FC = () => {
  const WrappedPromotedPatternPage = withPrelaunchRedirect(PromotedPatternPageLazy);
  const WrappedPromotedYarnPage = withPrelaunchRedirect(PromotedYarnPageLazy);
  const WrappedPatternPage = withPrelaunchRedirect(PatternPageLazy);
  const WrappedPatternizerPage = withPrelaunchRedirect(PatternizerPageLazy);
  const WrappedStepsViewerPage = withPrelaunchRedirect(StepsViewerPageLazy);
  const WrappedResourceViewerPage = withPrelaunchRedirect(ResourceViewerPageLazy);
  const WrappedResourceUploadPage = withPrelaunchRedirect(ResourceUploadPageLazy);
  const WrappedYarniversPage = withPrelaunchRedirect(YarniversPageLazy);
  const WrappedYarnPage = withPrelaunchRedirect(YarnPageLazy);
  const WrappedSearchPage = withPrelaunchRedirect(SearchPageLazy);

  return (
    <>
      <Header />
      <section className='page-content'>
        <Routes>
          <Route path={'/callback'} element={<SocialAuthCallback />} />
          <Route path={PATH.HOME} element={<HomePageLazy />} />
          <Route path={PATH.PRELAUNCH} element={<PrelaunchPageLazy />} />
          <Route path={PATH.SIGNIN} element={<SignInPageLazy />} />
          <Route path={PATH.SIGNUP} element={<SignUpPageLazy />} />
          <Route path={PATH.SUCCESS} element={<SuccessPageLazy />} />
          <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordLazy />} />
          <Route
            path={PATH.PROFILE}
            element={
              <SignedIn>
                <ProfilePageLazy />
              </SignedIn>
            }
          />
          <Route
            path={PATH.PROFILE_SETTING}
            element={
              <ProtectedRoute>
                <ProfileSettingPageLazy />
              </ProtectedRoute>
            }
          />
          {/* <Route path={PATH.PAYMENT_WALL} element={ <ProtectedRoute><PaymentWallPageLazy /></ProtectedRoute>} /> */}
          <Route path={PATH.PAYMENT_WALL} element={<NewPaymentPageLazy />}></Route>
          <Route
            path={PATH.PAYMENT_WALL_STRIPE_SUCCESS}
            element={
              <ProtectedRoute>
                <PaymentSuccessPageLazy />
              </ProtectedRoute>
            }
          />
          <Route path={PATH.PAYMENT_PAGE} element={<PaymentPageLazy />}></Route>
          <Route path={PATH.PAYMENT_SUCCESS} element={<PaymentSuccessPageLazy />}></Route>
          <Route path={PATH.PAYMENT_ERROR} element={<PaymentErrorPageLazy />}></Route>
          <Route path={PATH.PROMOTED_PATTERNS} element={<WrappedPromotedPatternPage />} />
          <Route path={PATH.PROMOTED_YARN} element={<WrappedPromotedYarnPage />} />
          <Route path={PATH.PATTERN} element={<WrappedPatternPage />} />
          <Route
            path={PATH.PATTERNS}
            element={
              <ProtectedRoute>
                <WrappedPatternizerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATH.STEPS_VIEWER}
            element={
              <ProtectedRoute>
                <WrappedStepsViewerPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={PATH.RESOURCE_VIEWER}
            element={
              <ProtectedRoute>
                <WrappedResourceViewerPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={PATH.RESOURCE_UPLOAD}
            element={
              <ProtectedRoute>
                <WrappedResourceUploadPage />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path={PATH.YARNIVERS}
            element={
              <ProtectedRoute>
                <WrappedYarniversPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={PATH.YARN}
            element={
              <ProtectedRoute>
                <WrappedYarnPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATH.SEARCH}
            element={
              <ProtectedRoute>
                <WrappedSearchPage />
              </ProtectedRoute>
            }
          />
          <Route path={PATH.TERMS_PRIVACY_ABOUT} element={<TermsPrivacyAboutPageLazy />}></Route>
          <Route path={PATH.NOT_FOUND} element={isPrelaunch ? <Navigate to={PATH.PRELAUNCH} /> : <Navigate to={PATH.HOME} />} />
        </Routes>
      </section>
      <Footer />
    </>
  );
};

export default App;