import { ItemSearchType } from '@/common/interfaces/search.interface';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

interface Props {
  onChange: (value: ItemSearchType) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '2rem',
  width: '50%',
  height: '15rem',
  borderRight: '1px solid #000',
  borderBottom: '1px solid #000',
  fontSize: '3.125rem',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1.5rem',
    height: '5rem',
  },
}));

export const ItemTypeStep: FC<Props> = (props) => {
  const { onChange } = props;
  return (
    <Container>
      {Object.keys(ItemSearchType).map((type) => (
        <Item key={type} onClick={() => onChange(type as ItemSearchType)}>
          {type}
        </Item>
      ))}
    </Container>
  );
};
