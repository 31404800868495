import { PATH } from '@/common/constants/path';
import { Collections } from '@/common/interfaces/collection.interface';
import { ItemSearchType } from '@/common/interfaces/search.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import {
  clearSearchAction,
  hideSearchAction,
  searchItemsAction,
  setItemSearchTypeAction,
  showSearchAction,
} from '@/store/reducers/search.reducer';
import { getSearchDataSelector } from '@/store/selectors/search.selector';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filter } from './components/filter';
import { Result } from './components/result';
import { SearchInput } from './components/search-input';
import { Drawer } from '@mui/material';
import { SlideInFilter } from './components/slide-in-filter';

export const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [additional, setAdditional] = useState<{ [key: string]: number[] }>({});
  const [Craft, setCraft] = useState<number>();
  const data = useSelector(getSearchDataSelector);

  const handleSearch = () => {
    dispatch(
      searchItemsAction({
        value: searchValue,
        itemType: data.itemSearchType,
        additional: {
          ...additional,
          Craft,
        },
      }),
    );
  };

  const [searchValue, setSearchValue] = useInputWithDelay('', handleSearch, 500);
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilterSlide = () => {
    setShowFilter((p) => !p);
  }

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const handleShowFilter = () => {
    if (data.showSearch) {
      dispatch(hideSearchAction());
    } else {
      dispatch(showSearchAction());
    }
  };

  const handleCloseFilter = () => {
    dispatch(hideSearchAction());
    dispatch(clearSearchAction());
  };

  const handleAdditional = (value: number, type: Collections | string) => {
    if (type === Collections.Craft) {
      setCraft((p) => (p === value ? undefined : value));
    } else {
      setAdditional((p) => ({
        ...p,
        [type]: (p[type] || []).includes(value)
          ? p[type].filter((item) => item !== value)
          : [...(p[type] || []), value],
      }));
    }
  };

  useEffect(() => {
    if (!Object.keys(additional).length) return;
    handleSearch();
  }, [additional]);

  useEffect(() => {
    if (!Craft) return;
    handleSearch();
  }, [Craft]);

  const isResult = useMemo(() => {
    return !!data?.patterns?.length || !!data?.yarns?.length || !!data?.resources?.length || !!data?.designers?.length;
  }, [data.patterns, data.yarns, data.resources, data.designers]);

  const handleClear = () => {
    dispatch(clearSearchAction());
  };

  const handleSetItemSearchType = (type: ItemSearchType | null) => {
    console.log('handleSetItemSearchType', type);
    dispatch(setItemSearchTypeAction(type));
  };

  const location = useLocation();

  useEffect(() => {
    console.log("location.pathname: ", location.pathname)
    if (PATH.SEARCH !== location.pathname) {
      handleSetItemSearchType(null);
      dispatch(hideSearchAction());
      dispatch(clearSearchAction());
    }
  }, [location]);

  useEffect(() => {
    setAdditional({Pattern: [1]});
    setCraft(undefined);
  }, [data.itemSearchType]);

  useEffect(() => {
    if (isResult || data.itemSearchType) {
      navigate(PATH.SEARCH);
    }
  }, [isResult, data.itemSearchType]);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const itemSearchType = searchParams.get('itemSearchType');
    if (itemSearchType === ItemSearchType.Designer) {
      setAdditional({});
      handleSetItemSearchType(itemSearchType as ItemSearchType);
    }
  }, [location.search]);

  return (
    <>

      <SearchInput
        value={searchValue}
        onChange={handleSearchValue}
        onChangeShowFilter={handleShowFilter}
        onChangeShowFilterSlide={handleShowFilterSlide}
      ></SearchInput>

      {data.itemSearchType !== ItemSearchType.Designer && <Filter
          isResult={isResult}
          result={data}
          itemSearchType={data.itemSearchType}
          onChangeItemSearchType={handleSetItemSearchType}
          isShow={data.showSearch}
          onChangeAdditional={handleAdditional}
      />}

      <Drawer open={showFilter} style={{zIndex: 15000}}>
        <SlideInFilter
          onChangeShowFilter={handleShowFilter}
          onChangeShowFilterSlide={handleShowFilterSlide}
          onChangeItemSearchType={handleSetItemSearchType}
          onChangeAdditional={handleAdditional}
        />
      </Drawer>

      {isResult && (
        <Result
          patterns={data.patterns}
          yarns={data.yarns}
          recourses={data.resources}
          designers={data.designers}
          close={handleCloseFilter}
          clear={handleClear}
        />
      )}
    </>
  );
};
