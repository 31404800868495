import { Button } from '@/components/button/button';
import { YarnAlternativeWithStage } from '@/store/types/collection';
import { Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FC, useEffect, useMemo, useState } from 'react';
import { Item } from './components/item';
import { ButtonsContainer, ButtonStyled, Container, NoYarnText, YarnContainer } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onAdd: () => void;
  alternatives: YarnAlternativeWithStage[];
  selectedAlternative?: number;
  setSelectedAlternative: (id: number) => void;
  onChangeMeasurement: (id: number, value: string) => void;
  onChangeName: (name: string, alternativeItem: number) => void;
  onDeleteItem: (id: number) => void;
  onDeleteAlternative: (id: number) => void;
}

export const Alternatives: FC<Props> = (props) => {
  const {
    onAdd,
    alternatives,
    selectedAlternative,
    setSelectedAlternative,
    onChangeMeasurement,
    onChangeName,
    onDeleteItem,
    onDeleteAlternative,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (alternatives.length === 0 || selectedAlternative) return;
    setSelectedAlternative(alternatives[0].id);
  }, [alternatives]);

  const onChangeAlternative = (alternativeId: number) => {
    setSelectedAlternative(alternativeId);
  };

  const selectedAlternativeMemo = useMemo(() => {
    return alternatives.find((alternative) => alternative.id === selectedAlternative);
  }, [selectedAlternative, alternatives]);

  return (
    <Container>
      <ButtonsContainer>
        {alternatives.map(({ id }, i) => (
          <ButtonStyled
            id={id.toString()}
            selected={selectedAlternative === id}
            onClick={() => onChangeAlternative(id)}
            endIcon={<DeleteIcon onClick={() => onDeleteAlternative(id)} />}
          >{`Alternative ${i + 1}`}</ButtonStyled>
        ))}
        <Button onClick={onAdd}>{t('patternizerSteps.addAlternative')}</Button>
      </ButtonsContainer>

      <YarnContainer>
        {selectedAlternativeMemo?.alternativ_items.map((item, i) => (
          <Item
            key={item.id + '_item'}
            item={item}
            first={i === 0}
            onChangeMeasurement={onChangeMeasurement}
            onChangeName={onChangeName}
            onDeleteItem={onDeleteItem}
          />
        ))}

        {selectedAlternativeMemo?.alternativ_items.length === 0 && (
          <NoYarnText variant='h4'>Click to yarn to add</NoYarnText>
        )}
      </YarnContainer>
    </Container>
  );
};
