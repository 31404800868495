
import { Divider } from '@/components/divider';
import { styled } from '@mui/material';
import { Tab as BaseTab } from '@mui/base/Tab';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { ContentContainer } from './content-container';
import { TPACollectionItem } from '@/common/interfaces/collection.interface';

const Tab = styled(BaseTab)(() => ({
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '1.5rem',
    padding: '0.5rem 1.5rem',
    borderRadius: '4.375rem',
    border: '1px solid #000',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: '#211E21',
    textTransform: 'none',
    flexShrink: 0,
    '&:hover': {
        backgroundColor: '#CEFF1A',
    },
    '&:focus': {
        outline: '1px solid #000',
        backgroundColor: '#CEFF1A',
    },
    '&.Mui-selected': {
        backgroundColor: '#F6F4ED',
    },
    '&.Mui-disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
}));

const TabsList = styled(BaseTabsList)(
    ({ theme }) => ({
        minWidth: '400px',
        display: 'flex',
        alignItems: 'center',
        padding: '1rem 2rem',
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignContent: 'space-between',
        gap: '15px',
        [theme.breakpoints.down('tablet')]: {
            padding: '1rem',
        },
    })
);

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TermsAndConditionsTabs = ({ data }: { data: Array<TPACollectionItem> }) => {

    return (<Tabs defaultValue={0}>
        <TabsList>
            {data.map((item, index) => (
                <Tab key={item.id} value={index} {...a11yProps(index)} >{item.title}</Tab>)
            )}
        </TabsList>

        <Divider variant="fullWidth" />

        {data.map((item, index) => (
            <TabPanel key={item.id} value={index}>
                <ContentContainer data={item} />
            </TabPanel>)
        )}
    </Tabs>)
}
