import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    height: 'auto',
    padding: '1rem',
  },
}));

export const PatternName = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '2.5rem',
  },
}));

export const Description = styled('div')({
  fontSize: '1.125rem',
  marginTop: '1rem',
});

export const PriceTitle = styled('div')({
  fontSize: '1.375rem',
});

export const Technique = styled('div')(({ theme }) => ({
  height: '8.44rem',
  display: 'flex',
  marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
  },
}));

export const TechniqueTitle = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 400,
});

export const PriceSet = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Currency = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));
