import { Input } from '@/components/input/input';
import { styled, TextareaAutosize, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  padding: '2rem',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    marginBottom: '2rem',
  },
}));

export const Name = styled(Typography)({});

export const Supplier = styled(Typography)({
  margin: '1rem 0',
});

export const Textarea = styled(TextareaAutosize)({
  border: 'none',
  outline: 'none',
  minHeight: '16rem',
  width: '100%',
  fontSize: '1.125rem',
  paddingTop: '1rem',
  height: '30rem',
  maxHeight: '30rem',
  overflow: 'auto !important',
  resize: 'none',
});

export const PriceTitle = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 400,
});

export const PriceSet = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Currency = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const PriceInput = styled(Input)(({ theme }) => ({
  fontSize: '3.125rem',
  padding: '0',
  '& ::before': {
    border: 'none !important',
  },
  '& ::after': {
    border: 'none !important',
  },
  '& .MuiInputBase-root': {
    width: '100%',
  },

  input: {
    '&::placeholder': {
      color: 'black',
      opacity: 1,
    },
  },
  maxWidth: '15rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));
