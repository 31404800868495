import React, { useState, useEffect, RefObject } from "react";

export function useIntersection<T extends Element>(ref: RefObject<T>): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });

    observer.observe(ref.current as T);

    return () => observer.disconnect();
  }, []);
  return isIntersecting;
}
