import {
  YarnColorCollectionItem,
  YarnImageCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { YarniversStepsKeys } from '@/pages/yarnivers-page/steps';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PublishYarnPayload,
  SetYarnPricePayload,
  UploadYarnImagePayload,
  WashAndCarePayload,
  Yarn,
  YarnColorPayload,
  YarnColorRemovePayload,
} from '../types/yarnivers';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarniversState {
  currentStep: YarniversStepsKeys;
  isLoading: boolean;
  data: Yarn;
  colors: YarnColorCollectionItem[];
  images: YarnImageCollectionItem[];
  prices: YarnPriceCollectionItem[];
}

const initialState: YarniversState = {
  currentStep: YarniversStepsKeys.Start,
  isLoading: false,
  data: {},
  colors: [],
  images: [],
  prices: [],
};

export const yarniversSlice = createSlice({
  name: 'yarnivers',
  initialState,
  reducers: {
    
    setYarniversStepAction: (state, action: PayloadAction<YarniversStepsKeys>) => {
      state.currentStep = action.payload;
    },

    // Get yarn data
    getYarniversDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarniversDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Update data without saving to backend
    updateYarnStateData(state, action: PayloadAction<Yarn>) {
      state.data = action.payload;
    },

    //Set yarn data with save to backend
    setYarnDataAction: (state, _: PayloadAction<Yarn>) => {
      state.isLoading = true;
    },
    setYarnDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Get yarn colors
    getYarnColorsAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnColorsSuccessAction: (state, action: PayloadAction<YarnColorCollectionItem[]>) => {
      state.isLoading = false;
      state.colors = [...state.colors, ...action.payload]; // Append new colors to the existing array
    },

    // Create yarn color
    setYarnColorAction: (state, _: PayloadAction<YarnColorPayload>) => {
      state.isLoading = true;
    },
    setYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn color
    removeYarnColorAction: (state, _: PayloadAction<YarnColorRemovePayload>) => {
      state.isLoading = true;
    },
    removeYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Upload Yarn image
    uploadYarnImageAction: (state, _: PayloadAction<UploadYarnImagePayload>) => {
      state.isLoading = true;
    },
    uploadYarnImageSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Get yarn images
    getYarnImagesAction: (state, _: PayloadAction<{ yarn: Yarn }>) => {
      state.isLoading = true;
    },
    getYarnImagesSuccessAction: (state, action: PayloadAction<YarnImageCollectionItem[]>) => {
      state.isLoading = false;
      state.images = action.payload;
    },

    // Remove yarn image
    setYarnWashAndCareAction: (state, _: PayloadAction<WashAndCarePayload>) => {
      state.isLoading = true;
    },
    setYarnWashAndCareSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Set price yarn
    setYarnPriceAction: (state, _: PayloadAction<SetYarnPricePayload>) => {},
    setYarnPriceSuccessAction: (state, action: PayloadAction<any>) => {},

    // Get yarn prices
    getYarnPricesAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnPricesSuccessAction: (state, action: PayloadAction<YarnPriceCollectionItem[]>) => {
      state.isLoading = false;
      state.prices = action.payload;
    },

    // Publish yarn
    publishYarnAction: (state, _: PayloadAction<PublishYarnPayload>) => {
      state.isLoading = true;
    },
    publishYarnSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Clear store
    clearYarniversAction: () => {
      return initialState;
    },

    // Error action
    yarniversErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  setYarniversStepAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  setYarnColorAction,
  setYarnColorSuccessAction,
  uploadYarnImageAction,
  uploadYarnImageSuccessAction,
  getYarnImagesAction,
  getYarnImagesSuccessAction,
  yarniversErrorAction,
  removeYarnColorAction,
  removeYarnColorSuccessAction,
  updateYarnStateData,
  setYarnWashAndCareAction,
  setYarnWashAndCareSuccessAction,
  getYarniversDataAction,
  getYarniversDataSuccessAction,
  setYarnPriceAction,
  setYarnPriceSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  clearYarniversAction,
} = yarniversSlice.actions;

export const yarniversReducer = yarniversSlice.reducer;
