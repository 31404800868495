import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  width: '49.40%',
  border: '1px solid #000000',
  height: '12.5rem',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '1%',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
    marginBottom: '1rem',
    width: '100%',
  },
}));

export const Picture = styled('img')({
  width: '12.5rem',
  height: '12.5rem',
});

export const Block = styled('div')({
  padding: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Name = styled('div')({
  textDecoration: 'underline',
  fontSize: '1.375rem',
  fontWeight: 600,
});

export const Description = styled('div')({
  fontSize: '1.125rem',
  margin: '0.5rem 0',
  maxHeight: '5.8rem',
  textOverflow: 'ellipsis',
  overflow: 'auto',
});

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('tablet')]: {
    display: 'block',
  },
}));

export const SelectColor = styled('div')({
  fontSize: '1.125rem',
  fontWeight: 400,
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const Total = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 600,
});
