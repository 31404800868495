import { Button, styled } from '@mui/material';

export const Container = styled('div')({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '1.875rem',
  border: '1px solid #000000',
  fontSize: '1.125rem',
  fontWeight: 600,
});

export const Number = styled('div')({
  minWidth: '3.75rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const Control = styled(Button)({
  width: '1.875rem !important',
  minWidth: '1.875rem !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '0',
  fontSize: '1.125rem',
  fontWeight: 600,
  color: '#000',
  borderRadius: 0,

  '&:hover': {
    borderColor: '#000',
    backgroundColor: '#f6f4ed',
  },
});
