import { TPACollectionItem } from "@/common/interfaces/collection.interface";
import { styled } from "@mui/material";
import { FC } from "react";

const Wrapper = styled('div')(({ theme }) => ({
    maxWidth: '960px',
    display: 'flex',
    padding: '1rem 2rem',
    [theme.breakpoints.down('tablet')]: {
        padding: '0.5rem 1rem',
    },
}));

interface ContentContainerProps {
    data: TPACollectionItem;
}


export const ContentContainer: FC<ContentContainerProps> = (props) => {
    const { data } = props;
    const content = data?.content;

    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: content }} ></div>
        </Wrapper>
    );
};
