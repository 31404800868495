import { RootState } from '..';

export const getUserRolesSelector = (state: RootState) => state.user.roles;

export const getCurrentUserSelector = (state: RootState) => state.user.currentUser;

export const getUserPatternsSelector = (state: RootState) => state.user.patterns;

export const getBoughtPatternsSelector = (state: RootState) => state.user.boughtPatterns;

export const getUserResourcesSelector = (state: RootState) => state.user.resources;

export const getUserLoadingSelector = (state: RootState) => state.user.isLoading;

export const getUserOrdersSelector = (state: RootState) => state.user.orders;

export const getUserYarnsSelector = (state: RootState) => state.user.yarns;

export const getCurrentRoleNameSelector = (state: RootState) => state.user.currentRoleName;

export const getProfileUserSelector = (state: RootState) => state.user.profileUser;
