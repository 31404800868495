import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarnivers';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Container } from './styles';

interface Props {
  yarn: Yarn;
}

export const WashAndCare: FC<Props> = (props) => {
  const { yarn } = props;

  const { WashAndCare } = useSelector(getCollectionDataSelector);

  const washAndCareMemo = useMemo(() => {
    return WashAndCare.filter((item) => (yarn?.wash_and_care || []).includes(item.id));
  }, [yarn, WashAndCare]);

  return (
    <div>
      <Typography variant='h3'>Wash and Care</Typography>

      <Container>
        {washAndCareMemo.map((el) => (
          <Cell key={el.id} src={getAssetBackendUrl(el.image)}></Cell>
        ))}
      </Container>
    </div>
  );
};
