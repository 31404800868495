import { styled } from '@mui/material';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const Item = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid #000`,
  cursor: 'pointer',
  padding: '0.5rem',
  '&.selected': {
    backgroundColor: '#F6F4ED',
  },
}));

export const Column = styled('div')(({ theme }) => ({
  width: '50%',
  height: '15rem',
  overflow: 'scroll',
  borderTop: '1px solid #000',
  borderBottom: '1px solid #000',
}));
