import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

interface ContainerProps {
  paddingBottom?: string;
  paddingBottomMobile?: string;
}

export const Container = styled('span')<ContainerProps>(({ theme, paddingBottom, paddingBottomMobile }) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: paddingBottom || '100%',
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: paddingBottomMobile || '100%',
  },
  backgroundColor: theme.palette.secondary.main,
}));

export const Loader = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const Img = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const Video = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
