import { OneItem, TransportResponse } from '@directus/sdk';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
    setConfirmPaymentDataAction,
    setConfirmPaymentDataSuccessAction,
    setConfirmPaymentDataErrorAction,
    createConfirmPaymentAction,
    confirmPaymentAction,
    setPaymentDataAction,
    setPaymentDataSuccessAction,
    setPaymentDataErrorAction,
    updatePaymentDataAction,
    updatePaymentAction,
    updatePaymentSuccessAction,
    updatePaymentErrorAction,
    createPaymentAction,
    setPayment
} from '../reducers/payment.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import { CreatePaymentProduct, Payment } from '@/common/interfaces/payment.interface';

function* setPaymentDataRequest(api: ApiType, action: ReturnType<typeof setPaymentDataAction>) {
  const {products, callback } = action.payload;
  console.log('REST API: ', products)

  try {
    const responsePayment: TransportResponse<any> = yield call( api.createPayment,
        {
            products: products
        },
    );

    if (responsePayment) {
      yield put(setPaymentDataSuccessAction(responsePayment));
      // yield call(callback, responsePayment);
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(setPaymentDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* setConfirmPaymentRequest(api: ApiType, action: ReturnType<typeof setConfirmPaymentDataAction>) {
    const confirm_payment = action.payload;
    try {
      const response: OneItem<Payment> = yield call(api.createConfirmPayment,
        confirm_payment,
      );
  
      if (response) {
        yield put(setConfirmPaymentDataSuccessAction(response));
      } else {
        throw new Error();
      }
    } catch (error: any) {
      yield put(setConfirmPaymentDataErrorAction(error));
      yield put(displayErrorAction(error?.message));
    }
  }


export const paymentSaga = function* (api: ApiType) {
  yield all([takeLatest(setPaymentDataAction.type, setPaymentDataRequest, api)]);
  yield all([takeLatest(setConfirmPaymentDataAction.type, setConfirmPaymentRequest, api)]);
};
