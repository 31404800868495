import { RootState } from '..';

export const getYarniversCurrentStepSelector = (state: RootState) => state.yarnivers.currentStep;

export const getYarniversDataSelector = (state: RootState) => state.yarnivers.data;

export const getYarniversColorsSelector = (state: RootState) => state.yarnivers.colors;

export const getYarniversLoadingSelector = (state: RootState) => state.yarnivers.isLoading;

export const getYarnImagesSelector = (state: RootState) => state.yarnivers.images;

export const getYarnPricesSelector = (state: RootState) => state.yarnivers.prices;
