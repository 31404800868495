import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collections } from '@/common/interfaces/collection.interface';
import { Resource } from '@/common/interfaces/resource.interface';
import { ResourceData, ResourceSteps } from '../types/resource';
import { ResourceWithStage } from '../types/pattern';

export interface ResourceState {
  isLoading: boolean,
  ressources: ResourceWithStage[],
}

const initialState: ResourceState = {
  ressources: [],
  isLoading: false,
};

export const resourceAllSlice = createSlice({
  name: 'resource-all',
  initialState,
  reducers: {
    // Get ressources
    getAllRessourcesAction: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getAllRessourcesSuccessAction: (state, action: PayloadAction<ResourceWithStage[]>) => {
      state.isLoading = false;
      state.ressources = action.payload;
    },
    getAllRessourcesErrorAction: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  getAllRessourcesAction,
  getAllRessourcesSuccessAction,
  getAllRessourcesErrorAction,
} = resourceAllSlice.actions;
export const resourceAllReducer = resourceAllSlice.reducer;
