import {
  YarnColorCollectionItem,
  YarnManufacturerCollectionItem,
} from '@/common/interfaces/collection.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { searchYarnManufacturerAction } from '@/store/reducers/collection.reducer';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { Grid } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from './components/colors';
import { Selector } from './components/selector';
import {
  Container,
  InputStyled,
  SearchContainer,
  SearchIconStyled,
  Title,
  FilterCol,
} from './styles';

interface Props {
  onAddYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
}

export const Yarnivers: FC<Props> = (props) => {
  const { onAddYarn } = props;

  const dispatch = useDispatch();

  const [selectedYarn, setSelectedYarn] = useState<YarnCollectionItemWithStage>();

  const [selectedManufacturer, setSelectedManufacturer] =
    useState<YarnManufacturerCollectionItem>();

  const yarnTitle = useMemo(
    () => selectedManufacturer?.name + ': ' + selectedYarn?.name,
    [selectedManufacturer, selectedYarn],
  );

  const handleSearch = (value: string) => {
    dispatch(searchYarnManufacturerAction(value));
  };

  const [searchValue, setSearchValue] = useInputWithDelay('', handleSearch);

  return (
    <Container container>
      <FilterCol laptop={6} mobile={12}>
        <Title variant='h2'>Yarnivers</Title>
        <SearchContainer>
          <SearchIconStyled />
          <InputStyled
            placeholder='Search in Yarnivers'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchContainer>
        <Selector
          selectedYarn={selectedYarn}
          setSelectedYarn={setSelectedYarn}
          selectedManufacturer={selectedManufacturer}
          setSelectedManufacturer={setSelectedManufacturer}
        />
      </FilterCol>
      <Grid laptop={6} mobile={12}>
        <Colors title={yarnTitle} yarn={selectedYarn} onAddYarn={onAddYarn} />
      </Grid>
    </Container>
  );
};
