export class LocalStorageBase {
  public name = 'LocalStorageBase';
  constructor(name: string) {
    this.name = name;
  }

  protected remove(): void {
    localStorage.removeItem(this.name);
  }

  protected get(): any {
    const obj = localStorage.getItem(this.name);
    return obj ? JSON.parse(obj) : {};
  }

  protected set(key: string, value: string): void {
    const obj = {
      ...this.get(),
      [key]: value,
    };
    localStorage.setItem(this.name, JSON.stringify(obj));
  }
}
