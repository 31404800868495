import { PATH } from '@/common/constants/path';
import { styled } from '@mui/material/styles';
import { FC, memo, useState } from 'react';
import { ResourceWithStage } from '@/store/types/pattern';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ResourceTypes } from '@/common/constants/resources';
import { ImgVideo } from '@/components/img-video/img-video';

const apiPath = process.env.REACT_APP_API_URL || '';

export interface ResourcesItemProps {
  resource: ResourceWithStage;
  onOpenPreview: (resourceId: number) => void;
}

const ListEl = styled('li')({
  borderBottom: '1px solid #000',
  marginBottom: '1.5rem',
  position: 'relative',
  ':hover .MuiButtonBase-root': {
    visibility: 'visible',
  },
});

const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  paddingBottom: '66%',
});

const Title = styled('p')({
  margin: 0,
  fontSize: '1rem',
  lineHeight: '1.5rem',
});

const CopyBtn = styled(Button)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  backgroundColor: '#fff',
  color: '#000',
  visibility: 'hidden',
  ':hover': {
    backgroundColor: '#F6F4ED',
  },
});

export const ResourceItem: FC<ResourcesItemProps> = memo(({ resource, onOpenPreview }) => {
  const { t } = useTranslation();
  const { name, files, id } = resource;

  //@ts-ignore
  const fileId = files ? files[0]?.file : '';
  const fileSrc = `${apiPath}/assets/${fileId}`;

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(
      `https://${window.location.host}${PATH.RESOURCE_VIEWER}?id=${id}&type=${ResourceTypes.RESOURCE}`,
    );
  };

  const onOpenPreviewHandler = () => {
    resource.id && onOpenPreview(resource.id);
  };

  return (
    <ListEl>
      {!!fileId && <Image src={fileSrc} onClick={onOpenPreviewHandler} />}
      <CopyBtn onClick={copyLinkHandler}>{t('common.copyLink')}</CopyBtn>
      <Title>{name}</Title>
    </ListEl>
  );
});
