import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

export const StepsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
  },
}));

export const TabsEl = styled(Tabs)({
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.MuiTabs-flexContainer': {
    flexDirection: 'column',
  },
});
