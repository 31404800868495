import { styled, TextareaAutosize } from '@mui/material';
import { Input } from '@/components/input/input';

export const Content = styled('div')(({ theme }) => ({
  padding: '2rem',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    marginBottom: '2rem',
    padding: '1rem',
  },
}));

export const Name = styled('h1')(({ theme }) => ({
  margin: 0,
  fontSize: '2.5rem',
  fontWeight: 400,
  [theme.breakpoints.down('tablet')]: {
    fontSize: '2rem',
  },
}));

export const Description = styled('div')(({ theme }) => ({
  fontSize: '1.375rem',
  fontWeight: 400,
  margin: '1rem 0',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1rem',
  },
}));

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  border: 'none',
  outline: 'none',
  minHeight: '16rem',
  width: '100%',
  fontSize: '1.125rem',
  paddingTop: '1rem',
  height: '25rem',
  maxHeight: '25rem',
  overflow: 'auto !important',
  resize: 'none',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1rem',
    paddingTop: 0,
  },
}));

export const PriceTitle = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 400,
});

export const PriceSet = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Currency = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const PriceInput = styled(Input)(({ theme }) => ({
  fontSize: '3.125rem',
  padding: '0',
  '& ::before': {
    border: 'none !important',
  },
  '& ::after': {
    border: 'none !important',
  },
  '& .MuiInputBase-root': {
    width: '100%',
  },

  input: {
    '&::placeholder': {
      color: 'black',
      opacity: 1,
    },
  },
  maxWidth: '15rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const Technique = styled('div')(({ theme }) => ({
  height: '8.44rem',
  display: 'flex',
  marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
  },
}));

export const TechniqueTitle = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 400,
});

export const Block = styled('div')({});
