import { DirectusFields } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import { FileFolders } from '@/common/constants/fileFolders';
import { Collections, FileCollectionItem } from '@/common/interfaces/collection.interface';
import { Role } from '@/common/interfaces/role.interface';
import { User } from '@/common/interfaces/user.interface';
import { userLocalService } from '@/services/local-storage';
import {
  getBoughtPatternsAction,
  getUserByIdAction,
  getUserByIdSuccessAction,
  setCurrentRoleNameAction,
} from '@/store/reducers/user.reducer';
import { getCurrentUserRoleName } from '@/utils/getCurrentUserRole';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  createUserAction,
  createUserErrorAction,
  createUserSuccessAction,
  getCurrentUserAction,
  getCurrentUserErrorAction,
  getCurrentUserSuccessAction,
  getRolesAction,
  getRolesErrorAction,
  getRolesSuccessAction,
  getUserOrdersAction,
  getUserOrdersErrorAction,
  getUserOrdersSuccessAction,
  getUserPatternsAction,
  getUserPatternsErrorAction,
  getUserPatternsSuccessAction,
  getBoughtPatternsSuccessAction,
  getUserResourcesAction,
  getUserResourcesErrorAction,
  getUserResourcesSuccessAction,
  getUserYarnsAction,
  getUserYarnsSuccessAction,
  updateUserAction,
  updateUserErrorAction,
  updateUserSuccessAction,
  uploadAvatarAction,
  uploadAvatarErrorAction,
  uploadAvatarSuccessAction,
  userErrorAction,
} from '../reducers/user.reducer';
import { getCurrentUserSelector } from '../selectors/user.selector';
import { BoughtPatternWithStage, PatternWithStage, ResourceWithStage } from '../types/pattern';
import { OrderWithStage } from '../types/user';
import { YarnWithStage } from '../types/yarnivers';

function* createUserRequest(api: ApiType, action: ReturnType<typeof createUserAction>) {
  const { data, callback } = action.payload;
  try {
    const isRegistered: boolean = yield call(api.createUser, data);

    if (isRegistered) {
      yield put(createUserSuccessAction());
      yield call(callback, true);
    }
  } catch (error) {
    yield put(createUserErrorAction());
    yield call(callback, false);
  }
}

function* getRolesRequest(api: ApiType) {
  try {
    const roles: TransportResponse<Role[]> = yield call(api.getRoles);
    if (roles.data) {
      yield put(getRolesSuccessAction(roles.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(getRolesErrorAction());
  }
}

function* getCurrentUserRequest(api: ApiType, _: ReturnType<typeof getCurrentUserAction>) {
  try {
    const response: TransportResponse<User> = yield call(
      api.getCurrentUser,
      DirectusFields.CurrentUser,
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getCurrentUserSuccessAction(response.data));
  } catch (error) {
    yield put(getCurrentUserErrorAction());
  }
}

function* uploadAvatarRequest(api: ApiType, action: ReturnType<typeof uploadAvatarAction>) {
  try {
    const { avatar } = action.payload;

    const currentUser: User = yield select(getCurrentUserSelector);

    // Delete old avatar
    if (currentUser.avatar) {
      yield call(api.deleteFile, currentUser.avatar);
    }

    // Upload new avatar
    const responseFile: FileCollectionItem = yield call(
      api.uploadFile,
      FileFolders.AvatarImages,
      avatar,
    );

    if (!responseFile) {
      throw new Error();
    }
    // Set id to user
    const response: TransportResponse<User> = yield call(api.updatedCurrentUser, {
      avatar: responseFile.id,
    });
    if (!response.data) {
      throw new Error();
    }
    yield put(uploadAvatarSuccessAction(response.data));
  } catch (error) {
    yield put(uploadAvatarErrorAction());
  }
}

function* getUserPatternsRequest(api: ApiType, action: ReturnType<typeof getUserPatternsAction>) {
  try {
    console.log('Pattern user');
    const userId = action.payload;

    const response: TransportResponse<PatternWithStage[]> = yield call(
      api.getCollection,
      Collections.Patterns,
      {
        filter: {
          user_created: {
            _eq: userId,
          },
        },
        fields: DirectusFields.PatternProfile,
      },
    );
    if (!response.data) {
      throw new Error();
    }
    yield put(getUserPatternsSuccessAction(response.data || []));
  } catch (error) {
    yield put(getUserPatternsErrorAction());
  }
}

function* getBoughtPatternsRequest(api: ApiType, action: ReturnType<typeof getBoughtPatternsAction>) {
  console.log('Bought');
  try {
    const userId = action.payload;

    const response: TransportResponse<BoughtPatternWithStage[]> = yield call(
      api.getCollection,
      Collections.Payments,
      {
        filter: {
          user_created: {
            _eq: userId,
          },
        },
        fields: DirectusFields.BoughtPatternProfile,
      },
    );
    if (!response.data) {
      throw new Error();
    }
    
    yield put(getBoughtPatternsSuccessAction(response.data || []));
  } catch (error) {
    yield put(getUserPatternsErrorAction());
  }
}

function* getUserResourcesRequest(api: ApiType, action: ReturnType<typeof getUserResourcesAction>) {
  try {
    const userId = action.payload;

    const response: TransportResponse<ResourceWithStage[]> = yield call(
      api.getCollection,
      Collections.Resources,
      {
        filter: {
          user_created: {
            _eq: userId,
          },
        },
        fields: DirectusFields.Resource,
      },
    );
    if (!response.data) {
      throw new Error();
    }
    yield put(getUserResourcesSuccessAction(response.data || []));
  } catch (error) {
    yield put(getUserResourcesErrorAction());
  }
}

function* getUserYarnsRequest(api: ApiType, action: ReturnType<typeof getUserYarnsAction>) {
  try {
    const userId = action.payload;

    const response: TransportResponse<YarnWithStage[]> = yield call(
      api.getCollection,
      Collections.Yarn,
      {
        filter: {
          user_created: {
            _eq: userId,
          },
        },
        fields: DirectusFields.YarnProfile,
      },
    );

    if (!response.data) {
      throw new Error();
    }
    yield put(getUserYarnsSuccessAction(response.data || []));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(userErrorAction());
  }
}

function* updateUserRequest(api: ApiType, action: ReturnType<typeof updateUserAction>) {
  try {
    const userDto = action.payload;

    const response: TransportResponse<User> = yield call(api.updatedCurrentUser, userDto, {});

    if (!response.data) {
      throw new Error();
    }

    yield put(updateUserSuccessAction(response.data));
  } catch (error) {
    yield put(updateUserErrorAction());
  }
}

function* getUserOrdersRequest(api: ApiType) {
  try {
    const currentUser: User = yield select(getCurrentUserSelector);

    const response: TransportResponse<OrderWithStage[]> = yield call(
      api.getCollection,
      Collections.Orders,
      {
        filter: {
          orderby: {
            _in: currentUser.id,
          },
        },
        fields: DirectusFields.Order,
      },
    );

    if (!response.data) {
      throw new Error();
    }

    yield put(getUserOrdersSuccessAction(response.data || []));
  } catch (error) {
    yield put(getUserOrdersErrorAction());
  }
}

function* setCurrentRoleName(action: ReturnType<typeof setCurrentRoleNameAction>) {
  userLocalService.adminSelectedRole = getCurrentUserRoleName(action.payload);
  yield;
}

function* getUserByIdRequest(api: ApiType, action: ReturnType<typeof getUserByIdAction>) {
  try {
    const userId = action.payload;

    const response: TransportResponse<User> = yield call(
      api.getUserById,
      userId,
      DirectusFields.CurrentUser,
    );

    if (!response.data) {
      throw new Error(Errors.UserNotFound);
    }

    yield put(getUserByIdSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(userErrorAction());
  }
}

export const userSaga = function* (api: ApiType) {
  yield all([takeLatest(createUserAction.type, createUserRequest, api)]);
  yield all([takeLatest(getRolesAction.type, getRolesRequest, api)]);
  yield all([takeLatest(getCurrentUserAction.type, getCurrentUserRequest, api)]);
  yield all([takeLatest(uploadAvatarAction.type, uploadAvatarRequest, api)]);
  yield all([takeLatest(getUserPatternsAction.type, getUserPatternsRequest, api)]);
  yield all([takeLatest(getBoughtPatternsAction.type, getBoughtPatternsRequest, api)]);
  yield all([takeLatest(updateUserAction.type, updateUserRequest, api)]);
  yield all([takeLatest(getUserOrdersAction.type, getUserOrdersRequest, api)]);
  yield all([takeLatest(getUserResourcesAction.type, getUserResourcesRequest, api)]);
  yield all([takeLatest(getUserYarnsAction.type, getUserYarnsRequest, api)]);
  yield all([takeLatest(setCurrentRoleNameAction.type, setCurrentRoleName)]);
  yield all([takeLatest(getUserByIdAction.type, getUserByIdRequest, api)]);
};
