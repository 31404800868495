import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Popover, styled } from '@mui/material';
import { FC } from 'react';

interface Props {
  anchorEl: HTMLButtonElement | HTMLDivElement | null;
  onClose: () => void;
  onSelect: (id: number) => void;
  colors: YarnColorCollectionItem[] | null | undefined;
}

export const ColorPicker: FC<Props> = (props) => {
  const { anchorEl, onClose, onSelect, colors } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!anchorEl || !colors || !colors.length) {
    return null;
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Colors colors={colors} onChange={onSelect} />
    </Popover>
  );
};

interface ColorsProps {
  colors: YarnColorCollectionItem[];
  onChange: (id: number) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '1rem',
});

const Item = styled('img')({
  width: 'calc(5rem + 2px)',
  height: 'calc(5rem + 2px)',
  marginLeft: '0.5rem',
  marginBottom: '0.5rem',
  cursor: 'pointer',
});

export const Colors: FC<ColorsProps> = (props) => {
  const { colors, onChange } = props;
  return (
    <Container>
      {colors.map((el) => (
        <Item
          key={el.id}
          src={getAssetBackendUrl(el.image)}
          title={el.name}
          alt={el.name}
          onClick={() => onChange(el.id)}
        ></Item>
      ))}
    </Container>
  );
};
