import { InputButtonSelect } from "@/components/input-button-select";
import useResource from "@/hooks/api/useResource";
import { createApi } from "@/services/api";
import { getCollectionDataSelector } from "@/store/selectors/collection.selector";
import { getPatternAvailableSizesSelector } from "@/store/selectors/pattern.selector";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface Props {
  currentStepId: number;
}

export const RelevantSizes = ({ currentStepId }: Props) => {
  const availableSizes = useSelector(getPatternAvailableSizesSelector);
  const { AvailableSize: sizeData } = useSelector(getCollectionDataSelector);

  const { data: steps, fetchData } = useResource('item/pattern_step/' + currentStepId) as { data: null | { data: { id: number, relevant_sizes: number[] } }, fetchData: () => void };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const relevantSizes = steps?.data?.relevant_sizes;
  if (!relevantSizes) return null;

  return <>
    {availableSizes.map(sizeId => {
      const checked = relevantSizes.includes(sizeId);
      return <InputButtonSelect
        key={sizeId}
        label={sizeData.find(y => y.id === sizeId)?.size_name}
        value={sizeId}
        checked={checked}
        onChange={() => {
           createApi().updateStepSizes(currentStepId,
            checked
              ? relevantSizes.filter(x => x !== sizeId)
              : [...relevantSizes, sizeId])
            .then(fetchData)
        }}
      />}
    )}
  </>
};
