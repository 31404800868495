import { Collections } from '@/common/interfaces/collection.interface';
import {
  AccordionWithBlock,
  AccordionWithBlockData,
  AccordionWithBlockItemData,
} from '@/components/accordion-with-block';
import { getCollectionsAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  onChangeAdditional: (value: number, type: Collections | string) => void;
}

export const YarnFilter: FC<Props> = (props) => {
  const { onChangeAdditional } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Gauge, FiberType, NeedleSize, WeightClass, Countries, YarnManufacturer } =
    useSelector(getCollectionDataSelector);

  const [accordionData, setAccordionData] = useState<AccordionWithBlockData>({
    expanded: null,
    data: [],
  });

  useEffect(() => {
    dispatch(
      getCollectionsAction([
        Collections.Gauge,
        Collections.FiberType,
        Collections.NeedleSize,
        Collections.WeightClass,
        Collections.Countries,
        Collections.YarnManufacturer,
      ]),
    );
  }, []);

  useEffect(() => {
    if (
      Gauge.length &&
      FiberType.length &&
      NeedleSize.length &&
      WeightClass.length &&
      YarnManufacturer.length
    ) {
      setAccordionData({
        expanded: null,
        data: [
          {
            label: t('search.yarnFilter.fiber'),
            type: Collections.FiberType,
            items: FiberType.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.gauge'),
            type: Collections.Gauge,
            items: Gauge.map((item) => ({ id: item.id, label: item.thickness })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.needleSize'),
            type: Collections.NeedleSize,
            items: NeedleSize.map((item) => ({ id: item.id, label: item.size })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.weightClass'),
            type: Collections.WeightClass,
            items: WeightClass.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.country'),
            type: 'CountryOfOrigin',
            items: Countries.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.availableCountry'),
            type: 'AvailableCountry',
            items: Countries.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },

          {
            label: t('search.yarnFilter.supplier'),
            type: Collections.YarnManufacturer,
            items: YarnManufacturer.map((item) => ({ id: item.id, label: item.name || '' })),
            selected: [],
          },
        ],
      });
    }
  }, [Gauge, FiberType, NeedleSize, WeightClass, Countries, YarnManufacturer]);

  const handleExpanded = (value: Collections | string) => {
    setAccordionData((p) => ({
      ...p,
      expanded: p.expanded === value ? null : value,
    }));
  };

  const handleSelect = (value: AccordionWithBlockItemData, type: Collections | string) => {
    setAccordionData((p) => ({
      ...p,
      data: p.data.map((item) => {
        if (item.type === type) {
          return {
            ...item,
            selected: item.selected.includes(value)
              ? item.selected.filter((item) => item !== value)
              : [...item.selected, value],
          };
        } else {
          return item;
        }
      }),
    }));
    onChangeAdditional(value.id, type);
  };

  return (
    <>
      <AccordionWithBlock
        data={accordionData}
        onExpanded={handleExpanded}
        onSelect={handleSelect}
      />
    </>
  );
};
