import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import GalleyIconLeft from '@/assets/gallery-icon-left.svg';

interface GalleryItemProps {
  image: string | File;
}

const CarouselEl = styled(Carousel)({
  '.MuiButtonBase-root': {
    opacity: 1,
    background: 'none',
  },
});
const ImgEl = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: '80vh',
  objectFit: 'cover',
});

const VideoEl = styled('video')({
  display: 'block',
  width: '100%',
  maxHeight: '80vh',
  objectFit: 'contain',
});

export const GalleryItem: FC<GalleryItemProps> = ({ image }) => {
  const isFile = image instanceof File;
  const isVideo = isFile ? image.type.includes('video') : image.includes('.mp4');
  const src = isFile ? URL.createObjectURL(image) : image;
  return isVideo ? <VideoEl src={src} muted controls autoPlay loop /> : <ImgEl src={src} />;
};

interface GalleryProps {
  images?: (string | File)[];
}

export const Gallery: FC<GalleryProps> = ({ images = [] }) => {
  return (
    <CarouselEl
      autoPlay={false}
      animation='slide'
      NextIcon={<img src={GalleyIconLeft} style={{ transform: 'rotate(180deg)' }} />}
      PrevIcon={<img src={GalleyIconLeft} />}
      indicators={false}
    >
      {images.map((image, i) => (
        <GalleryItem image={image} key={i} />
      ))}
    </CarouselEl>
  );
};
