import { Button } from '@/components/button/button';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { MeasurementWithStage } from '@/store/types/collection';
import { TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import {
  TableContainer,
  TableDeleteIcon,
  TableSellDeleteStyled,
  TableSellNameStyled,
  TableSellStyled,
  TableStyled,
  TextFieldStyled,
  TableSellStyledItem,
} from './styles';

interface Props {
  measurements: MeasurementWithStage[];
  onAddMeasurement: () => void;
  onSizeValueChange: (id: number, value: string) => void;
  onNameChange: (id: number, name: string) => void;
  onRemoveMeasurement: (id: number) => void;
}

export const Table: FC<Props> = (props) => {
  const { measurements, onAddMeasurement, onSizeValueChange, onNameChange, onRemoveMeasurement } =
    props;

    const { t } = useTranslation();

  const headerMemo = useMemo(() => {
    return measurements?.[0]?.measurement_values.map((el) => el.size_chart);
  }, [measurements]);

  return (
    <>
      <Typography variant='body1' mt='1rem'>
        {t('patternizerSteps.unitOfMeasurementWriteText')}
      </Typography>
      <TableContainer>
        <TableStyled>
          {!!headerMemo?.length && (
            <TableHead>
              <TableRow>
                <TableSellStyled></TableSellStyled>
                {headerMemo.map((el) => (
                  <TableSellStyled key={el.id + '_size'}>{el.size_name}</TableSellStyled>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {measurements?.map((el) => (
              <TableRow key={el.id}>
                <TableSellNameStyled>
                  <InputWithDelay
                    name={el?.name}
                    placeholder='Type spesific measurement'
                    onChange={(value) => onNameChange(el.id, value)}
                  />
                </TableSellNameStyled>
                {el.measurement_values.map((_) => (
                  <TableSellStyledItem key={_.id}>
                    <InputWithDelay
                      name={_?.value}
                      placeholder='-'
                      onChange={(value) => onSizeValueChange(_.id, value)}
                    />
                  </TableSellStyledItem>
                ))}
                <TableSellDeleteStyled>
                  <TableDeleteIcon onClick={() => onRemoveMeasurement(el.id)} />
                </TableSellDeleteStyled>
              </TableRow>
            ))}
          </TableBody>
        </TableStyled>
      </TableContainer>

      <Button onClick={onAddMeasurement} style={{ backgroundColor: '#ffffff' }}>
        <Typography>{t('patternizerSteps.addMeasurement')}</Typography>
      </Button>
    </>
  );
};

interface InputWithDelayProps {
  name: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const InputWithDelay: FC<InputWithDelayProps> = (props) => {
  const { name, placeholder, onChange } = props;

  const [value, setValue] = useInputWithDelay(name, onChange, 500);

  return (
    <TextFieldStyled
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
