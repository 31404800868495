import { PATH } from '@/common/constants/path';
import { getPatternImagesAction, publishPatternAction } from '@/store/reducers/pattern.reducer';
import {
  getPatternDataSelector,
  getPatternImagesSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Info } from './components/Info';
import { Container, Photo } from './styles';

export const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pattern = useSelector(getPatternDataSelector);

  const images = useSelector(getPatternImagesSelector);

  const { currentStep } = useSelector(getPatternizerSelector);

  const tabIsOpened = useMemo(() => {
    return PatternizerStepsKeys.PREVIEW === currentStep;
  }, [currentStep]);

  useEffect(() => {
    if (tabIsOpened && pattern.id) {
      dispatch(getPatternImagesAction({ pattern: pattern }));
    }
  }, [tabIsOpened]);

  const handlePublish = () => {
    dispatch(
      publishPatternAction({
        patternId: pattern.id as number,
        callback: (isSuccess) => {
          if (isSuccess) {
            navigate(PATH.PROFILE);
          }
        },
      }),
    );
  };

  return (
    <Container>
      <Photo images={images} main_image={images.find((_) => _.id === pattern.main_image)} />
      <Info pattern={pattern} onPublish={handlePublish} />
    </Container>
  );
};
