import { DropdownGroup } from '@/components/dropdown-group';
import {
  addFiberAction,
  getFibersByYarnIdAction,
  removeFiberAction,
  updateFiberAction,
} from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniversCurrentStepSelector,
  getYarniversDataSelector,
} from '@/store/selectors/yarnivers.selector';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YarniversStepsKeys } from '../../../steps';
import { ButtonLabelStyled, ButtonStyled, Container } from './styles';

export const Fiber = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector(getYarniversCurrentStepSelector);

  const { Fiber, FiberType, Countries } = useSelector(getCollectionDataSelector);

  const yarn = useSelector(getYarniversDataSelector);

  const TYPES = FiberType.map((_) => ({ label: _.name, value: _.id }));
  const PERCENTS = new Array(100)
    .fill(null)
    .map((_, i) => ({ value: `${i + 1}%`, label: `${i + 1}%` }));
  const COUNTRIES = Countries.map((_) => ({ label: _.name, value: _.id }));

  const handleAdd = () => {
    if (!yarn.id) return;
    dispatch(
      addFiberAction({
        yarn: yarn,
        fiber: {},
      }),
    );
  };

  const handleChange = (id: number, type: string, value: string | number) => {
    dispatch(
      updateFiberAction({
        yarnId: yarn.id as number,
        fiber: {
          id,
          [type]: value,
        },
      }),
    );
  };

  const handleRemove = (id: number) => {
    dispatch(
      removeFiberAction({
        fiberId: id,
        yarn: yarn,
      }),
    );
  };

  useEffect(() => {
    if (yarn.id && currentStep === YarniversStepsKeys.Categorization) {
      dispatch(getFibersByYarnIdAction(yarn.id));
    }
  }, [currentStep, yarn]);

  return (
    <Container>
      {Fiber?.map((item, i) => (
        <DropdownGroup
          key={item?.id}
          id={item?.id as number}
          label={'Fiber ' + (i + 1)}
          data={[
            {
              type: 'type',
              value: item.type?.id as number,
              options: TYPES,
            },
            {
              type: 'pourcent',
              value: item.pourcent as string,
              options: PERCENTS,
            },
            {
              type: 'country',
              value: item.country?.id as number,
              options: COUNTRIES,
            },
          ]}
          onChange={(type, value) => handleChange(item.id as number, type, value)}
          onDelete={(id) => handleRemove(id)}
        />
      ))}
      <ButtonStyled onClick={handleAdd}>
        <ButtonLabelStyled>{`Add fiber`}</ButtonLabelStyled>
      </ButtonStyled>
    </Container>
  );
};
