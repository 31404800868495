import { ScreenSteps } from '@/components/screen-steps';
import { setYarniversStepAction } from '@/store/reducers/yarnivers.reducer';
import {
  getYarniversCurrentStepSelector,
  getYarniversDataSelector,
} from '@/store/selectors/yarnivers.selector';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Advertisement } from '../advertisement';
import { Categorization } from '../categorization';
import { Colors } from '../colors';
import { Start } from '../start';
import { WashAndCare } from '../wash-and-care';

export enum YarniversStepsKeys {
  Start = 'Start',
  Categorization = 'Categorization',
  WashAndCare = 'WashAndCare',
  Colors = 'Colors',
  Advertisement = 'Advertisement',
}

export const Steps = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const currentStep = useSelector(getYarniversCurrentStepSelector);

  const { id } = useSelector(getYarniversDataSelector);

  const handlePressNext = useCallback((step: YarniversStepsKeys) => {
    dispatch(setYarniversStepAction(step));
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab') as YarniversStepsKeys;
    if (tab) {
      dispatch(setYarniversStepAction(tab));
    }
  }, [searchParams]);

  useEffect(() => {
    const searchParams: { tab: YarniversStepsKeys; id?: string } = {
      tab: currentStep,
    };
    if (id) {
      searchParams['id'] = id.toString();
    }
    setSearchParams(searchParams);
  }, [currentStep, id]);

  const STEPS = [
    {
      id: YarniversStepsKeys.Start,
      title: 'Enter yarn name',
      content: <Start onPressNext={() => handlePressNext(YarniversStepsKeys.Categorization)} />,
    },
    {
      id: YarniversStepsKeys.Categorization,
      title: 'Categorization',
      content: (
        <Categorization onPressNext={() => handlePressNext(YarniversStepsKeys.WashAndCare)} />
      ),
    },
    {
      id: YarniversStepsKeys.WashAndCare,
      title: 'Wash and care',
      content: <WashAndCare onPressNext={() => handlePressNext(YarniversStepsKeys.Colors)} />,
    },
    {
      id: YarniversStepsKeys.Colors,
      title: 'Colors',
      content: <Colors onPressNext={() => handlePressNext(YarniversStepsKeys.Advertisement)} />,
    },
    {
      id: YarniversStepsKeys.Advertisement,
      title: 'Advertisement',
      content: <Advertisement />,
    },
  ];

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    dispatch(setYarniversStepAction(value as YarniversStepsKeys));
  };

  const getIsBlocked = useCallback(
    (key: string) => {
      if (!id) {
        return true;
      }
      switch (key) {
        default: {
          return false;
        }
      }
    },
    [id],
  );

  return (
    <>
      <ScreenSteps
        currentStep={currentStep}
        steps={STEPS}
        onStepChange={handleStepChange}
        getIsBlocked={getIsBlocked}
      />
    </>
  );
};
