import React, { FC, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import '@/index.css';
import '@/services/i18n';
import '@/index.css';
import '@/services/i18n';
import { AuthProvider } from '@/components/auth-provider/auth-provider';
import { getStore } from '@/store';
import './index.css';
import './services/i18n';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ClerkProvider } from '@clerk/clerk-react'
import { FeatureToggleProvider } from '@/context/FeatureToggleContext';

const store = getStore();
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';

const featureToogle = {
  PATTERN_DESIGNER: {
    settings: true,
    upload_patterns: true,
    upload_resources: true,
    payment_wall: true,
    publish_patterns: true,
    publish_resources: true,
  },
  RETAILER: {
    settings: true,
    upload_patterns: true,
    upload_resources: true,
  },
  YARN_SUPPLIER: {
    settings: false,
    upload_patterns: true,
    upload_resources: true,
  },
  KNITTER: {
    settings: true
  },
};

const Wrapper: FC = () => {

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <Suspense fallback={<LazyFallback />}>
              <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
                <FeatureToggleProvider features={featureToogle}>
                  <App />
                </FeatureToggleProvider>
              </ClerkProvider>
            </Suspense>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

root.render(<Wrapper />);
