import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const HomePage = lazy(() => import('./home-page'));

export const HomePageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <HomePage />
  </Suspense>
);
