import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  checkIsLoggedInAction,
  checkIsLoggedInFailedAction,
  checkIsLoggedInSuccessAction,
  forgotPasswordRequestAction,
  loginAction,
  loginFailedAction,
  loginSuccessAction,
  logoutAction,
  logoutErrorAction,
  logoutSuccessAction,
} from '../reducers/authorization.reducer';
import { openSnackbarAction } from '../reducers/system.reducer';

function* loginRequest(api: ApiType, action: ReturnType<typeof loginAction>) {
  const { data, callback } = action.payload;

  try {
    const isLoggedIn: boolean = yield call(api.login, data);
    if (isLoggedIn) {
      yield put(loginSuccessAction());
      yield call(callback, true);
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(loginFailedAction(error));
    yield put(openSnackbarAction({ message: 'Login failed', severity: 'error' }));
    yield call(callback, false);
  }
}

function* checkIsLoggedInRequest(api: ApiType) {
  try {
    const isLoggedIn: boolean = yield call(api.checkIsLoggedIn);

    if (isLoggedIn) {
      yield put(checkIsLoggedInSuccessAction());
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(checkIsLoggedInFailedAction(error));
  }
}

function* logoutRequest(api: ApiType, action: ReturnType<typeof logoutAction>) {
  try {
    const { callback } = action.payload;
    yield call(api.logout);
    yield put(logoutSuccessAction());
    yield call(callback);
  } catch (err) {
    yield put(logoutErrorAction());
  }
}

function* forgotPasswordRequestRequest(
  api: ApiType,
  action: ReturnType<typeof forgotPasswordRequestAction>,
) {
  try {
    const email = action.payload;
    const response: unknown = yield call(api.forgotRequest, email);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
}

export const authorizationSaga = function* (api: ApiType) {
  yield all([takeLatest(loginAction.type, loginRequest, api)]);
  yield all([takeLatest(checkIsLoggedInAction.type, checkIsLoggedInRequest, api)]);
  yield all([takeLatest(logoutAction.type, logoutRequest, api)]);
  yield all([takeLatest(forgotPasswordRequestAction.type, forgotPasswordRequestRequest, api)]);
};
