import { Button } from '@/components/button/button';
import {
  deletePatternStepAction,
  getPatternStepsAction,
  updatePatternStepAction,
} from '@/store/reducers/pattern.reducer';
import {
  getPatternDataSelector,
  getPatternStepsSelector,
} from '@/store/selectors/pattern.selector';
import { PatternStepWithStage } from '@/store/types/pattern';
import { DeltaStatic, Sources } from 'quill';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PatternSteps } from './components/pattern-steps/pattern-steps';
import { ResourcesSidebar } from './components/resources-sidebar/resources-sidebar';
import {
  ContentEl,
  PageContainerEl,
  TabsContainer,
  TabsSection,
  TabsTitle,
  TextEditorEl,
  TopContainer,
  TopSection,
} from './styles';

export interface PatternViewProps {
  patternId?: number;
  onCancel?: () => void;
}

export const PatternView: FC<PatternViewProps> = memo(({ patternId, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState<number | null>(null);
  const [currentStep, setCurrentStep] = useState<PatternStepWithStage | null>(null);
  const [stepContent, setStepContent] = useState('');
  const steps = useSelector(getPatternStepsSelector);
  const patternData = useSelector(getPatternDataSelector);

  useEffect(() => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
    }
  }, [patternId]);

  useEffect(() => {
    setCurrentStep(steps.find(({ id }) => currentStepId === id) || null);
  }, [steps, currentStepId]);

  useEffect(() => {
    setStepContent(currentStep?.instructions || '');
  }, [currentStep]);

  const onCancelHandler = () => {
    dispatch(
      deletePatternStepAction({
        stepId: steps.map((step) => step.id),
        callback: () => patternData.id && dispatch(getPatternStepsAction(patternData.id)),
      }),
    );
    if (onCancel) {
      onCancel();
    }
  };

  const stepResources = currentStep?.ressources || [];

  const getUpdatedPatternSteps = () => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
    }
  };

  const onContentChange = (value: string, _: DeltaStatic, source: Sources) => {
    if (source === 'api' || !currentStep) {
      return;
    }

    setStepContent(value);
  };

  const onContentBlur = () => {
    if (!patternId || !currentStep) {
      return;
    }
    dispatch(
      updatePatternStepAction({
        stepId: currentStep.id,
        data: {
          instructions: stepContent,
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onResourceSelect = (selectedResources: number[]) => {
    if (!patternId || !currentStep) {
      return;
    }

    dispatch(
      updatePatternStepAction({
        stepId: currentStep.id,
        data: {
          ressources: selectedResources.map((resources_id) => ({
            resources_id,
          })),
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  if (!patternId) {
    return null;
  }

  return (
    <>
      {!!onCancel && (
        <TopSection>
          <TopContainer>
            <span>{/* <LangSelector /> */}</span>
            <Button onClick={onCancelHandler}>{t('common.cancel')}</Button>
          </TopContainer>
        </TopSection>
      )}
      <TabsSection>
        <TabsContainer>
          <TabsTitle>{patternData.name}</TabsTitle>
        </TabsContainer>
      </TabsSection>
      <PageContainerEl>
        <PatternSteps patternId={patternId} onStepChange={setCurrentStepId} />
        <ContentEl>
          <TextEditorEl
            value={stepContent}
            onChange={onContentChange}
            onBlur={(_range, _source, editor) => {
              setTimeout(() => {
                const fixRange = editor.getSelection();
                if (!fixRange) {
                  onContentBlur();
                }
              }, 50);
            }}
          />
        </ContentEl>
        <ResourcesSidebar
        patternId={patternId}
        resources={stepResources}
        onResourceSelect={onResourceSelect}
        currentStep={currentStep}
        />
      </PageContainerEl>
    </>
  );
});
