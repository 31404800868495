import {
  GaugeCollectionItem,
  NeedleSizeCollectionItem,
  TextureCollectionItem,
  WashAndCareCollectionItem,
} from '@/common/interfaces/collection.interface';
import { FiberCollectionItemWithStage } from '@/store/types/collection';
import { YarnWithStage } from '@/store/types/yarnivers';

export interface YarnWithCollection {
  wash_and_care: {
    wash_and_care_id: WashAndCareCollectionItem;
  }[];
  gauge: {
    masketett_id: GaugeCollectionItem;
  }[];
  fiber: {
    fiber_id: FiberCollectionItemWithStage;
  }[];
  needle_size: {
    needle_size_id: NeedleSizeCollectionItem;
  }[];
  texture: {
    texture_id: TextureCollectionItem;
  }[];
  thread_construction: {
    thread_construction_id: TextureCollectionItem;
  }[];
}

export const normalizeYarn = (yarn: YarnWithCollection): YarnWithStage => {
  return {
    ...yarn,
    wash_and_care: yarn.wash_and_care
      .filter((_) => !!_?.wash_and_care_id)
      .map((el) => el.wash_and_care_id),
    gauge: yarn.gauge.filter((_) => !!_?.masketett_id).map((el) => el.masketett_id),
    fiber: yarn.fiber.filter((_) => !!_?.fiber_id).map((el) => el.fiber_id),
    needle_size: yarn.needle_size.filter((_) => !!_?.needle_size_id).map((el) => el.needle_size_id),
    texture: yarn.texture.filter((_) => !!_?.texture_id).map((el) => el.texture_id),
    thread_construction: yarn.thread_construction.filter((_) => !!_?.thread_construction_id).map((el) => el.thread_construction_id),
  };
};
