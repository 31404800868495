import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarnivers';
import { Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Label, Title } from './styles';

interface Props {
  yarn: Yarn;
}

export const Summary: FC<Props> = (props) => {
  const { yarn } = props;

  const { Gauge, NeedleSize, WeightClass, Units, Countries } =
    useSelector(getCollectionDataSelector);

  const GaugeMemo = useMemo(() => {
    return Gauge.filter((item) => (yarn?.gauge || []).includes(item.id))
      .map((el) => el?.thickness)
      .join(', ');
  }, [Gauge, yarn.gauge]);

  const NeedleSizeMemo = useMemo(() => {
    return NeedleSize.filter((item) => (yarn?.needle_size || []).includes(item.id))
      .map((el) => el?.size)
      .join(', ');
  }, [NeedleSize, yarn.needle_size]);

  const WeightClassMemo = useMemo(() => {
    return WeightClass.filter((item) => (yarn?.weight_class || []).includes(item.id))
      .map((el) => el?.name)
      .join(', ');
  }, [WeightClass, yarn.weight_class]);

  const AmountMemo = useMemo(() => {
    return `${yarn.amount_weight} ${Units.find(
      (_) => _.id === yarn.amount_weight_unit,
    )?.name.toLowerCase()} / ${yarn.amount_lenght} ${Units.find(
      (_) => _.id === yarn.amount_lenght_unit,
    )?.name.toLowerCase()}`;
  }, [yarn]);

  const CountryOfOriginMemo = useMemo(() => {
    return Countries.find((_) => _.id === yarn.country_of_origin)?.name;
  }, [yarn.country_of_origin, Container]);

  return (
    <Container>
      <Label variant='h3'>Summary</Label>

      <Grid container direction='row'>
        <Title variant='h4'>Gauge:</Title>
        <Typography variant='h4'>{GaugeMemo}</Typography>
      </Grid>

      <Grid container direction='row'>
        <Title variant='h4'>Needle size:</Title>
        <Typography variant='h4'>{NeedleSizeMemo}</Typography>
      </Grid>

      <Grid container direction='row'>
        <Title variant='h4'>Weight class</Title>
        <Typography variant='h4'>{WeightClassMemo}</Typography>
      </Grid>

      {yarn.amount_lenght && yarn.amount_weight && (
        <Grid container direction='row'>
          <Title variant='h4'>Amount:</Title>
          <Typography variant='h4'>{AmountMemo}</Typography>
        </Grid>
      )}

      <Grid container direction='row'>
        <Title variant='h4'>Country of origin:</Title>
        <Typography variant='h4'>{CountryOfOriginMemo}</Typography>
      </Grid>
    </Container>
  );
};
