import { PageContentCollectionItem } from '@/common/interfaces/pages-content.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatternWithStage } from '../types/pattern';
import { User } from '@/common/interfaces/user.interface';

export interface CollectionState {
  isLoading: boolean;
  isRecentUploadsLoading: boolean;
  isPromotedPatternsLoading: boolean;
  isDesignersOfTheWeekLoading: boolean;
  content: PageContentCollectionItem[];
  promotedPatterns: PatternWithStage[];
  recentUploads: PatternWithStage[];
  designersOfTheWeek: User[];
  promotedYarn: PatternWithStage[];
  isPromotedYarnLoading: boolean;
}

const initialState: CollectionState = {
  isLoading: false,
  isRecentUploadsLoading: false,
  isPromotedPatternsLoading: false,
  isDesignersOfTheWeekLoading: false,
  content: [],
  promotedPatterns: [],
  recentUploads: [],
  designersOfTheWeek: [],
  promotedYarn: [],
  isPromotedYarnLoading: false
};

export const collectionSlice = createSlice({
  name: 'pages-content',
  initialState,
  reducers: {
    // Get pages content
    getPagesContentAction: (state) => {
      state.isLoading = true;
    },
    getPagesContentSuccessAction: (state, action: PayloadAction<PageContentCollectionItem[]>) => {
      state.isLoading = false;
      state.content = { ...action.payload };
    },
    getPagesContentErrorAction: (state) => {
      state.isLoading = false;
    },
    // Get recent uploads
    getRecentUploadsAction: (state) => {
      state.isRecentUploadsLoading = true;
    },
    getRecentUploadsSuccessAction: (state, action: PayloadAction<PatternWithStage[]>) => {
      state.isRecentUploadsLoading = false;
      state.recentUploads = action.payload;
    },
    getRecentUploadsErrorAction: (state) => {
      state.isRecentUploadsLoading = false;
    },
    // Get promoted patterns
    getPromotedPatternsAction: (state) => {
      state.isPromotedPatternsLoading = true;
    },
    getPromotedPatternsSuccessAction: (state, action: PayloadAction<PatternWithStage[]>) => {
      state.isPromotedPatternsLoading = false;
      state.promotedPatterns = action.payload;
    },
    getPromotedPatternsErrorAction: (state) => {
      state.isPromotedPatternsLoading = false;
    },
    // Get designers of the week
    getDesignersOfTheWeekAction: (state) => {
      state.isDesignersOfTheWeekLoading = true;
    },
    getDesignersOfTheWeekSuccessAction: (state, action: PayloadAction<User[]>) => {
      state.isDesignersOfTheWeekLoading = false;
      state.designersOfTheWeek = action.payload;
    },
    getDesignersOfTheWeekErrorAction: (state) => {
      state.isDesignersOfTheWeekLoading = false;
    }   
  },
});

export const {
  getPagesContentAction,
  getPagesContentSuccessAction,
  getPagesContentErrorAction,
  getRecentUploadsAction,
  getRecentUploadsSuccessAction,
  getRecentUploadsErrorAction,
  getPromotedPatternsAction,
  getPromotedPatternsSuccessAction,
  getPromotedPatternsErrorAction,
  getDesignersOfTheWeekAction,
  getDesignersOfTheWeekErrorAction,
  getDesignersOfTheWeekSuccessAction,
} = collectionSlice.actions;

export const pagesContentReducer = collectionSlice.reducer;
