import { LocalStorageBase } from './base';

enum KEYS {
  userId = 'userId',
}

export class SSELocalStorage extends LocalStorageBase {
  constructor() {
    super('SSELocalStorage');
  }

  get sseUserId() {
    const obj = this.get();
    return obj[KEYS.userId] || '';
  }

  set sseUserId(userId: string) {
    this.set(KEYS.userId, userId);
  }
}
