import { styled } from '@mui/material/styles';
import { PageContainer } from '@/components/page-container/page-container';

export const PageContainerEl = styled(PageContainer)({});

export const TableEl = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  marginBottom: '2rem',
});

export const TableCell = styled('td')({
  border: '1px solid #000',
  padding: '2rem',
  verticalAlign: 'top',
});

export const DescrEl = styled('p')({
  fontSize: '1.125rem',
  padding: '2rem 0',
  margin: 0,
});

export const CellHead = styled('p')({
  fontSize: '2.5rem',
  margin: '0 0 2rem',
});
