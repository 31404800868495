import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material';
import { FC } from 'react';
import { ResourceView } from '@/components/resource-view';
import { CloseBtn } from '@/components/close-btn';

interface ResourceViewDrawerProps extends DrawerProps {
  resourceId: number | null;
  resourceType: 'resource' | 'diagram';
}

const ResourceContainer = styled('div')(({ theme }) => ({
  width: '70vw',
  [theme.breakpoints.down('tablet')]: {
    width: '100vw',
  },
}));

export const ResourceViewDrawer: FC<ResourceViewDrawerProps> = (props) => {
  const { resourceId, resourceType, onClose, ...rest } = props;

  return (
    <Drawer anchor='left' onClose={onClose} open={!!resourceId} {...rest}>
      <ResourceContainer>
        {resourceId && <ResourceView resourceId={resourceId} resourceType={resourceType} />}
      </ResourceContainer>
      <CloseBtn onClick={onClose as any} />
    </Drawer>
  );
};
