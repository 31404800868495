import { LeftMenu } from '@/components/horizontal-tabs/components/left-menu';
import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';
import { Container, ContentCol, MenuCol, TopContainer } from './styles';

interface HorizontalTabsProps {
  title?: ReactNode;
  description?: ReactNode;
  menuItems: { id: string; label: string; description?: string }[];
  selectedId: string;
  onSelect: (item: { id: string; label: string }) => void;
  children: ReactNode;
  heightByContent?: boolean;
  menuContainerStyles?: CSSProperties;
  contentContainerStyles?: CSSProperties;
}

export const HorizontalTabs: FC<HorizontalTabsProps> = (props) => {
  const {
    menuItems,
    selectedId,
    onSelect,
    description,
    title,
    children,
    heightByContent,
    menuContainerStyles,
    contentContainerStyles,
  } = props;

  const menuRef = useRef<HTMLDivElement | null>(null);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!heightByContent) return;
    if (!menuRef.current?.offsetHeight || !contentRef.current?.offsetHeight) return;

    const maxHeight = Math.min(menuRef.current.offsetHeight, contentRef.current.offsetHeight);

    menuRef.current.style.maxHeight = `${maxHeight}px`;
    contentRef.current.style.maxHeight = `${maxHeight - 0.5}px`;
  }, [heightByContent, menuRef.current, contentRef.current]);

  return (
    <Container container>
      <MenuCol item mobile={12} laptop={6} ref={menuRef} style={menuContainerStyles}>
        {(title || description) && (
          <TopContainer>
            {title}
            {description}
          </TopContainer>
        )}
        <LeftMenu items={menuItems} selectedId={selectedId} onSelect={onSelect} />
      </MenuCol>
      <ContentCol item mobile={12} laptop={6} ref={contentRef} style={contentContainerStyles}>
        {children}
      </ContentCol>
    </Container>
  );
};
