export const getAssetBackendUrl = (id?: string | null): string | undefined => {
  const BACKEND_API = process.env.REACT_APP_API_URL;
  return !!id ? `${BACKEND_API}/assets/${id}` : undefined;
};

export const isEmptyAssetBackendUrl = (url?: string | null): boolean => {
  if (!url) return true;
  const BACKEND_API = process.env.REACT_APP_API_URL;
  return !url.replace(`${BACKEND_API}/assets/`, '');
};
