import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginPayload, LogoutPayload } from '../types/authorization';

export interface AuthorizationState {
  isLoading: boolean;
  error: null;
  isLoggedIn: boolean;
  checkAuthLoading: boolean;
}

const initialState: AuthorizationState = {
  isLoading: false,
  error: null,
  isLoggedIn: false,
  checkAuthLoading: false,
};

export const authorizationSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginAction(state, _: PayloadAction<LoginPayload>) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccessAction(state) {
      state.isLoading = false;
      state.error = null;
      state.isLoggedIn = true;
    },
    loginFailedAction(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
    },
    checkIsLoggedInAction(state) {
      state.checkAuthLoading = true;
      state.error = null;
    },
    checkIsLoggedInSuccessAction(state) {
      state.checkAuthLoading = false;
      state.error = null;
      state.isLoggedIn = true;
    },
    checkIsLoggedInFailedAction(state, action: PayloadAction<any>) {
      state.checkAuthLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
    },
    logoutAction(state, _: PayloadAction<LogoutPayload>) {
      state.isLoading = true;
    },
    logoutSuccessAction(state) {
      state.isLoading = false;
      state.isLoggedIn = false;
    },
    logoutErrorAction(state) {
      state.isLoading = false;
    },
    forgotPasswordRequestAction(state, _: PayloadAction<string>) {
      state.isLoading = true;
    },
    forgotPasswordRequestSuccessAction(state) {
      state.isLoading = false;
    },
    forgotPasswordRequestErrorAction(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  loginAction,
  loginSuccessAction,
  loginFailedAction,
  checkIsLoggedInAction,
  checkIsLoggedInSuccessAction,
  checkIsLoggedInFailedAction,
  logoutAction,
  logoutSuccessAction,
  logoutErrorAction,
  forgotPasswordRequestAction,
  forgotPasswordRequestSuccessAction,
  forgotPasswordRequestErrorAction,
} = authorizationSlice.actions;

export const authorizationReducer = authorizationSlice.reducer;
