import React, { FC, ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TabEl } from './styles';

export interface DraggableTabProps {
  index: number;
  value: number;
  label: ReactNode;
}

export const DraggableTab: FC<DraggableTabProps> = (props) => {
  return (
    <Draggable draggableId={`${props.index}`} index={props.index} disableInteractiveElementBlocking>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div ref={innerRef} {...draggableProps}>
          <TabEl {...props} {...dragHandleProps} />
        </div>
      )}
    </Draggable>
  );
};
