import { RootState } from '..';

export const getPatternViewLoadingSelector = (state: RootState) => state.patternView.isLoading;

export const getPatternViewDataSelector = (state: RootState) => state.patternView.data;

export const getBoughtPatternViewDataSelector = (state: RootState) => state.patternView.bought_pattern_data;

export const getResourcesViewDataSelector = (state: RootState) => state.patternView.resourceData;

export const getDiagramViewDataSelector = (state: RootState) => state.patternView.diagramData;
