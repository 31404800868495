import { Collections } from '@/common/interfaces/collection.interface';
import { Button } from '@/components/button/button';
import { HorizontalTabs } from '@/components/horizontal-tabs';
import { getYarnColorsAction, setYarnDataAction } from '@/store/reducers/yarnivers.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniversCurrentStepSelector,
  getYarniversDataSelector,
} from '@/store/selectors/yarnivers.selector';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YarniversStepsKeys } from '../steps';
import { Amount } from './components/amount';
import { Fiber } from './components/fiber';
import { Selectors } from './components/selectors';
import { use } from 'i18next';

interface Props {
  onPressNext: () => void;
}

export const Categorization: FC<Props> = (props) => {
  const { onPressNext } = props;
  const dispatch = useDispatch();

  const yarn = useSelector(getYarniversDataSelector);

  const currentStep = useSelector(getYarniversCurrentStepSelector);

  const { Gauge, NeedleSize, Countries, WeightClass, Texture, ThreadConstruction } = useSelector(getCollectionDataSelector);

  console.log('Texture', Texture);

  const MENU_ITEMS = [
    {
      id: Collections.Fiber,
      label: 'Fiber',
    },
    {
      id: Collections.Gauge,
      label: 'Gauge',
    },
    {
      id: Collections.Texture,
      label: 'Texture',
    },
    {
      id: Collections.ThreadConstruction,
      label: 'Construction',
    },
    {
      id: Collections.NeedleSize,
      label: 'Needle size',
    },

    {
      id: Collections.WeightClass,
      label: 'Weight class',
    },
    {
      id: 'Amount',
      label: 'Amount',
    },
    {
      id: 'CountryOfOrigin',
      label: 'Country of origin',
    },

    {
      id: 'CountryAvailable',
      label: 'Available Country',
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState<{ id: string; label: string }>(
    MENU_ITEMS[0],
  );

  const handleMenuChange = (item: { id: string; label: string }) => setSelectedMenuItem(item);

  const GAUGE = useMemo(() => {
    return Gauge.map((el) => ({ label: el.thickness, value: el.id }));
  }, [Gauge]);

  const NEEDLE_SIZE = useMemo(() => {
    return NeedleSize.map((el) => ({ label: el.size, value: el.id }));
  }, [NeedleSize]);

  const WIGHT_CLASS = useMemo(() => {
    return WeightClass.map((el) => ({ label: el.name, value: el.id }));
  }, [WeightClass]);

  const COUNTRY_OF_ORIGIN = useMemo(() => {
    return Countries.map((el) => ({ label: el.name, value: el.id }));
  }, [Countries]);

  const COUNTRY_AVAILABLE = useMemo(() => {
    return Countries.map((el) => ({ label: el.name, value: el.id }));
  }, [Countries]);

  const TEXTURE = useMemo(() => {
    return Texture.map((el) => ({ label: el.name, value: el.id }));
  }, [Texture]);

  const THREADCONSTRUCTION = useMemo(() => {
    return ThreadConstruction.map((el) => ({ label: el.name, value: el.id }));
  }, [ThreadConstruction]);

  const handleChangeCollections = (key: string, value: number) => {
    switch (key) {
      case 'country_of_origin':
        dispatch(
          setYarnDataAction({
            ...yarn,
            //@ts-ignore
            [key]: value,
          }),
        );
        break;
      case 'available_country':
        dispatch(
          setYarnDataAction({
            ...yarn,
            [key]: [
              //@ts-ignore
              ...(yarn[key] || []),
              //@ts-ignore
              value,
            ],
          }),
        );
        break;
      default:
        dispatch(
          setYarnDataAction({
            ...yarn,
            //@ts-ignore
            [key]: yarn[key]?.includes(value)
            //@ts-ignore
              ? yarn[key].filter((id) => id !== value)
              //@ts-ignore
              : [...(yarn[key] || []), value],
          }),
        );
    }
  };

  useEffect(() => {
    if (currentStep === YarniversStepsKeys.Colors && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
    }
  }, [currentStep]);

  if (currentStep !== YarniversStepsKeys.Categorization) return null;

  // useEffect(() => {
  //   console.log('selectedMenuItem.id', selectedMenuItem.id);
  // }, [selectedMenuItem]);

  return (
    <>
      <HorizontalTabs
        menuItems={MENU_ITEMS}
        selectedId={selectedMenuItem.id}
        onSelect={handleMenuChange}
        title={<Typography variant='h2'>Categorization</Typography>}
        contentContainerStyles={{ borderBottom: '1px solid #000' }}
        heightByContent={true}
      >
        {selectedMenuItem.id === Collections.Fiber && <Fiber />}
        {selectedMenuItem.id === Collections.Gauge && (
          <Selectors
            items={GAUGE}
            selectedItems={yarn.gauge || []}
            onChange={(value) => handleChangeCollections('gauge', value)}
          />
        )}
        {selectedMenuItem.id === Collections.Texture && (
          <Selectors
            items={TEXTURE}
            selectedItems={yarn.texture || []}
            onChange={(value) => handleChangeCollections('texture', value)}
          />
        )}
         {selectedMenuItem.id === Collections.ThreadConstruction && (
          <Selectors
            items={THREADCONSTRUCTION}
            selectedItems={yarn.thread_construction|| []}
            onChange={(value) => handleChangeCollections('thread_construction', value)}
          />
        )}
        {selectedMenuItem.id === Collections.NeedleSize && (
          <Selectors
            items={NEEDLE_SIZE}
            selectedItems={yarn.needle_size || []}
            onChange={(value) => handleChangeCollections('needle_size', value)}
          />
        )}
        {selectedMenuItem.id === Collections.WeightClass && (
          <Selectors
            items={WIGHT_CLASS}
            selectedItems={yarn.weight_class || []}
            onChange={(value) => handleChangeCollections('weight_class', value)}
          />
        )}
        {selectedMenuItem.id === 'Amount' && <Amount yarn={yarn} />}
        {selectedMenuItem.id === 'CountryOfOrigin' && (
          <Selectors
            items={COUNTRY_OF_ORIGIN}
            selectedItems={yarn?.country_of_origin ? [yarn.country_of_origin] : []}
            onChange={(value) => handleChangeCollections('country_of_origin', value)}
          />
        )}
        {selectedMenuItem.id === 'CountryAvailable' && (
          <Selectors
            items={COUNTRY_AVAILABLE}
            selectedItems={yarn?.available_country || []}
            onChange={(value) => handleChangeCollections('available_country', value)}
          />
        )}
      </HorizontalTabs>

      <Grid container width='100%' p='2rem'>
        <Button colored onClick={onPressNext}>
          Next
        </Button>
      </Grid>
    </>
  );
};
