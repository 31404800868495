import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { FC, SyntheticEvent } from 'react';

interface Props extends TabsProps {
  currentStep: string;
  steps: ScreenStep[];
  onStepChange: (_: SyntheticEvent, value: string) => void;
  getIsBlocked: (id: string) => boolean;
}

export interface ScreenStep {
  id: string;
  title: string;
  content: any;
}

const TabsContainer = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #000',
  padding: '2.5rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '0.5rem 0',
  },
}));

const TabsEl = styled(Tabs)(({ theme }) => ({
  maxWidth: '1600px',
  // padding: '0 2rem',
  margin: '0 auto',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  // Customized tabs according to Figma design
  '.MuiTab-root': {
    fontFamily: 'ES Allianz, sans-serif',
    fontSize: '20px',
    fontWeight: 'normal',
    color: '#000',
    textTransform: 'none',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
  },
}));

const TabEl = styled(Tab)({
  border: '1px solid #000',
  padding: '1rem',
  marginRight: '1rem',
  '&.Mui-selected': {
    backgroundColor: '#F6F4ED',
    fontWeight: 800,
    color: '#000',
  },
});

export const ScreenSteps: FC<Props> = (props) => {
  const { currentStep, steps, onStepChange, getIsBlocked } = props;
  return (
    <>
      <TabsContainer>
        <TabsEl value={currentStep} onChange={onStepChange} variant='scrollable'>
          {steps.slice(0).map(({ title, id }) => ( // slice(1) to not show "START" as step according to figma
            <TabEl label={title} key={id} value={id} disabled={getIsBlocked(id)} />
          ))}
        </TabsEl>
      </TabsContainer>
      {steps.map(({ id, content }) => (
        <div key={id} hidden={currentStep !== id}>
          {content}
        </div>
      ))}
    </>
  );
};
