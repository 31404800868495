import { YarnPriceCollectionItem } from '@/common/interfaces/collection.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setYarnDataAction, setYarnPriceAction } from '@/store/reducers/yarnivers.reducer';
import { Yarn } from '@/store/types/yarnivers';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Currency,
  Name,
  PriceInput,
  PriceSet,
  PriceTitle,
  Supplier,
  Textarea,
} from './styles';

interface Props {
  yarn: Yarn;
  username?: string;
  prices: YarnPriceCollectionItem[];
}

export const Info: FC<Props> = (props) => {
  const { yarn, username, prices } = props;

  const dispatch = useDispatch();

  const handleDescriptionChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, description: value }));
  };

  const [description, setDescription] = useInputWithDelay(
    yarn?.description || '',
    handleDescriptionChange,
    1000,
  );

  const setYarnPrice = (value: string) => {
    dispatch(setYarnPriceAction({ yarnId: yarn.id as number, value: +value }));
  };

  const [price, setPrice] = useInputWithDelay(
    prices?.[0]?.price?.toString() || '',
    setYarnPrice,
    1000,
  );

  useEffect(() => {
    if (!prices.length) return;
    setPrice(prices[0].price?.toString() || '');
  }, [prices]);

  useEffect(() => {
    if (!yarn.description) return;
    setDescription(yarn.description || '');
  }, [yarn.description]);

  return (
    <Container>
      <div>
        <Name variant='h3'>{yarn.name}</Name>
        <Supplier variant='h4'>{username}</Supplier>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='Write a description'
        />
      </div>
      <div>
        <PriceTitle>Price</PriceTitle>
        <PriceSet>
          <PriceInput
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            placeholder='Set Price'
            type='number'
            // style={{ width: price.length > 0 ? `${price.length * 2.5}rem` : '15rem' }}
          />
          <Currency>, - NOK</Currency>
        </PriceSet>
      </div>
    </Container>
  );
};
