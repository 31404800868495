import { useTranslation } from 'react-i18next';
import { ListItem } from '@mui/material';
import { PATH } from '@/common/constants/path';
import { ItemSearchType } from '@/common/interfaces/search.interface';
import { getMenuItems } from './menuItems';
// import { NavLink } from 'react-router-dom';

import {
    MenuContainer,
    HeaderLabelStyle,
    MenuLinkStyle,
    CloseButton,
    MenuHeader,
    StyledList,
    StyledNavLink,
    MenuByline
} from './styles';

export const SlideInMenu = ({ toggleDrawer }: { toggleDrawer: (newOpen: boolean) => void }) => {
    const { t } = useTranslation();
    const menuItems = getMenuItems(t);
    const handleClose = () => {
        toggleDrawer(false);
    };

    return (
        <MenuContainer role="presentation">
            <MenuHeader>
                <HeaderLabelStyle>{t('header.nav.menu')}</HeaderLabelStyle>
                <CloseButton variant='text' onClick={handleClose}>{t('common.close')}</CloseButton>
            </MenuHeader>
            <StyledList>
                {menuItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        {item.active ? (
                            <StyledNavLink to={item.link} onClick={() => handleClose()} >
                                <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                                <MenuByline active={item.active}>{item.byline}</MenuByline>
                            </StyledNavLink>
                        ) : (
                            <span>
                                <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                                <MenuByline active={item.active}>{item.byline}</MenuByline>
                            </span>
                        )}
                    </ListItem>
                ))}
            </StyledList>
        </MenuContainer>
    );
};
