import {
  AvailableSizeCollectionItem,
  Collections,
  CountryCollectionItem,
  CraftCollectionItem,
  FiberTypeCollectionItem,
  GaugeCollectionItem,
  LanguageCollectionItem,
  NeedleLengthCollectionItem,
  NeedleSizeCollectionItem,
  NeedleTypeCollectionItem,
  ProjectCollectionItem,
  ResourceCollectionItem,
  ResourceContentTypeCollectionItem,
  ResourceTypeCollectionItem,
  ShipmentMethodCollectionItem,
  SuitableCollectionItem,
  TechnicsCollectionItem,
  TPACollectionItem,
  TypeWashAndCareCollectionItem,
  UnitCollectionItem,
  UnitMeasurementCollectionItem,
  WashAndCareCollectionItem,
  WeightClassCollectionItem,
  YarnManufacturerCollectionItem,
  TextureCollectionItem,
  ThreadConstructionCollectionItem,
  PaymentWallCollectionItem,
} from '@/common/interfaces/collection.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddFiberPayload,
  AddYArnAlternativeItemPayload,
  FiberCollectionItemWithStage,
  MeasurementWithStage,
  NeedleCollectionItemWithStage,
  RemoveFiberPayload,
  RemoveMeasurementActionPayload,
  SetNeedleActionPayload,
  SetQuantityWithSizePayload,
  UpdateFiberPayload,
  UpdateMeasurementsActionPayload,
  UpdateMeasurementSizeActionPayload,
  UpdateYarnMeasurementPayload,
  YarnCollectionItemWithStage,
} from '../types/collection';
import {
  AddMeasurementActionPayload,
  RemoveNeedleActionPayload,
  UpdateAlternativeItemPayload,
  UpdateMeasurementNamePayload,
  YarnAlternativeWithStage,
} from './../types/collection';
import { logoutSuccessAction } from './authorization.reducer';

export interface CollectionState {
  isLoading: boolean;
  data: {
    Craft: CraftCollectionItem[];
    Projects: ProjectCollectionItem[];
    Languages: LanguageCollectionItem[];
    Suitable: SuitableCollectionItem[];
    Technics: TechnicsCollectionItem[];
    Gauge: GaugeCollectionItem[];
    NeedleType: NeedleTypeCollectionItem[];
    NeedleSize: NeedleSizeCollectionItem[];
    NeedleLength: NeedleLengthCollectionItem[];
    AvailableSize: AvailableSizeCollectionItem[];
    UnitMeasurement: UnitMeasurementCollectionItem[];
    Needle: NeedleCollectionItemWithStage[];
    Measurement: MeasurementWithStage[];
    Yarn: YarnCollectionItemWithStage[];
    YarnAlternative: YarnAlternativeWithStage[];
    PrivacyPolicy: TPACollectionItem[];
    TermCondition: TPACollectionItem[];
    About: TPACollectionItem[];
    ResourceType: ResourceTypeCollectionItem[];
    ResourceContentType: ResourceContentTypeCollectionItem[];
    PaymentWallPatternDesigner: PaymentWallCollectionItem[];
    PaymentWallYarnSupplier: PaymentWallCollectionItem[];
    PaymentWallLocalYarnStore: PaymentWallCollectionItem[];
    Resources: ResourceCollectionItem[];
    Countries: CountryCollectionItem[];
    FiberType: FiberTypeCollectionItem[];
    Fiber: FiberCollectionItemWithStage[];
    WeightClass: WeightClassCollectionItem[];
    YarnManufacturer: YarnManufacturerCollectionItem[];
    WashAndCare: WashAndCareCollectionItem[];
    Units: UnitCollectionItem[];
    TypeWashAndCare: TypeWashAndCareCollectionItem[];
    ShipmentMethods: ShipmentMethodCollectionItem[];
    Texture: TextureCollectionItem[];
    ThreadConstruction: ThreadConstructionCollectionItem[];
  };
}

const initialState: CollectionState = {
  isLoading: false,
  data: {
    Craft: [],
    Projects: [],
    Languages: [],
    Suitable: [],
    Technics: [],
    Gauge: [],
    NeedleType: [],
    NeedleSize: [],
    NeedleLength: [],
    AvailableSize: [],
    UnitMeasurement: [],
    Needle: [],
    Measurement: [],
    Yarn: [],
    YarnAlternative: [],
    PrivacyPolicy: [],
    TermCondition: [],
    About: [],
    ResourceType: [],
    ResourceContentType: [],
    PaymentWallPatternDesigner: [],
    PaymentWallYarnSupplier: [],
    PaymentWallLocalYarnStore: [],
    Resources: [],
    Countries: [],
    FiberType: [],
    Fiber: [],
    WeightClass: [],
    YarnManufacturer: [],
    WashAndCare: [],
    Units: [],
    TypeWashAndCare: [],
    ShipmentMethods: [],
    Texture: [],
    ThreadConstruction: [],
  },
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    // Get collections
    getCollectionsAction: (state, _: PayloadAction<Collections | Collections[]>) => {
      state.isLoading = true;
    },
    getCollectionsSuccessAction: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },
    getCollectionsErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    // Get needle by pattern id
    getNeedlesByPatternAction: (state, action: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getNeedlesByPatternSuccessAction: (
      state,
      action: PayloadAction<NeedleCollectionItemWithStage[]>,
    ) => {
      state.isLoading = false;
      state.data.Needle = action.payload;
    },
    getNeedlesByPatternErrorAction: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data.Needle = [];
    },
    // Set needle
    setNeedleAction: (state, _: PayloadAction<SetNeedleActionPayload>) => {
      state.isLoading = true;
    },

    //Remove needle
    removeNeedleAction: (state, _: PayloadAction<RemoveNeedleActionPayload>) => {
      state.isLoading = true;
    },
    removeNeedleSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Add Measurement
    addMeasurementAction: (state, action: PayloadAction<AddMeasurementActionPayload>) => {
      state.isLoading = true;
    },
    addMeasurementSuccessAction: (state) => {
      state.isLoading = false;
    },
    addMeasurementErrorAction: (state) => {
      state.isLoading = false;
    },
    // Update Measurement
    updateMeasurementsAction: (state, _: PayloadAction<UpdateMeasurementsActionPayload>) => {
      state.isLoading = true;
    },
    updateMeasurementsSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Update Measurement name
    updateMeasurementNameAction: (state, _: PayloadAction<UpdateMeasurementNamePayload>) => {
      state.isLoading = true;
    },
    updateMeasurementNameSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateMeasurementNameErrorAction: (state) => {
      state.isLoading = false;
    },

    // Update Measurement Available Sizes
    updateMeasurementSizeAction: (state, _: PayloadAction<UpdateMeasurementSizeActionPayload>) => {
      state.isLoading = true;
    },
    updateMeasurementSizeSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateMeasurementSizeErrorAction: (state) => {
      state.isLoading = false;
    },

    // Remove Measurement
    removeMeasurementAction: (state, _: PayloadAction<RemoveMeasurementActionPayload>) => {
      state.isLoading = true;
    },
    removeMeasurementSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Get measurements by pattern id
    getMeasurementsByPatternAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getMeasurementsByPatternSuccessAction: (
      state,
      action: PayloadAction<MeasurementWithStage[]>,
    ) => {
      state.isLoading = false;
      state.data.Measurement = action.payload;
    },
    getMeasurementsByPatternErrorActionError: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
      state.data.Measurement = [];
    },

    // Get yarn alternative by pattern id
    getYarnAlternativeByPatternAction: (state) => {
      state.isLoading = true;
    },
    getYarnAlternativeByPatternSuccessAction: (
      state,
      action: PayloadAction<YarnAlternativeWithStage[]>,
    ) => {
      state.isLoading = false;
      state.data.YarnAlternative = action.payload;
    },
    getYarnAlternativeByPatternErrorAction: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    // Add yarn alternative
    addYarnAlternativeAction: (state) => {
      state.isLoading = true;
    },
    addYarnAlternativeSuccessAction: (state) => {
      state.isLoading = false;
    },
    addYarnAlternativeErrorAction: (state) => {
      state.isLoading = false;
    },

    // Add alternative item
    addYarnAlternativeItemAction: (state, _: PayloadAction<AddYArnAlternativeItemPayload>) => {
      state.isLoading = true;
    },
    addYarnAlternativeItemSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Add alternative items
    addAlternativeItemsAction: (state, action: PayloadAction<YarnAlternativeWithStage>) => {
      state.isLoading = true;
    },
    addAlternativeItemsSuccessAction: (state) => {
      state.isLoading = false;
    },
    addAlternativeItemsErrorAction: (state) => {
      state.isLoading = false;
    },

    // Remove alternative items
    removeAlternativeItemsAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    removeAlternativeItemsSuccessAction: (state) => {
      state.isLoading = false;
    },
    removeAlternativeItemsErrorAction: (state) => {
      state.isLoading = false;
    },

    //Update alternative items
    updateAlternativeItemsAction: (state, _: PayloadAction<UpdateAlternativeItemPayload>) => {
      state.isLoading = true;
    },
    updateAlternativeItemsSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateAlternativeItemsErrorAction: (state) => {
      state.isLoading = false;
    },

    // Update yarn measurement item
    updateYarnMeasurementAction: (state, _: PayloadAction<UpdateYarnMeasurementPayload>) => {
      state.isLoading = true;
    },
    updateYarnMeasurementSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateYarnMeasurementErrorAction: (state) => {
      state.isLoading = false;
    },

    // Update yarn measurement if size changes
    updateYarnMeasurementSizeAction: (state) => {
      state.isLoading = true;
    },
    updateYarnMeasurementSizeSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateYarnMeasurementSizeErrorAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn alternative
    removeYarnAlternativeAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    removeYarnAlternativeSuccessAction: (state) => {
      state.isLoading = false;
    },
    removeYarnAlternativeErrorAction: (state) => {
      state.isLoading = false;
    },

    // Set Yarn Color
    setAlternativeItemsColorAction: (
      state,
      _: PayloadAction<{
        color: number;
        alternative: number;
      }>,
    ) => {
      state.isLoading = true;
    },
    setAlternativeItemsColorSuccessAction: (state) => {
      state.isLoading = false;
    },
    setAlternativeItemsColorErrorAction: (state) => {
      state.isLoading = false;
    },

    // Set quantity with size
    setQuantityWithSizeAction: (state, _: PayloadAction<SetQuantityWithSizePayload>) => {
      state.isLoading = true;
    },
    setQuantityWithSizeSuccessAction: (state) => {
      state.isLoading = false;
    },
    setQuantityWithSizeErrorAction: (state) => {
      state.isLoading = false;
    },

    //Get fiber by yarn id
    getFibersByYarnIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getFibersByYarnSuccessAction: (
      state,
      action: PayloadAction<FiberCollectionItemWithStage[]>,
    ) => {
      state.isLoading = false;
      state.data.Fiber = action.payload;
    },

    //Add fiber
    addFiberAction: (state, _: PayloadAction<AddFiberPayload>) => {
      state.isLoading = true;
    },
    addFiberSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Update fiber
    updateFiberAction: (state, _: PayloadAction<UpdateFiberPayload>) => {
      state.isLoading = true;
    },
    updateFiberSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove fiber
    removeFiberAction: (state, _: PayloadAction<RemoveFiberPayload>) => {
      state.isLoading = true;
    },
    removeFiberSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Search manufacturer
    searchYarnManufacturerAction(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.data.Yarn = [];
    },
    searchYarnManufacturerSuccessAction(
      state,
      action: PayloadAction<YarnManufacturerCollectionItem[]>,
    ) {
      state.isLoading = false;
      state.data.YarnManufacturer = action.payload;
    },

    // Get Yarn By Manufacturer
    getYarnByManufacturerAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnByManufacturerSuccessAction: (
      state,
      action: PayloadAction<YarnCollectionItemWithStage[]>,
    ) => {
      state.isLoading = false;
      state.data.Yarn = action.payload;
    },

    collectionErrorActions: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  getCollectionsAction,
  getCollectionsSuccessAction,
  getCollectionsErrorAction,
  getNeedlesByPatternAction,
  getNeedlesByPatternSuccessAction,
  getNeedlesByPatternErrorAction,
  setNeedleAction,
  removeNeedleAction,
  removeNeedleSuccessAction,
  addMeasurementAction,
  addMeasurementSuccessAction,
  addMeasurementErrorAction,
  updateMeasurementsAction,
  updateMeasurementsSuccessAction,
  updateMeasurementSizeAction,
  updateMeasurementSizeSuccessAction,
  updateMeasurementSizeErrorAction,
  updateMeasurementNameAction,
  updateMeasurementNameSuccessAction,
  updateMeasurementNameErrorAction,
  getMeasurementsByPatternAction,
  getMeasurementsByPatternSuccessAction,
  getMeasurementsByPatternErrorActionError,
  removeMeasurementAction,
  removeMeasurementSuccessAction,
  getYarnAlternativeByPatternAction,
  getYarnAlternativeByPatternSuccessAction,
  getYarnAlternativeByPatternErrorAction,
  addYarnAlternativeAction,
  addYarnAlternativeSuccessAction,
  addYarnAlternativeErrorAction,
  updateAlternativeItemsAction,
  updateAlternativeItemsSuccessAction,
  updateAlternativeItemsErrorAction,
  updateYarnMeasurementAction,
  updateYarnMeasurementSuccessAction,
  updateYarnMeasurementErrorAction,
  updateYarnMeasurementSizeAction,
  updateYarnMeasurementSizeSuccessAction,
  updateYarnMeasurementSizeErrorAction,
  removeYarnAlternativeAction,
  removeYarnAlternativeSuccessAction,
  removeYarnAlternativeErrorAction,
  addAlternativeItemsAction,
  addAlternativeItemsSuccessAction,
  addAlternativeItemsErrorAction,
  removeAlternativeItemsAction,
  removeAlternativeItemsSuccessAction,
  removeAlternativeItemsErrorAction,
  setAlternativeItemsColorAction,
  setAlternativeItemsColorSuccessAction,
  setAlternativeItemsColorErrorAction,
  setQuantityWithSizeAction,
  setQuantityWithSizeSuccessAction,
  setQuantityWithSizeErrorAction,
  getFibersByYarnIdAction,
  getFibersByYarnSuccessAction,
  collectionErrorActions,
  addFiberAction,
  addFiberSuccessAction,
  getYarnByManufacturerAction,
  getYarnByManufacturerSuccessAction,
  addYarnAlternativeItemAction,
  addYarnAlternativeItemSuccessAction,
  searchYarnManufacturerAction,
  searchYarnManufacturerSuccessAction,
  removeFiberAction,
  removeFiberSuccessAction,
  updateFiberAction,
  updateFiberSuccessAction,
} = collectionSlice.actions;

export const collectionReducer = collectionSlice.reducer;
