import { createSlice } from '@reduxjs/toolkit';
import { logoutSuccessAction } from './authorization.reducer';
import { PaymentPlanState } from '@/common/interfaces/payment-plan.interface';

const initialState: PaymentPlanState = {
  isLoading: false,
  data: [],
};

export const paymentPlanSlice = createSlice({
  name: 'payment-plan',
  initialState,
  reducers: {
    setPaymentPlanData: (state, action) => {
      state.data = action.payload !== null ? action.payload.data : [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const { setPaymentPlanData } = paymentPlanSlice.actions;
export const paymentPlanReducer = paymentPlanSlice.reducer;
