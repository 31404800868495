import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { FileItem, ManyItems, OneItem } from '@directus/sdk';
import { FileFolders } from '@/common/constants/fileFolders';
import { Collections } from '@/common/interfaces/collection.interface';
import {
  createResourceDataAction,
  createResourceDataSuccessAction,
  createResourceDataErrorAction,
  getResourceDataAction,
  getResourceDataSuccessAction,
  getResourceDataErrorAction,
} from '../reducers/resource.reducer';
import { getCollectionDataSelector } from '../selectors/collection.selector';
import { ProductStatus } from '@/common/constants/product-status';

function* createResourceDataRequest(
  api: ApiType,
  action: ReturnType<typeof createResourceDataAction>,
) {
  const resource = action.payload;

  // @ts-ignore
  const collectionData = yield select(getCollectionDataSelector);

  let categorizationDataForRequest: any = {};
  [Collections.ResourceType, Collections.ResourceContentType, Collections.Technics].forEach(
    (collection) => {
      // @ts-ignore
      categorizationDataForRequest[collection] = resource[collection].map((itemId) => {
        // @ts-ignore
        return collectionData[collection].find(({ id }) => id === itemId);
      });
    },
  );

  try {
    if (!resource.files?.length) {
      throw new Error('No files provided');
    }
    //create resource
    const resourceResponse: OneItem<any> = yield call(api.createResource, {
      ...resource,
      ...categorizationDataForRequest,
      files: [],
      status: ProductStatus.Published,
    });

    if (!resourceResponse) {
      throw new Error('Resource creation failed');
    }
    //files upload
    let files;

    const fileResponse: OneItem<FileItem> = yield call(
      api.uploadFile,
      FileFolders.Resources,
      resource.files,
    );
    if (fileResponse) {
      files = Array.isArray(fileResponse) ? fileResponse : [fileResponse];
    } else {
      throw new Error('File upload failed');
    }
    //create files collection items
    const fileCollectionItemsResponse: ManyItems<any> = yield call(
      api.createCollectionItems,
      Collections.ResourcesImages,
      files.map((file) => ({
        file: file.id,
        resource: resourceResponse.id,
      })),
    );
    if (fileCollectionItemsResponse) {
      yield put(createResourceDataSuccessAction(fileCollectionItemsResponse));
    } else {
      throw new Error('Files have not been added to the collection');
    }
  } catch (error) {
    console.error(error);
    yield put(createResourceDataErrorAction(error));
  }
}

export const resourceSaga = function* (api: ApiType) {
  yield all([takeLatest(createResourceDataAction.type, createResourceDataRequest, api)]);
};
