import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAction } from '@/store/reducers/authorization.reducer';
const SocialAuthCallback = () => {

  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

    console.log('user:', user.user?.id);

    // Now that clerk is retrieved correctly, check for user and role
    if (user.isSignedIn) {
      const role = localStorage.getItem('selectedRole');

      if (role) {
        fetch(`${process.env.REACT_APP_KNITRY_API_V2}/user/update-role`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },         
          body: JSON.stringify({data: { id: user.user?.id, role}}),
        })
          .then(res => {
            console.log('res:', res);
            dispatch(loginAction({
              data:{
                  email: user.user.primaryEmailAddress?.emailAddress ?? '', // Fix: Use 'emailAddress' instead of 'email'
                  password: user.user.id, // Assuming you have a method to handle user IDs as passwords
              },      
              callback: (success) => {
                if (success) {
                  navigate('/');
                  //console.log("Logged in successfully");
                }
              }
            }));
          })
         
          .catch(error => console.error('Error fetching payment intent:', error));
      } else {
        // If no role is selected, just redirect or handle accordingly
        navigate('/');
      }
    }
  }, [user?.user?.id]); 

  return (
    <div>Loading...</div>
  );
};

export default SocialAuthCallback;
