import { PatternImageCollectionItem } from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { PhotoUploader } from '@/components/photo-uploader';
import {
  getPatternImagesAction,
  setPatternDataAction,
  uploadPatternImageAction,
} from '@/store/reducers/pattern.reducer';
import { getPatternImagesSelector } from '@/store/selectors/pattern.selector';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  pattern: Pattern;
}

export const Photo: FC<Props> = (props) => {
  const { pattern, ...rest } = props;

  const images = useSelector(getPatternImagesSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChange = (file: File) => {
    dispatch(
      uploadPatternImageAction({
        pattern,
        image: file,
      }),
    );
  };

  const setMainImage = (img: PatternImageCollectionItem) => {
    dispatch(
      setPatternDataAction({
        id: pattern.id,
        images: images.map((image) => image.id),
        main_image: img?.id,
      }),
    );
  };

  useEffect(() => {
    if (pattern.images?.some((image) => !images.map((el) => el.id).includes(image))) {
      dispatch(
        getPatternImagesAction({
          pattern,
        }),
      );
    }
  }, [pattern.images]);

  return (
    <PhotoUploader
      mainImageId={pattern.main_image}
      images={images}
      onMain={setMainImage}
      onUpload={onChange}
      buttonText={t('patternizerSteps.advertisement.uploadPhoto')}
      {...rest}
    />
  );
};
