import React, { FC } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export interface LazyFallbackProps {}

const FallbackEl = styled(LinearProgress)({
  height: '1rem',
});

export const LazyFallback: FC<LazyFallbackProps> = () => {
  return <FallbackEl />;
};
