import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  height: '15rem',
  width: '100%',
  border: '1px solid #000000',
});

export const Image = styled('img')({
  height: '12.5rem',
  width: '12.5rem',
  objectFit: 'cover',
  borderRight: '1px solid #000000',
});

export const Main = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '12.5rem',
});

export const Info = styled('div')({
  width: 'calc(100% - 12.5rem)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const NameDescription = styled('div')({
  width: '100%',
  height: '10rem',
  overflow: 'auto',
});

export const Left = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem',
  width: '75%',
});

export const Right = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem',
});

export const Price = styled(Typography)({
  fontWeight: 600,
});

export const SelectColor = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
});
