import { Typography } from '@mui/material';
import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export interface InputProps extends MuiInputProps {
  errorMessage?: string;
}

const InputStyled = styled(MuiInput)(({ theme }) => ({
  fontSize: '1.375rem',
  background: 'none',
  padding: '0.5rem',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1.125rem',
  },
}));


const InputContainer = styled('div')({
  position: 'relative',
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: 'red',
  position: 'absolute',
  right:'0',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1rem',
  },
}));


export const Input: FC<InputProps> = (props) => {
  const { errorMessage, ...rest } = props;
  return (
    <InputContainer className={rest.className}>
      <InputStyled {...props} error={!!errorMessage} />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};
