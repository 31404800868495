import {
  YarnColorCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { ColorPicker } from '@/components/color-picker';
import { setAlternativeItemsColorAction } from '@/store/reducers/collection.reducer';
import { QuantityWithSizeCollectionItemWithStage } from '@/store/types/collection';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Quantity } from '../quantity';
import { Block, Container, Description, Name, Picture, Row, SelectColor, Total } from './styles';

interface Props {
  type: 'yarn' | 'needle';
  name?: string;
  image?: string;
  prices?: YarnPriceCollectionItem[];
  description?: string;
  selectedSize: number;
  quantities: QuantityWithSizeCollectionItemWithStage[] | number;
  id: number;
  pattern: Pattern;
  colors?: YarnColorCollectionItem[] | null;
}

export const Item: FC<Props> = (props) => {
  const { type, name, image, prices, description, selectedSize, quantities, id, pattern, colors } =
    props;

  const dispatch = useDispatch();

  const onChangeColor = (color: number, alternative: number) => {
    dispatch(setAlternativeItemsColorAction({ color, alternative }));
    setColorAnchor(null);
  };

  const [colorAnchor, setColorAnchor] = useState<HTMLDivElement | null>(null);

  const openPicker = (event: MouseEvent<HTMLDivElement>) => {
    setColorAnchor(event.currentTarget);
  };

  const closePicker = () => {
    setColorAnchor(null);
  };

  return (
    <>
      <Container>
        <Picture src={image} />
        <Block>
          <Row>
            <div>
              <Name>{name}</Name>
              <Description>{description}</Description>
            </div>
            <Quantity
              id={id}
              selectedSize={selectedSize}
              quantities={quantities}
              pattern={pattern}
              type={type}
            />
          </Row>
          <Row>
            <SelectColor
              style={{ visibility: type === 'yarn' ? 'visible' : 'hidden' }}
              onClick={openPicker}
            >
              Select color
            </SelectColor>
            {!!prices?.length && (
              <Total>{`${prices[0]?.price},-${prices[0]?.currency?.name}`}</Total>
            )}
          </Row>
        </Block>
      </Container>
      <ColorPicker
        anchorEl={colorAnchor}
        onClose={closePicker}
        colors={colors}
        onSelect={(colorId) => onChangeColor(colorId, id)}
      />
    </>
  );
};
