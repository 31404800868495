import React, { FC, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { CloseBtn } from '@/components/close-btn';

export interface BrowseResourcesModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const BrowseResourcesModal: FC<BrowseResourcesModalProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '1100px',
          padding: '1rem',
        },
      }}
    >
      {children}
      <CloseBtn onClick={onClose} />
    </Dialog>
  );
};
