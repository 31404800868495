import { MenuItem, Select, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface Props extends Omit<SelectProps, 'onChange'> {
  onChange: (id: number | string) => void;
  options: DropdownOptionsItem[];
  style?: CSSProperties;
  placeholder?: string;
  className?: string;
}

export interface DropdownOptionsItem {
  label: string;
  value: number | string;
}

export const Dropdown: FC<Props> = (props) => {
  const { value, onChange, options, style, placeholder, ...rest } = props;

  const onChangeHandler = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string | number);
  };

  return (
    <Select
      size='small'
      displayEmpty={!!placeholder}
      value={value || 'placeholder'}
      onChange={onChangeHandler}
      inputProps={{
        'aria-label': 'Without label',
      }}
      style={{
        borderRadius: 0,
        margin: '0 0.625rem',
        minWidth: '15rem',
        ...style,
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000',
        },
        '& .MuiSvgIcon-root': {
          color: '#000',
        },
      }}
      {...rest}
    >
      {!!placeholder && (
        <MenuItem disabled value='placeholder'>
          <Typography>{placeholder}</Typography>
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
