import { DirectusFields } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { User } from '@/common/interfaces/user.interface';
import { normalizePattern, PatternWithCollection } from '@/utils/normalizePattern';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  getDesignersOfTheWeekAction,
  getDesignersOfTheWeekErrorAction,
  getDesignersOfTheWeekSuccessAction,
  getPagesContentAction,
  getPagesContentErrorAction,
  getPagesContentSuccessAction,
  getPromotedPatternsAction,
  getPromotedPatternsErrorAction,
  getPromotedPatternsSuccessAction,
  getRecentUploadsAction,
  getRecentUploadsErrorAction,
  getRecentUploadsSuccessAction,
} from '../reducers/pages-content.reducer';

import { displayErrorAction } from '../reducers/system.reducer';
import { PageContentCollectionItem } from './../../common/interfaces/pages-content.interface';

function* getPagesContentRequest(api: ApiType) {
  try {
    const response: TransportResponse<PageContentCollectionItem[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.WebPages,
      {
        fields: DirectusFields.WebPages,
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getPagesContentSuccessAction(response.data));
  } catch (error: any) {
    yield put(getPagesContentErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getRecentUploadsRequest(api: ApiType) {
  try {
    const response: TransportResponse<PatternWithCollection[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Patterns,
      {
        fields: DirectusFields.Pattern,
        limit: 5,
        sort: ['-date_created' as never],
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getRecentUploadsSuccessAction(response.data.map(normalizePattern)));
  } catch (error: any) {
    yield put(getRecentUploadsErrorAction());
  }
}

function* getPromotedPatternsRequest(api: ApiType) {
  try {
    const response: TransportResponse<PatternWithCollection[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Patterns,
      {
        fields: DirectusFields.Pattern,
        limit: 5,
        filter: {
          is_promoted: {
            _eq: true,
          },
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getPromotedPatternsSuccessAction(response.data.map(normalizePattern)));
  } catch (error: any) {
    yield put(getPromotedPatternsErrorAction());
  }
}

function* getDesignersOfTheWeekRequest(api: ApiType) {
  try {
    const response: TransportResponse<User[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Users,
      {
        limit: 5,
        filter: {
          designers_of_the_week: {
            _eq: true,
          },
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getDesignersOfTheWeekSuccessAction(response.data));
  } catch (error: any) {
    yield put(getDesignersOfTheWeekErrorAction());
  }
}



export const pagesContentSaga = function* (api: ApiType) {
  yield all([takeLatest(getPagesContentAction.type, getPagesContentRequest, api)]);
  yield all([takeLatest(getRecentUploadsAction.type, getRecentUploadsRequest, api)]);
  yield all([takeLatest(getPromotedPatternsAction.type, getPromotedPatternsRequest, api)]);
  yield all([takeLatest(getDesignersOfTheWeekAction.type, getDesignersOfTheWeekRequest, api)]);
};
