import {
  CountryCollectionItem,
  CurrencyCollectionItem,
  ShipmentMethodCollectionItem,
} from '@/common/interfaces/collection.interface';
import { YarnManufacturerWithStage } from '@/store/types/yarn-supplier';

export interface YarnManufacturerWithCollection {
  available_country: {
    countries_id: CountryCollectionItem;
  }[];
  shipment_methods: {
    id: number;
    currency: CurrencyCollectionItem;
    price: number;
    shipment_method_id: ShipmentMethodCollectionItem;
  }[];
}

export const normalizeYarnManufacturer = (
  yarnManufacturer: YarnManufacturerWithCollection,
): YarnManufacturerWithStage => {
  //@ts-ignore
  return {
    ...yarnManufacturer,
    available_country: yarnManufacturer.available_country.map((el) => el.countries_id),
    shipment_methods: yarnManufacturer.shipment_methods
      .filter((el) => !!el.shipment_method_id)
      .map((el) => ({
        id: el.id,
        currency: el.currency,
        price: el.price,
        method: el.shipment_method_id,
      })),
  };
};
 