import { Input } from '@/components/input/input';
import Delete from '@mui/icons-material/Delete';
import { styled, Typography } from '@mui/material';

export const Container = styled('div')({});

export const Title = styled(Typography)({
  margin: '2rem 0',
});

export const Inner = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '12.5rem',
});

export const InputStyled = styled(Input)({
  width: '12.5rem',
  marginBottom: '0.5rem',
  fontSize: '1.375rem',
});

export const Photo = styled('img')({
  height: '100%',
  width: '12.5rem',
});

export const SizeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid black',
  width: '6.3125rem',
  margin: '0 0.5rem',

  '& div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%',
    width: '100%',
  },
});

export const SizeInput = styled(Input)({
  '& .MuiInput-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root:before': {
    border: 'none !important',
  },

  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
});

export const PhotoContainer = styled('div')({
  position: 'relative',
});

export const EditColor = styled(Typography)({
  position: 'absolute',
  top: '0.5rem',
  left: '0.5rem',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const IconButtonWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3rem',
});

export const DeleteIcon = styled(Delete)({
  transform: 'scale(1.5)',
});

export const NoPhoto = styled('div')({
  height: '100%',
  width: '12.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F0F0F0',
});
