import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  marginTop: '2rem',
  [theme.breakpoints.down('tablet')]: {
    marginTop: '1rem',
  },
}));

export const ButtonsContainer = styled('div')({
  display: 'flex',
  overflow: 'scroll',
});

export const ButtonStyled = styled(Button)({
  marginRight: '1rem',
});

export const YarnContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
});

export const ItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const NoYarnText = styled(Typography)({
  marginTop: '3rem',
});
