import {
  PatternImageCollectionItem,
  YarnImageCollectionItem,
} from '@/common/interfaces/collection.interface';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ImgVideo } from '@/components/img-video/img-video';

interface Props {
  images: PatternImageCollectionItem[] | YarnImageCollectionItem[];
  main_image?: PatternImageCollectionItem | YarnImageCollectionItem;
}

const Container = styled('div')({
  height: '100%',
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PictureContainer = styled('div')({
  position: 'relative',
  height: '100%',
  width: '100%',
});

const Picture = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  height: '100%',
});

const Prev = styled(ChevronLeft)({
  position: 'absolute',
  left: '1rem',
  top: '50%',
  bottom: '50%',
  transform: 'scale(2.5)',
  cursor: 'pointer',
});

const Next = styled(ChevronRight)({
  position: 'absolute',
  right: '1rem',
  top: '50%',
  bottom: '50%',
  transform: 'scale(2.5)',
  cursor: 'pointer',
});

export const ProductPhotos: FC<Props> = (props) => {
  const { images, main_image, ...rest } = props;

  const [currentImage, setCurrentImage] = useState<
    PatternImageCollectionItem | YarnImageCollectionItem | null
  >();

  useEffect(() => {
    if (main_image?.id) {
      setCurrentImage(main_image);
    }
  }, [main_image]);

  const onNextImage = () => {
    const currentIndex = images.findIndex((image) => image.id === currentImage?.id);
    if (currentIndex === images.length - 1) {
      setCurrentImage(images[0]);
    } else {
      setCurrentImage(images[currentIndex + 1]);
    }
  };

  const onPrevImage = () => {
    const currentIndex = images.findIndex((image) => image.id === currentImage?.id);
    if (currentIndex === 0) {
      setCurrentImage(images[(images || []).length - 1]);
    } else {
      setCurrentImage(images[currentIndex - 1]);
    }
  };

  return (
    <Container {...rest}>
      <PictureContainer>
        <Picture src={currentImage ? getAssetBackendUrl(currentImage?.image) : undefined} />
        {images.length > 1 && <Prev onClick={onPrevImage} />}
        {images.length > 1 && <Next onClick={onNextImage} />}
      </PictureContainer>
    </Container>
  );
};
