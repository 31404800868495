import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
});

export const Inner = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '1.5rem',
});
