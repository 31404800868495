import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

export interface CloseBtnProps extends IconButtonProps {}

export const CloseBtn: FC<CloseBtnProps> = (props) => {
  return (
    <IconButton
      aria-label='close'
      sx={{
        position: 'absolute',
        right: '0.75rem',
        top: '0.75rem',
        color: (theme) => theme.palette.grey[500],
      }}
      {...props}
    >
      <CloseIcon />
    </IconButton>
  );
};
