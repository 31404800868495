import { ItemSearchType } from '@/common/interfaces/search.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DesignerSearchItem,
  PatternSearchItem,
  ResourceSearchItem,
  SearchPayload,
  SearchSuccessPayload,
  YarnSearchItem,
} from '../types/search';
import { logoutSuccessAction } from './authorization.reducer';

export interface SearchState {
  isLoading: boolean;
  error: null;
  patterns: PatternSearchItem[];
  yarns: YarnSearchItem[];
  resources: ResourceSearchItem[];
  showSearch: boolean;
  itemSearchType: ItemSearchType | null;
  designers: DesignerSearchItem[];
}

const initialState: SearchState = {
  isLoading: false,
  error: null,
  patterns: [],
  yarns: [],
  resources: [],
  showSearch: false,
  itemSearchType: null,
  designers: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchItemsAction(state, _: PayloadAction<SearchPayload>) {
      state.isLoading = true;
    },
    searchItemsSuccessAction(state, action: PayloadAction<SearchSuccessPayload>) {
      state.isLoading = false;
      state.patterns = action.payload.patterns;
      state.yarns = action.payload.yarns;
      state.resources = action.payload.resources;
      state.designers = action.payload.designers;
    },
    searchItemsErrorAction(state) {
      state.isLoading = false;
    },

    // Show search
    showSearchAction(state) {
      state.showSearch = true;
    },

    // Hide search
    hideSearchAction(state) {
      state.showSearch = false;
    },

    // Set item search type
    setItemSearchTypeAction(state, action: PayloadAction<ItemSearchType | null>) {
      state.itemSearchType = action.payload;
    },

    // Clear search
    clearSearchAction() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  searchItemsAction,
  searchItemsSuccessAction,
  searchItemsErrorAction,
  clearSearchAction,
  showSearchAction,
  hideSearchAction,
  setItemSearchTypeAction,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;
