import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateYarnManufacturerPayload,
  GetYarnManufacturerBySupplierPayload,
  UpdateDeliveryPricePayload,
  UpdateShipmentMethodPayload,
  UpdateYarnManufacturerPayload,
  YarnManufacturerWithStage,
} from '../types/yarn-supplier';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarnSupplierState {
  isLoading: boolean;
  yarnManufacturer: YarnManufacturerWithStage | null;
}

const initialState: YarnSupplierState = {
  isLoading: false,
  yarnManufacturer: null,
};

export const yarnSupplierSlice = createSlice({
  name: 'yarn-supplier',
  initialState,
  reducers: {
    // Create yarn manufacturer
    createYarnManufacturerAction: (state, _: PayloadAction<CreateYarnManufacturerPayload>) => {
      state.isLoading = true;
    },
    createYarnManufacturerSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Get yarn manufacturer by supplier
    getYarnManufacturerBySupplierAction: (
      state,
      _: PayloadAction<GetYarnManufacturerBySupplierPayload>,
    ) => {
      state.isLoading = true;
    },
    getYarnManufacturerBySupplierSuccessAction: (
      state,
      action: PayloadAction<YarnManufacturerWithStage | null>,
    ) => {
      state.isLoading = false;
      state.yarnManufacturer = action.payload;
    },

    // Create yarn manufacturer if not exist
    createYarnManufacturerIfNotExistAction: (
      state,
      _: PayloadAction<CreateYarnManufacturerPayload>,
    ) => {
      state.isLoading = true;
    },
    createYarnManufacturerIfNotExistSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Update yarn manufacturer
    updateYarnManufacturerAction: (state, _: PayloadAction<UpdateYarnManufacturerPayload>) => {
      state.isLoading = true;
    },
    updateYarnManufacturerSuccessAction: (
      state,
      action: PayloadAction<YarnManufacturerWithStage>,
    ) => {
      state.yarnManufacturer = action.payload;
      state.isLoading = false;
    },

    // Update shipment method
    updateShipmentMethodAction: (state, _: PayloadAction<UpdateShipmentMethodPayload>) => {
      state.isLoading = true;
    },
    updateShipmentMethodSuccessAction: (
      state,
      action: PayloadAction<YarnManufacturerWithStage>,
    ) => {
      state.isLoading = false;
      state.yarnManufacturer = action.payload;
    },

    // Update delivery price
    updateDeliveryPriceAction: (state, _: PayloadAction<UpdateDeliveryPricePayload>) => {
      state.isLoading = true;
    },
    updateDeliveryPriceSuccessAction: (state, action: PayloadAction<YarnManufacturerWithStage>) => {
      state.isLoading = false;
      state.yarnManufacturer = action.payload;
    },

     // Error action
    yarnSupplierErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  createYarnManufacturerAction,
  createYarnManufacturerSuccessAction,
  getYarnManufacturerBySupplierAction,
  getYarnManufacturerBySupplierSuccessAction,
  updateYarnManufacturerAction,
  updateYarnManufacturerSuccessAction,
  yarnSupplierErrorAction,
  updateShipmentMethodAction,
  updateShipmentMethodSuccessAction,
  updateDeliveryPriceAction,
  updateDeliveryPriceSuccessAction,
} = yarnSupplierSlice.actions;

export const yarnSupplierReducer = yarnSupplierSlice.reducer;
