import React, { FC, ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import { CloseBtn } from '@/components/close-btn';

export interface ResourceUploadModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const ResourceUploadModal: FC<ResourceUploadModalProps> = ({ open, onClose, children }) => {
  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '1100px', width: '100%' },
      }}
    >
      {children}
      <CloseBtn onClick={onClose} />
    </Drawer>
  );
};
