import { styled } from '@mui/material/styles';

export const Inner = styled('div')(({ theme }) => ({
  ...theme.typography.h4,
  padding: '2.5rem',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2.5rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '0.5rem',
    rowGap: '1rem',
  },
}));

export const EmptyBasket = styled('div')(({ theme }) => ({
  padding: '2.5rem 0',
  color: theme.palette.primary.inactive,
  textAlign: 'center',
}));

export const ProductContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    rowGap: '0.5rem',
  },
}));

export const ProductTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h3,
}));

export const Footer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    paddingTop: '0.5rem',
    borderTop: `1px solid ${theme.palette.primary.text}`,
  },
}));

export const TotalRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const Description = styled('div')({

});

export const DeliveryPrice = styled('div')(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: theme.palette.secondary.main,
  textAlign: 'right',
  padding: '0.5rem 0.5rem 0.5rem 1.5rem',
  marginRight: '1rem',
}));

export const DeliveryCostContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.25rem 2.5rem',
  borderBottom: `1px solid ${theme.palette.primary.text}`,
  borderTop: `1px solid ${theme.palette.primary.text}`,
  ...theme.typography.h4,
  [theme.breakpoints.down('tablet')]: {
    padding: '0.5rem',
    margin: '0.5rem 0.5rem 5rem 0.5rem',
    border: `1px solid ${theme.palette.primary.text}`,
  },
}));

export const FinalContainer = styled(DeliveryCostContainer)(({ theme }) => ({
  borderTop: 'none',
  alignItems: 'flex-end',
  padding: '2.5rem',
  [theme.breakpoints.down('tablet')]: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.primary.text}`,
    margin: 0,
    padding: '2rem 1rem',
  },
}));

export const TotalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '0.5rem',
});

export const TotalPrice = styled(DeliveryPrice)(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 600,
  padding: '0.5rem',
}));

export const EmptyLink = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  ...theme.typography.body1,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

