import { Button } from '@/components/button/button';
import { Link } from '@/components/link/link';
import { ResourceUpload } from '@/components/resource-upload';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';
import { ResourcesBrowse } from '@/components/resources-browse';
import { deleteStepDiagramAction, getPatternStepsAction, updateStepDiagramAction, uploadPatternStepDiagramAction } from '@/store/reducers/pattern.reducer';
import { getUserResourcesAction } from '@/store/reducers/user.reducer';
import { getCurrentUserSelector, getUserResourcesSelector } from '@/store/selectors/user.selector';
import { PatternStepWithStage, ResourceWithStage } from '@/store/types/pattern';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Diagrams } from '../diagrams/diagrams';
import { BrowseResourcesModal } from './components/browse-resources-modal/browse-resources-modal';
import { ResourceItem } from './components/resource-item/resource-item';
import { ResourceUploadModal } from './components/resource-upload-modal/resource-upload-modal';

export interface ResourcesSidebarProps {
  patternId: number;
  resources?: ResourceWithStage[];
  onResourceSelect: (selectedResources: number[]) => void;
  currentStep: PatternStepWithStage | null;
}

const SidebarContainer = styled('div')(({ theme }) => ({
  padding: '2rem 0 2rem 2rem',
  width: '22rem',
  minWidth: '22rem',
  borderRight: '1px solid #000',
  [theme.breakpoints.down('laptop')]: {
    width: '40%',
    minWidth: '40%',
  },
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    minWidth: 'auto',
    padding: 0,
    borderRight: 'none',
  },
}));

const ListTitle = styled('p')({
  fontWeight: '500',
  margin: '0 0 1.5rem',
  fontSize: '1.25rem',
});

const ResourceList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

export const ResourcesSidebar: FC<ResourcesSidebarProps> = ({
  patternId,
  resources = [],
  onResourceSelect,
  currentStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resourceUploadOpened, setResourceUploadOpened] = useState(false);
  const [browseResourcesOpened, setBrowseResourcesOpened] = useState(false);
  const [resourceViewId, setResourceViewId] = useState<number | null>(null);
  const userResources = useSelector(getUserResourcesSelector);
  const user = useSelector(getCurrentUserSelector);

  const getUserResources = () => {
    if (user) {
      dispatch(getUserResourcesAction(user?.id));
    }
  };

  useEffect(() => {
    getUserResources();
  }, []);

  const resourceModalCloseHandler = () => {
    setResourceUploadOpened(false);
  };
  const resourceModalSuccessHandler = () => {
    getUserResources();
    setResourceUploadOpened(false);
  };

  const resourcesList = useMemo(() => {
    return user ? userResources : [];
  }, [userResources]);

  const browseResourcesCloseHandler = () => {
    setBrowseResourcesOpened(false);
  };

  const stepDiagrams = currentStep?.step_diagrams || [];

  const getUpdatedPatternSteps = () => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
    }
  };

  const onDiagramFileChange = (file: File) => {
    if (!currentStep || !patternId) {
      return;
    }
    dispatch(
      uploadPatternStepDiagramAction({
        stepId: currentStep.id,
        file,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  return (
    <>
      <SidebarContainer>
        <Button onClick={() => setResourceUploadOpened(true)}>
          {t('resourcesSidebar.createNewResource')}
        </Button>
        <br />
        <br />
        <Link to={''} onClick={() => setBrowseResourcesOpened(true)}>
          {t('resourcesSidebar.browseAvailableResources')}
        </Link>
        <br />
        <br />
        <br />
        <ListTitle>{t('resourcesSidebar.appliedResources')}</ListTitle>
        <ResourceList>
          {resources.map((resource) => (
            <ResourceItem resource={resource} key={resource.id} onOpenPreview={setResourceViewId} />
          ))}
        </ResourceList>
        <Diagrams
          diagrams={stepDiagrams}
          onFileChange={onDiagramFileChange}
          onDiagramDelete={(diagramId) => {
            dispatch(
              deleteStepDiagramAction({
                diagramId,
                callback: getUpdatedPatternSteps,
              }),
            );
          }}
          onDiagramSave={(diagram) => {
            dispatch(
              updateStepDiagramAction({
                diagram,
                callback: getUpdatedPatternSteps,
              }),
            );
          }}
        />
      </SidebarContainer>
      <ResourceUploadModal open={resourceUploadOpened} onClose={resourceModalCloseHandler}>
        <ResourceUpload onSuccess={resourceModalSuccessHandler} />
      </ResourceUploadModal>
      <BrowseResourcesModal open={browseResourcesOpened} onClose={browseResourcesCloseHandler}>
        <ResourcesBrowse
          onResourceSelect={onResourceSelect}
          selectedResources={resources.map(({ id }) => id) as number[]}
          resourcesCollection={resourcesList}
        />
      </BrowseResourcesModal>
      <ResourceViewDrawer
        resourceType='resource'
        resourceId={resourceViewId}
        onClose={() => setResourceViewId(null)}
      />
    </>
  );
};
