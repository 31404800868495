import { Dropdown } from '@/components/dropdown';
import { YarnManufacturerShipmentMethodWithStage } from '@/store/types/yarn-supplier';
import { Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { Container, DeliveryOptions, Region } from './styles';

interface Props {
  shipmentMethods: YarnManufacturerShipmentMethodWithStage[];
  from?: number | null;
  to?: number | null;
}

export const Delivery: FC<Props> = (props) => {
  const { shipmentMethods, from, to } = props;

  const [selectedDelivery, setSelectedDelivery] = useState<number | null>(null);

  const shipmentMethodsMemo = useMemo(() => {
    return shipmentMethods.map((el) => ({ label: el.method.name, value: el.id }));
  }, [shipmentMethods]);

  const priceMemo = useMemo(() => {
    return shipmentMethods.find((el) => el.id === selectedDelivery)?.price || '';
  }, [selectedDelivery, shipmentMethods]);

  return (
    <Container>
      <Region>
        <Typography>Region availability: yes</Typography>
      </Region>
      <Dropdown
        style={{ margin: 0, borderRight: '1px solid #000' }}
        placeholder='Delivery method'
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
        value={selectedDelivery}
        options={shipmentMethodsMemo}
        onChange={(v) => setSelectedDelivery(v as number)} variant={'filled'}      ></Dropdown>
      <DeliveryOptions>
        <Typography>Delivery time: {from && to ? `${from}-${to}` : ''} days</Typography>
      </DeliveryOptions>
      <DeliveryOptions>
        <Typography>Delivery cost: {priceMemo},- NOK</Typography>
      </DeliveryOptions>
    </Container>
  );
};
