import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FC } from 'react';
import { Container, MenuItem, Title, MenuDescriptionContainer, MenuDescription } from './styles';
import { useMediaQuery } from '@mui/material';

interface Props {
  items: { id: string; label: string; description?: string }[];
  selectedId: string;
  onSelect: (item: { id: string; label: string }) => void;
}


export const LeftMenu: FC<Props> = (props) => {
  const { items, selectedId, onSelect } = props;
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  return (
    <Container container>
      {items.map((el) => {
      const isRotated = el.id === selectedId && isLaptop
        return(
        <MenuItem
          key={el.id}
          container
          bgcolor={el.id === selectedId ? 'secondary.main' : 'transparent'}
          onClick={() => onSelect(el)}
        >
          <Title variant='h4'>{el.label}</Title>
          <MenuDescriptionContainer>
            {!!el.description && <MenuDescription>el.description</MenuDescription>}
            <ArrowForwardIosIcon style={{ transform: isRotated ? 'rotate(90deg)' : 'none' }} />
          </MenuDescriptionContainer>
        </MenuItem>
      )})}
    </Container>
  );
};
