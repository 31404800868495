import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Collections } from '@/common/interfaces/collection.interface';
import { Gallery } from '@/components/gallery';
import { Button } from '@/components/button/button';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getResourceDataSelector,
  getResourceCategorizationDataSelector,
} from '@/store/selectors/resource.selector';
import { createResourceDataAction } from '@/store/reducers/resource.reducer';
import { getSelectedLabels } from '../../utils';
import { CellHead, PageContainerEl, DescrEl, TableCell, TableEl } from './styles';

export interface CompleteTabProps {}

const infoTableList = [
  Collections.Craft,
  Collections.ResourceContentType,
  Collections.ResourceType,
  Collections.Technics,
];

export const CompleteTab: FC<CompleteTabProps> = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const collectionData = useSelector(getCollectionDataSelector);
  const categorizationData = useSelector(getResourceCategorizationDataSelector);
  const resourceData = useSelector(getResourceDataSelector);
  const { description, files } = resourceData;

  const resourceCreationHandler = () => {
    dispatch(
      createResourceDataAction({
        ...resourceData,
        files: Object.values(files),
        [Collections.Craft]: categorizationData.Craft,
        [Collections.ResourceType]: categorizationData.ResourceType,
        [Collections.ResourceContentType]: categorizationData.ResourceContentType,
        [Collections.Technics]: categorizationData.Technics,
      }),
    );
  };

  return (
    <PageContainerEl>
      <Gallery images={Object.values(files)} />
      <DescrEl>{description}</DescrEl>
      <TableEl>
        <tbody>
          <tr>
            {infoTableList.map((collection, i) => (
              <TableCell key={collection}>
                <CellHead>{t(`resourceUpload.${collection}`)}</CellHead>
                <p>
                  {
                    // @ts-ignore
                    getSelectedLabels(collectionData[collection], categorizationData[collection])
                  }
                </p>
              </TableCell>
            ))}
          </tr>
        </tbody>
      </TableEl>
      <Button colored onClick={resourceCreationHandler}>
        {t('resourceUpload.publish')}
      </Button>
    </PageContainerEl>
  );
};
