import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPagesContentAction } from '@/store/reducers/pages-content.reducer';
import { getPagesContentLoadingSelector } from '@/store/selectors/pages-content.selector';

export function usePagesContent(): boolean {
  const dispatch = useDispatch();
  const isLoading = useSelector(getPagesContentLoadingSelector);

  useEffect(() => {
    dispatch(getPagesContentAction());
  }, []);

  return isLoading;
}
