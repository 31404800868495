import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { normalizeYarn, YarnWithCollection } from '@/utils/normalizeYarn';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { getPatternByIdAction } from '../reducers/pattern-view.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import { getYarnByIdAction, getYarnByIdSuccessAction } from '../reducers/yarn-view.reducer';
import { yarniversErrorAction } from '../reducers/yarnivers.reducer';

function* getYarnViewRequest(api: ApiType, action: ReturnType<typeof getPatternByIdAction>) {
  try {
    const id = action.payload;

    const response: YarnWithCollection = yield call(
      api.getCollectionItemById,
      Collections.Yarn,
      id,
      {
        fields: [
          '*, main_image.*',
          'colors.*',
          'images.*',
          'prices.*',
          'wash_and_care.wash_and_care_id.*',
          'gauge.masketett_id.*',
          'needle_size.needle_size_id.*',
          'fiber.fiber_id.*',
          'fiber.fiber_id.country.*',
          'fiber.fiber_id.type.*',
          'available_country.countries_id.*',
          'user_created.*',
          'amount_lenght_unit.*',
          'amount_weight_unit.*',
          'texture.texture_id.*',
        ],
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(getYarnByIdSuccessAction(normalizeYarn(response)));
  } catch (error: any) {
    yield put(yarniversErrorAction());
    yield put(displayErrorAction(error?.message as string));
  }
}

export const yarnViewSaga = function* (api: ApiType) {
  yield all([takeLatest(getYarnByIdAction.type, getYarnViewRequest, api)]);
};
