import { Button } from '@/components/button/button';
import { styled } from '@mui/material';

export const Header = styled('div')({
  height: '60rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #000000',
  overflow: 'hidden',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '2rem',
});

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const PromotedButton = styled(Button)({
  marginBottom: '2rem',
});
