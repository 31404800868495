import CloseIcon from '@mui/icons-material/Close';
import { styled, Table as MuiTable, TableCell, TextField as MuiTextField } from '@mui/material';

export const TableContainer = styled('div')({
  overflow: 'hidden',
});

export const TableStyled = styled(MuiTable)({
  width: '100%',
  margin: '2rem 10px',
});

export const TableSellNameStyled = styled(TableCell)({
  border: '1px solid #000',
  padding: '0.5rem',
  fontWeight: '500',
});

export const TableSellStyled = styled(TableCell)({
  //border: '1px solid #000',
  border: 'none',
  padding: '0.5rem',
  fontSize: '1.0rem'
});

export const TableSellStyledItem = styled(TableCell)({
    border: '1px solid #000',
    padding: '0.5rem',
  });

export const TextFieldStyled = styled(MuiTextField)({
  padding: '0',
  height: '100%',
  width: '100%',
  '& fieldset': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
});

export const TableSellDeleteStyled = styled(TableCell)({
  border: 'none',
  padding: '0.5rem',
});

export const TableDeleteIcon = styled(CloseIcon)({
  cursor: 'pointer',
});
