import { Button } from '@/components/button/button';
import { YarnItem } from '@/components/yarn-item';
import { styled } from '@mui/material/styles';

export const Header = styled('div')(({ theme }) => ({
  height: '60rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #000000',
  overflow: 'hidden',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
    flexWrap: 'wrap',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    padding: 0,
  },
}));

export const ItemContainer = styled('div')({
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

export const TotalTitle = styled('div')(({ theme }) => ({
  fontSize: '1.375rem',
  marginTop: '2rem',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1rem',
  },
}));

export const TotalQuantity = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  marginTop: '0.5rem',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '2rem',
  },
}));

export const NeedleTitle = styled('div')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  marginTop: '5rem',
  [theme.breakpoints.down('tablet')]: {
    marginTop: '2rem',
  },
}));

export const NeedleDescription = styled('div')({
  fontSize: '1.125rem',
  marginTop: '1rem',
  marginBottom: '4rem',
});

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const PublishButton = styled(Button)({
  height: '3.44rem',
  backgroundColor: '#CEFF1A',
});

export const YarnItemStyled = styled(YarnItem)({
  minWidth: '56rem',
  width: '50%',
  marginBottom: '1rem',
});
