import { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const ForgotPasswordPage = lazy(() => import('./forgot-password-page'));

export const ForgotPasswordLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <ForgotPasswordPage />
  </Suspense>
);
