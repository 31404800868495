import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const TextStyled = styled(Typography)({
  margin: '4rem 0',
});

export const ButtonUploadStyled = styled(Button)({
  width: 'fit-content',
});

export const ButtonNextStyled = styled(Button)({
  marginTop: '10rem',
  width: 'fit-content',
  backgroundColor: '#CEFF1A',
});
