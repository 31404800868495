import { Button } from '@/components/button/button';
import { DropdownGroup } from '@/components/dropdown-group';
import {
  getNeedlesByPatternAction,
  removeNeedleAction,
  setNeedleAction,
} from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { styled } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PatternizerStepsKeys } from '../../../patternizer-steps';

interface NeedleProps {}

interface NeedleStructure {
  id: number;
  needle_type?: number;
  needle_size?: number;
  cable_length?: number;
}

const ButtonStyled = styled(Button)(({ theme }) => ({
  // marginTop: '1rem',
  // fontSize: '1.2rem',
  padding: '0.9375rem 2.5rem',
  border: '1px solid #000',
  color: '#000',
  ...theme.typography.body2,
  width: 'fit-content',
}));

export const CreateNeedle: FC<NeedleProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const pattern = useSelector(getPatternDataSelector);

  const { NeedleType, NeedleSize, NeedleLength, Needle } = useSelector(getCollectionDataSelector);

  const TYPES = NeedleType.map((_) => ({ label: _.name, value: _.id }));
  const SIZES = NeedleSize.map((_) => ({ label: _.size, value: _.id }));
  const LENGTH = NeedleLength.map((_) => ({ label: _.value, value: _.id }));

  const addHandler = () => {
    if (!pattern.id) return;
    dispatch(
      setNeedleAction({
        pattern,
        needle: {
          pattern: pattern.id,
          status: 'published',
        },
      }),
    );
  };

  const deleteHandler = (id: number) => {
    dispatch(
      removeNeedleAction({
        pattern,
        id,
      }),
    );
  };

  const onChangeHandler = (id: number | string, i: number, type: keyof NeedleStructure) => {
    const result = {
      ...Needle[i],
      [type]: id,
    };
    dispatch(setNeedleAction({ pattern, needle: result }));
  };

  const { currentStep } = useSelector(getPatternizerSelector);

  useEffect(() => {
    if (currentStep === PatternizerStepsKeys.CATEGORIZATION && pattern.needle?.length) {
      dispatch(getNeedlesByPatternAction(pattern.id as number));
    }
  }, [currentStep, pattern]);

  return (
    <>
      {Needle.map((item, i) => (
        <DropdownGroup
          key={item.id}
          id={item.id}
          label={'Needle ' + (i + 1)}
          data={[
            {
              type: 'needle_type',
              value: item?.needle_type?.id || 0,
              options: TYPES,
            },
            {
              type: 'needle_size',
              value: item?.needle_size?.id || 0,
              options: SIZES,
            },
            {
              type: 'cable_length',
              value: item?.cable_length?.id || 0,
              options: LENGTH,
            },
          ]}
          onChange={(type, value) => onChangeHandler(value, i, type as keyof NeedleStructure)}
          onDelete={(id) => deleteHandler(id)}
        />
      ))}
      <ButtonStyled onClick={addHandler}>
        {`+ ${t('patternizerSteps.addNeedle')}`}
      </ButtonStyled>
    </>
  )
};
