import { Button } from '@/components/button/button';
import { setYarnWashAndCareAction } from '@/store/reducers/yarnivers.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getYarniversDataSelector } from '@/store/selectors/yarnivers.selector';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Item } from './components/item';
import { Container, Label, Row } from './styles';

interface Props {
  onPressNext: () => void;
}

export const WashAndCare: FC<Props> = (props) => {
  const { onPressNext } = props;

  const dispatch = useDispatch();

  const { WashAndCare, TypeWashAndCare } = useSelector(getCollectionDataSelector);

  const yarn = useSelector(getYarniversDataSelector);

  const getWashAndCareByType = (type: number) => {
    return WashAndCare.filter((item) => item.type_of_wash_and_care === type);
  };

  const handleSelect = (id: number) => {
    if (yarn.wash_and_care?.includes(id)) {
      dispatch(
        setYarnWashAndCareAction({
          yarnId: yarn.id as number,
          washAndCare: yarn.wash_and_care?.filter((item) => item !== id),
        }),
      );
    } else {
      dispatch(
        setYarnWashAndCareAction({
          yarnId: yarn.id as number,
          washAndCare: [...(yarn.wash_and_care || []), id],
        }),
      );
    }
  };

  return (
    <Container>
      {TypeWashAndCare.map((item) => (
        <div key={item.id + '_type_wash'}>
          <Label variant='h5' fontWeight='bold'>
            {item.name}
          </Label>
          <Row>
            {getWashAndCareByType(item.id)
              .sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0))
              .map((item) => (
                <Item
                  key={item.id + '_item'}
                  isSelected={yarn.wash_and_care?.includes(item.id as number) || false}
                  handleSelect={handleSelect}
                  item={item}
                />
              ))}
          </Row>
        </div>
      ))}
      <Grid m='2rem' width='100%'>
        <Button colored onClick={onPressNext}>
          Next
        </Button>
      </Grid>
    </Container>
  );
};
