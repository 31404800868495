import { ProductPhotos } from '@/components/pattern-photos';
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '60rem',
  [theme.breakpoints.down('tablet')]: {
    display: 'block',
    height: 'auto',
  },
}));

export const Photo = styled(ProductPhotos)(({ theme }) => ({
  width: '50%',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    height: '60vw',
  },
}));
