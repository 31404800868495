import { Collections } from '@/common/interfaces/collection.interface';
import {
  AccordionWithBlock,
  AccordionWithBlockData,
  AccordionWithBlockItemData,
} from '@/components/accordion-with-block';
import { getCollectionsAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  onChangeAdditional: (value: number, type: Collections | string) => void;
}

const Container = styled('div')({});

export const PatternFilter: FC<Props> = (props) => {
  const { onChangeAdditional } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Craft, Projects, Gauge, Suitable, Technics, Needle } =
    useSelector(getCollectionDataSelector);

  const [accordionData, setAccordionData] = useState<AccordionWithBlockData>({
    expanded: null,
    data: [],
  });

  useEffect(() => {
    dispatch(
      getCollectionsAction([
        Collections.Craft,
        Collections.Projects,
        Collections.Gauge,
        Collections.Suitable,
        Collections.Technics,
        Collections.Needle,
      ]),
    );
  }, []);

  useEffect(() => {

    console.log('Craft', Craft);

    if (
      Craft.length &&
      Projects.length &&
      Gauge.length &&
      Suitable.length &&
      Technics.length &&
      Needle.length
    ) {
      setAccordionData({
        expanded: null,
        data: [
          {
            label: t('search.patternFilter.craft'),
            type: Collections.Craft,
            items: Craft.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.patternFilter.projects'),
            type: Collections.Projects,
            items: Projects.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.patternFilter.suitable'),
            type: Collections.Suitable,
            items: Suitable.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.patternFilter.designElements'),
            type: Collections.Technics,
            items: Technics.map((item) => ({ id: item.id, label: item.name })),
            selected: [],
          },
          {
            label: t('search.patternFilter.requiredNeedles'),
            type: Collections.Needle,
            items: Needle.map((item) => ({ id: item.id, label: item.name || '' })),
            selected: [],
          },
          {
            label: t('search.patternFilter.gauge'),
            type: Collections.Gauge,
            items: Gauge.map((item) => ({ id: item.id, label: item.thickness })),
            selected: [],
          },
        ],
      });
    }
  }, [Craft, Projects, Gauge, Suitable, Technics, Needle]);

  const handleExpanded = (value: Collections | string) => {
    setAccordionData((p) => ({
      ...p,
      expanded: p.expanded === value ? null : value,
    }));
  };

  const handleSelect = (value: AccordionWithBlockItemData, type: Collections | string) => {
    if (type === Collections.Craft) {
      setAccordionData((p) => ({
        ...p,
        data: p.data.map((item) => {
          if (item.type === Collections.Craft) {
            return {
              ...item,
              selected: item.selected.includes(value) ? [] : [value],
            };
          } else {
            return item;
          }
        }),
      }));
    } else {
      setAccordionData((p) => ({
        ...p,
        data: p.data.map((item) => {
          if (item.type === type) {
            return {
              ...item,
              selected: item.selected.includes(value)
                ? item.selected.filter((item) => item !== value)
                : [...item.selected, value],
            };
          } else {
            return item;
          }
        }),
      }));
    }
    onChangeAdditional(value.id, type);
  };

  return (
    <Container>
      <AccordionWithBlock
        data={accordionData}
        onExpanded={handleExpanded}
        onSelect={handleSelect}
      />
    </Container>
  );
};
