import { CheckboxInput } from '@/components/radio-checkbox-input/radio-checkbox-input';
import { ResourceWithStage } from '@/store/types/pattern';
import { noop } from '@/utils/noop';
import { styled } from '@mui/material/styles';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ResourcesBrowseProps {
  onResourceSelect?: (selectedResources: number[]) => void;
  selectedResources?: number[];
  resourcesCollection?: ResourceWithStage[];
}

const Container = styled('div')({
  maxHeight: '100%',
  overflow: 'scroll',
});

const Title = styled('p')({
  position: 'sticky',
  top: 0,
  fontSize: '1.25rem',
  fontWeight: 500,
  padding: '0.5rem 0',
  margin: '0 0 2rem',
  backgroundColor: '#fff',
});

export const ResourcesBrowse: FC<ResourcesBrowseProps> = ({
  onResourceSelect = noop,
  selectedResources = [],
  resourcesCollection = [],
}) => {
  const { t } = useTranslation();

  const onChangeHandler = ({ target: { value, checked } }: ChangeEvent<HTMLInputElement>) => {
    const val = +value;
    const valueToSet: number[] = checked
      ? [...selectedResources, val]
      : selectedResources.filter((id) => id !== val);

    onResourceSelect(valueToSet);
  };
  return (
    <Container>
      <Title>{t('resourcesBrowse.title')}</Title>
      <div>
        {resourcesCollection.map(({ name, id }) => {
          const checked = selectedResources.includes(id as number);
          return (
            <CheckboxInput
              label={name}
              key={id}
              checked={checked}
              inputProps={{
                value: id,
                onChange: onChangeHandler,
                name,
                checked,
              }}
            />
          );
        })}
      </div>
    </Container>
  );
};
