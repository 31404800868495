import { FC } from 'react';
import { Container, Control, Number } from './styles';

interface Props {
  value: number;
  onChange?: (value: number) => void;
}

export const QuantityController: FC<Props> = (props) => {
  const { value, onChange } = props;
  return (
    <Container>
      {!!onChange && (
        <Control style={{ borderRight: '1px solid #000' }} onClick={() => onChange(value - 1)}>
          -
        </Control>
      )}
      <Number>{value}</Number>
      {!!onChange && (
        <Control style={{ borderLeft: '1px solid #000' }} onClick={() => onChange(value + 1)}>
          +
        </Control>
      )}
    </Container>
  );
};
