import { createApi } from "@/services/api";
import { getPatternDataSelector } from "@/store/selectors/pattern.selector";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setKeywordsAction } from '@/store/reducers/pattern.reducer';
import { debounce } from '@/utils/debounce';

interface Props {
  keywords?: string[];
}

export const Keywords = ({ keywords: keywords_initial }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useSelector(getPatternDataSelector);
  const [keywordsTextfield, setKeywordsTextfield] = useState("");
  const isSet = useRef(false);

  useEffect(() => {
    if (isSet.current) return;
    if (keywords_initial) {
      setKeywordsTextfield(keywords_initial.join(", "));
      isSet.current = true;
    }
  }, [keywords_initial, isSet])

  const keywords = useMemo(() => keywordsTextfield
    .split(",")
    .map(x => x.trim())
    .filter(x => x.length > 0), [keywordsTextfield]);

  const save = useMemo(() => debounce({ delay: 500 }, (id, keywords) => {
    if (id) {
      createApi().updatePatternKeywords(id, keywords);
      dispatch(setKeywordsAction(keywords));
    }
  }), []);

  useEffect(() => {
    save(id, keywords);
  }, [save, id, keywords]);

  return <>
    <textarea
      placeholder="Cardigan, Ragland"
      style={{ width: '100%' }}
      rows={25}
      value={keywordsTextfield}
      onChange={e => setKeywordsTextfield(e.target.value)}
    />
    <p>{t('patternizerSteps.keywordsHelptext')}</p>
    <Flex>{keywords.map((keyword, i) => <Pill key={keyword + i}><Text>{keyword}</Text></Pill>)}</Flex>
  </>;
}

const Flex = styled('div')({
  display: 'flex',
  flexFlow: 'wrap',
});

const Pill = styled('div')({
  paddingRight: '25px',
  paddingLeft: '25px',
  minWidth: '50px',
  // maxWidth: '400px',
  height: '50px',
  borderRadius: '50px',
  border: '1px solid black',
  marginRight: '20px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const Text = styled('span')({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
});
