import {
  ResourceContentTypeCollectionItem,
  ResourceTypeCollectionItem,
  TechnicsCollectionItem,
} from '@/common/interfaces/collection.interface';
import { ResourceWithStage } from '@/store/types/pattern';

export interface ResourceWithCollection {
  ResourceContentType: {
    resource_content_type_id: ResourceContentTypeCollectionItem;
  }[];
  ResourceType: {
    resource_type_id: ResourceTypeCollectionItem;
  }[];
  Technics: {
    technique_id: TechnicsCollectionItem;
  }[];
}

export const normalizeResource = (resource: ResourceWithCollection): ResourceWithStage => {
  const result: ResourceWithStage = {
    ...resource,
    ResourceContentType: resource?.ResourceContentType?.map(
      (item) => item?.resource_content_type_id,
    ),
    ResourceType: resource?.ResourceType?.map((item) => item?.resource_type_id),
    Technics: resource?.Technics?.map((item) => item?.technique_id),
  };
  return result;
};
