import { DirectusFields } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import {
  normalizeYarnManufacturer,
  YarnManufacturerWithCollection,
} from '@/utils/normalizeYarnManufacturer';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  createYarnManufacturerAction,
  getYarnManufacturerBySupplierAction,
  getYarnManufacturerBySupplierSuccessAction,
  updateDeliveryPriceAction,
  updateShipmentMethodAction,
  updateShipmentMethodSuccessAction,
  updateYarnManufacturerAction,
  updateYarnManufacturerSuccessAction,
  yarnSupplierErrorAction,
} from '../reducers/yarn-supplier.reducer';

function* createYarnManufacturerRequest(
  api: ApiType,
  action: ReturnType<typeof createYarnManufacturerAction>,
) {
  try {
    const { name } = action.payload;

    const response: unknown = yield call(api.createCollectionItem, Collections.YarnManufacturer, {
      name,
    });
  } catch (err: any) {
    yield put(yarnSupplierErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

function* getYarnManufacturerBySupplierRequest(
  api: ApiType,
  action: ReturnType<typeof getYarnManufacturerBySupplierAction>,
) {
  try {
    const { userId } = action.payload;

    const response: TransportResponse<YarnManufacturerWithCollection[]> = yield call(
      api.getCollection,
      Collections.YarnManufacturer,
      {
        filter: {
          user_created: {
            _eq: userId,
          },
        },
        fields: DirectusFields.YarnManufacturer,
      },
    );

    if (!response.data) {
      throw new Error(Errors.Default);
    }

    yield put(
      getYarnManufacturerBySupplierSuccessAction(
        response.data.length > 0 ? normalizeYarnManufacturer(response.data[0]) : null,
      ),
    );
  } catch (err: any) {
    yield put(yarnSupplierErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

function* updateYarnManufacturerRequest(
  api: ApiType,
  action: ReturnType<typeof updateYarnManufacturerAction>,
) {
  try {
    const data = action.payload;

    const response: YarnManufacturerWithCollection = yield call(
      api.updateCollectionItem,
      Collections.YarnManufacturer,
      data,
      {
        fields: DirectusFields.YarnManufacturer,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(updateYarnManufacturerSuccessAction(normalizeYarnManufacturer(response)));
  } catch (err: any) {
    yield put(yarnSupplierErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

function* updateShipmentMethodRequest(
  api: ApiType,
  action: ReturnType<typeof updateShipmentMethodAction>,
) {
  try {
    const { yarnManufacturer, shipmentMethodId } = action.payload;

    const payload = yarnManufacturer.shipment_methods.find(
      (el) => el.method.id === shipmentMethodId,
    )
      ? yarnManufacturer.shipment_methods.filter((el) => el.method.id !== shipmentMethodId)
      : [
          ...yarnManufacturer.shipment_methods.map((el) => ({
            id: el.id,
            shipment_method_id: el.method.id,
          })),
          { shipment_method_id: shipmentMethodId },
        ];

    const response: YarnManufacturerWithCollection = yield call(
      api.updateCollectionItem,
      Collections.YarnManufacturer,
      {
        id: yarnManufacturer.id,
        shipment_methods: payload,
      },
      {
        fields: DirectusFields.YarnManufacturer,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(updateShipmentMethodSuccessAction(normalizeYarnManufacturer(response)));
  } catch (err: any) {
    yield put(yarnSupplierErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

function* updateDeliveryPriceRequest(
  api: ApiType,
  action: ReturnType<typeof updateDeliveryPriceAction>,
) {
  try {
    const { yarnManufacturer, shipmentMethodId, price } = action.payload;

    const shipmentPayload = yarnManufacturer.shipment_methods.map((el) =>
      el.method.id === shipmentMethodId ? { ...el, price, currency: 1 } : el,
    );

    const response: YarnManufacturerWithCollection = yield call(
      api.updateCollectionItem,
      Collections.YarnManufacturer,
      {
        id: yarnManufacturer.id,
        shipment_methods: shipmentPayload,
      },
      {
        fields: DirectusFields.YarnManufacturer,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(updateYarnManufacturerSuccessAction(normalizeYarnManufacturer(response)));
  } catch (err: any) {
    yield put(yarnSupplierErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

export const yarnSupplierSaga = function* (api: ApiType) {
  yield all([takeLatest(createYarnManufacturerAction.type, createYarnManufacturerRequest, api)]);
  yield all([takeLatest(getYarnManufacturerBySupplierAction.type, getYarnManufacturerBySupplierRequest, api)]);
  yield all([takeLatest(updateYarnManufacturerAction.type, updateYarnManufacturerRequest, api)]);
  yield all([takeLatest(updateShipmentMethodAction.type, updateShipmentMethodRequest, api)]);
  yield all([takeLatest(updateDeliveryPriceAction.type, updateDeliveryPriceRequest, api)]);

};
