import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { TransportResponse } from '@directus/sdk';
import { DirectusFields } from '@/common/constants/directus-fields';
import { getManufacturWeekErrorAction, getYarnManufactureWeekAction, getYarnManufactureWeekSuccessAction } from '../reducers/yarn-manufacturer-week.reducer';
import {  YarnWithStage } from '../types/yarnivers';

function* getYarnManufactureWeekRequest(api: ApiType) {
  try {
    const response: TransportResponse<YarnWithStage[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.YarnManufacturer,
      {
        fields: DirectusFields.YarnManufacturer,
        limit: 3,
        filter: {
          supplierOfTheWeek: {
            _eq: true,
          },
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }   
    yield put(getYarnManufactureWeekSuccessAction(response.data));
  } catch (error: any) {
    yield put(getManufacturWeekErrorAction());
  }
}

export const yarnManufactureWeekSaga = function* (api: ApiType) {
  yield all([takeLatest(getYarnManufactureWeekAction.type, getYarnManufactureWeekRequest, api)]);
};
