import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

export interface PageContainerProps {
  children?: ReactNode;
  className?: string;
}

const PageContainerEl = styled('section')(({ theme }) => ({
  maxWidth: '1600px',
  margin: '0 auto',
  padding: '2rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem',
  },
}));

export const PageContainer: FC<PageContainerProps> = ({ children, className, ...rest }) => {
  return (
    <PageContainerEl className={className} {...rest}>
      {children}
    </PageContainerEl>
  );
};
