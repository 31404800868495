import {
  createPatternStepsAction,
  getPatternStepsAction,
  updatePatternStepAction,
  deletePatternStepAction,
  updatePatternStepsOrderAction,
} from '@/store/reducers/pattern.reducer';
import { getPatternAvailableSizesSelector, getPatternStepsSelector } from '@/store/selectors/pattern.selector';
import { FC, memo, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddStep } from './components/add-step/add-step';
import { TabItem } from './components/tab-item/tab-item';
import { TabsEl, StepsContainer } from './styles';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { PatternStepWithStage } from '@/store/types/pattern';
import { DraggableTab } from './components/draggable-tab/draggable-tab';
import { RelevantSizes } from './components/relevant-sizes/relevant-sizes';

export interface PatternStepsProps {
  patternId: number;
  onStepChange: (stepId: number) => void;
}

export const PatternSteps: FC<PatternStepsProps> = memo(({ onStepChange, patternId }) => {
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState<number | null>(null);
  const [dndSteps, setDndSteps] = useState<PatternStepWithStage[]>([]);
  const steps = useSelector(getPatternStepsSelector);
  const sizes = useSelector(getPatternAvailableSizesSelector);

  useEffect(() => {
    if (!steps.length) {
      return;
    }
    setDndSteps(steps);
  }, [steps]);

  //set initial step id
  useEffect(() => {
    if (!steps.length) {
      return;
    }
    const stepExists = !!steps.find(({ id }) => currentStepId === id);
    setCurrentStepId(stepExists ? currentStepId : steps[0].id);
  }, [steps, currentStepId]);

  useEffect(() => {
    if (currentStepId) {
      onStepChange(currentStepId);
    }
  }, [currentStepId]);

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    setCurrentStepId(+value);
  };

  const getUpdatedPatternSteps = () => {
    dispatch(getPatternStepsAction(patternId));
  };

  const addStepHandler = (stepTitle: string) => {
    dispatch(
      createPatternStepsAction({
        pattern: { id: patternId },
        name: stepTitle,
        sort: steps.length,
        relevant_sizes: sizes,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepNameChangeHandler = (stepId: number, name: string) => {
    dispatch(
      updatePatternStepAction({
        stepId,
        data: {
          name,
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepRemoveHandler = (stepId: number) => {
    dispatch(
      deletePatternStepAction({
        stepId,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepOrderChangeHandler = (steps: PatternStepWithStage[]) => {
    const stepsPayload = steps.map(({ id }, sort) => ({ id, sort }));
    dispatch(
      updatePatternStepsOrderAction({ steps: stepsPayload, callback: getUpdatedPatternSteps }),
    );
  };

  const onStepDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const updatedDndSteps = [...dndSteps];
    const draggedTab = updatedDndSteps.splice(source.index, 1)[0];
    updatedDndSteps.splice(destination.index, 0, draggedTab);
    setDndSteps(updatedDndSteps);
    onStepOrderChangeHandler(updatedDndSteps);
  };

  return (
    <StepsContainer>
      <DragDropContext onDragEnd={onStepDragEnd}>
        <div style={{ display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
          <Droppable droppableId='patternSteps' direction='vertical'>
            {({ droppableProps, innerRef, placeholder }) => (
              <div ref={innerRef} {...droppableProps}>
                <TabsEl
                  onChange={handleStepChange}
                  variant='scrollable'
                  scrollButtons={false}
                  value={currentStepId}
                >
                  {dndSteps.map(({ id, name }, index) => {
                    return (
                      <DraggableTab
                        value={id}
                        index={index}
                        key={index}
                        label={
                          <TabItem
                            title={name}
                            onRemove={() => onStepRemoveHandler(id)}
                            onChange={(value) => onStepNameChangeHandler(id, value)}
                          />
                        }
                      />
                    );
                  })}
                  {placeholder}
                </TabsEl>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <AddStep onAdd={addStepHandler} />
      {currentStepId !== null && <RelevantSizes currentStepId={currentStepId} />}
    </StepsContainer>
  );
});
