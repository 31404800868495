import { Button } from '@/components/button/button';
import { YarnAlternativeWithStage } from '@/store/types/collection';
import { styled } from '@mui/material';
import { FC } from 'react';

interface Props {
  yarnAlternative: YarnAlternativeWithStage[];
  selectedYarnAlternative: number;
  setSelectedYarnAlternative: (id: number) => void;
}

const Container = styled('div')(({ theme }) => ({
  marginTop: '4rem',
  [theme.breakpoints.down('laptop')]: {
    marginTop: '2rem',
    display: 'flex',
    overflow: 'scroll',
  },
}));

const AlternativeButton = styled(Button)({
  minWidth: '11rem',
  fontSize: '1.125rem',
  marginRight: '1rem',
  whiteSpace: 'nowrap',
});

export const Alternative: FC<Props> = (props) => {
  const { yarnAlternative, selectedYarnAlternative, setSelectedYarnAlternative } = props;
  return (
    <Container>
      {yarnAlternative.map((el, i) => (
        <AlternativeButton
          key={el.id}
          style={{ backgroundColor: selectedYarnAlternative === el.id ? '#F6F4ED' : 'transparent' }}
          onClick={() => setSelectedYarnAlternative(el.id)}
        >
          {el.name || `Alternative ${i + 1}`}
        </AlternativeButton>
      ))}
    </Container>
  );
};
