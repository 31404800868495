import { RootState } from '..';

export const getPagesContentLoadingSelector = (state: RootState) => state.pagesContent.isLoading;

export const getRecentUploadsLoadingSelector = (state: RootState) =>
  state.pagesContent.isRecentUploadsLoading;

export const getPromotedPatternsLoadingSelector = (state: RootState) =>
  state.pagesContent.isPromotedPatternsLoading;

export const getDesignersOfTheWeekLoadingSelector = (state: RootState) =>
  state.pagesContent.isDesignersOfTheWeekLoading;

export const getHomePageContentSelector = (state: RootState) => state.pagesContent.content[0];

export const getRecentUploadsSelector = (state: RootState) => state.pagesContent.recentUploads;

export const getPromotedPatternsSelector = (state: RootState) =>
  state.pagesContent.promotedPatterns;

export const getDesignersOfTheWeekSelector = (state: RootState) =>
  state.pagesContent.designersOfTheWeek;
