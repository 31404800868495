import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled('div')({
  position: 'relative',
});

export const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
});

export const Loader = styled(CircularProgress)({
  color: '#F6F4ED',
});

export const TopContainer = styled('div')({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TabsSection = styled('section')({
  borderTop: '1px solid #000',
  borderBottom: '1px solid #000',
  padding: '1.25rem 0',
});

export const TabsContainer = styled('div')({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
});

export const ResourceName = styled('p')({
  padding: '1rem 0',
  fontSize: '2rem',
  lineHeight: '2rem',
  height: '4rem',
  margin: 0,
});

export const TabsEl = styled(Tabs)({});

export const TabEl = styled(Tab)({
  border: '1px solid #000',
  padding: '1rem',
  marginRight: '1rem',
  '&.Mui-selected': {
    backgroundColor: '#F6F4ED',
    fontWeight: 800,
    color: '#000',
  },
});
