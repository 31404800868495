import {
  Collections,
  GaugeCollectionItem,
  ProjectCollectionItem,
  SuitableCollectionItem,
  UnionCollectionItem,
} from '@/common/interfaces/collection.interface';
import { styled } from '@mui/material/styles';
// import { PageContainer } from '@/components/page-container/page-container';
import { setAvailableSizeAction, setPatternDataAction, unsetAvailableSizeAction, updatePatternDataAction } from '@/store/reducers/pattern.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternAvailableSizesSelector, getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton } from '../next-button';
import { CreateNeedle } from './components/needle/needle';
import { Tab, TabContent } from '@/components/resource-upload/components/categorization-tab/components/tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { InputButtonSelect } from '@/components/input-button-select';
import { Input } from '@/components/input/input';
import useResource from '@/hooks/api/useResource';
import { Keywords } from './components/keywords';
import { Title } from '../pattern-name';
import { PageContainerStyled } from '../pattern-name';

interface Props {
  onPressNextButton: () => void;
}

const TabsContainer = styled('div')({
  position: 'relative',
  borderBottom: '1px solid #000',
  // margin: '0 -2rem 2rem',
});

const SecondaryTabsContainer = styled('div')({
  position: 'relative',
});

const TabPanelContainer = styled('div')({
  overflow: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: '40%',
  // borderTop: '1px solid #000',
});

const SecondaryTabPanelContainer = styled('div')({
  overflow: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: '35%',
});

const TabPanel = styled('div')({
  padding: '1.5rem',
  // margin: '.5rem 0 0 .5rem',
});

const SecondaryTabPanel = styled('div')({
  padding: 0,
});

// TabPanel (^) can't have these css rules,
// otherwise the hidden attribute doesn't work
const Flex = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  rowGap: '1.5rem',
  height: '100%',
})

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  margin: '0 -0.625rem',
  height: '75%',
  border: 'none',
});

// const TextBlock = styled('div')({
//   marginTop: '1rem',
//   fontSize: '1.125rem',
// });

const DescriptionText = styled('p')({
  marginTop: '1rem',
  maxWidth: '800px',
  fontSize: '1.125rem',
  b: {
    display: 'block',
    fontSize: '1.25rem',
    marginBottom: '1rem',
  },
});

const TemplateInput = styled(Input)({
  width: '100%',
  maxWidth: '400px',
});

const CategoryTab = (props: {value: string, text: string, descr: string}) =>
  <Tab
    key={props.value}
    value={props.value}
    label={
      <TabContent
        value={props.text}
        descr={props.descr}
      />
    }
    sx={{ borderTop: 1, borderColor: '#000', paddingLeft: '2.5rem' }}
    />

const SubCategoryTab = styled(Tab)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  textTransform: 'none',
  height: '3rem',
  marginTop: '1.5rem',
  alignItems: 'flex-start',
  borderBottom: '1px solid #000',
  ':first-child': {
    marginTop: 0,
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
}));

const PatternizerButtonSelect = styled(InputButtonSelect)({
  height: '3rem',
  margin: 0,
});

export const Categorization: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const collections = useSelector(getCollectionDataSelector);

  const pattern = useSelector(getPatternDataSelector);
  const patternAvailableSize = useSelector(getPatternAvailableSizesSelector);

  const onSelectHandler = (value: UnionCollectionItem, type: keyof typeof pattern) => {
    if (Array.isArray(pattern[type])) {
      dispatch(
        setPatternDataAction({
          ...pattern,
          [type]: [...((pattern[type] as []) || []), value.id],
        }),
      );
    } else if (pattern[type] !== value.id) {
      dispatch(
        setPatternDataAction({
          ...pattern,
          [type]: value.id,
        }),
      );
    }
  };

  const onDeleteHandler = (value: UnionCollectionItem, type: keyof typeof pattern) => {
    if (Array.isArray(pattern[type])) {
      dispatch(
        setPatternDataAction({
          ...pattern,
          [type]: (pattern[type] as []).filter((item) => item !== value.id),
        }),
      );
    } else if (pattern[type] === value.id) {
      setPatternDataAction({
        ...pattern,
        [type]: null,
      });
    }
  };

  function getFullElements<T>(collection: T[], ids: number[] | undefined) {
    if (!ids) return [];
    return (collection as Array<T>).filter((item: any) => ids.includes(item?.id));
  }

  // const { Craft, Projects, Gauge, Suitable, AvailableSize } = collections;
  const { Craft, Gauge, Suitable, AvailableSize } = collections;
  const [currentTab, setCurrentTab] = useState<string>(Collections.Craft);

  const { data: project_toplevel, fetchData: fetchProject_toplevel } =
    useResource('item/project_toplevel') as { data: null | { data: { id: number, name: string }[] }, fetchData: () => void };
  useEffect(() => {
    fetchProject_toplevel();
  }, []);

  const [currentProjectTopLevel, setCurrentProjectTopLevel] = useState<number | null>(null);
  useEffect(() => {
    if (project_toplevel === null) return;
    if (project_toplevel.data.length === 0) return;
    if (currentProjectTopLevel === null) {
      setCurrentProjectTopLevel(project_toplevel.data[0].id);
    }
  });

  const { data: projects, fetchData: fetchProjects } =
    useResource('item/type_of_project?fields[]=id,name,top_level.project_toplevel_id.id') as { data: null | { data: { id: number, name: string, top_level: { project_toplevel_id: { id: number } }[] }[] }, fetchData: () => void };
  useEffect(() => {
    fetchProjects();
  }, []);

  const { data: size_toplevel, fetchData } =
    useResource('item/size_toplevel') as { data: null | { data: { id: number, name: string }[] }, fetchData: () => void };
  useEffect(() => {
    fetchData();
  }, []);
  const [currentSizeTopLevel, setCurrentSizeTopLevel] = useState<number | null>(null);
  useEffect(() => {
    if (size_toplevel === null) return;
    if (size_toplevel.data.length === 0) return;
    if (currentSizeTopLevel === null) {
      setCurrentSizeTopLevel(size_toplevel.data[0].id);
    }
  });

  const { data: sizes, fetchData: fetchSizes } =
    useResource('item/size_chart?fields[]=id,size_name,toplevel.size_toplevel_id.id') as { data: null | { data: { id: number, size_name: string, toplevel: { size_toplevel_id: { id: number } }[] }[] }, fetchData: () => void };
  useEffect(() => {
    fetchSizes();
  }, []);

  const { data: designElements_toplevel, fetchData: fetchDesign_toplevel } =
    useResource('item/design_element') as { data: null | { data: { id: number, name: string, design_element_category: number[] }[] }, fetchData: () => void };
  useEffect(() => {
    fetchDesign_toplevel();
  }, []);
  const [currentDesignTopLevel, _setCurrentDesignTopLevel] = useState<number | null>(null);

  const { data: designElements_category, fetchData: fetchDesign_category } =
    useResource('item/design_element_category') as { data: null | { data: { id: number, name: string, items: number[] }[] }, fetchData: () => void };
  useEffect(() => {
    fetchDesign_category();
  }, []);
  const [currentDesignCategory, setCurrentDesignCategory] = useState<number | null>(null);

  const setCurrentDesignTopLevel = (level: number | null) => {
      _setCurrentDesignTopLevel(level);
      const toplevel = designElements_toplevel?.data.find(x => x.id === level);
      setCurrentDesignCategory(designElements_category?.data.find(x => toplevel?.design_element_category?.includes(x.id))?.id ?? null);
  }

  useEffect(() => {
    if (designElements_toplevel === null) return;
    if (designElements_toplevel.data.length === 0) return;
    if (designElements_category === null) return;
    if (designElements_category.data.length === null) return;
    if (currentDesignCategory === null) {
      setCurrentDesignTopLevel(designElements_toplevel.data[0]?.id)
    }
  });

  const { data: designElements_item, fetchData: fetchDesign_item } =
    useResource('item/design_element_item') as { data: null | { data: { id: number, name: string }[] }, fetchData: () => void };
  useEffect(() => {
    fetchDesign_item();
  }, []);

  return (
    <>
      <PageContainerStyled>
        <TabsContainer>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={currentTab}
            onChange={(_e, value) => setCurrentTab(value)}
            sx={{ borderRight: 1, borderColor: '#000', width: '40%' }}
            TabIndicatorProps={{ style: { display: 'none' } }}
            >
            <Title>{t('patternizerSteps.categorization')}</Title>
            {CategoryTab({
              value: Collections.Craft,
              text: t(`patternizerSteps.craft`),
              descr: collections.Craft.find((el) => el.id === pattern?.craft)?.name ?? "",
            })}
            {CategoryTab({
              value: Collections.Projects,
              text: t(`patternizerSteps.projects`),
              descr: getFullElements<ProjectCollectionItem>(
                collections.Projects,
                pattern?.type_of_project,
              ).map(x => x.name).join(", "),
            })}
            {CategoryTab({
              value: Collections.AvailableSize,
              text: t(`patternizerSteps.availableSizes`),
              descr: AvailableSize
                ?.filter(x => patternAvailableSize.includes(x.id))
                ?.map(x => x.size_name)
                .join(", "),
            })}
            {CategoryTab({
              value: Collections.Suitable,
              text: t(`patternizerSteps.suitable`),
              descr: getFullElements<SuitableCollectionItem>(
                collections.Suitable,
                pattern?.passer_til,
              ).map(x => x.name).join(", "),
            })}
            {CategoryTab({
              value: 'Design',
              text: t(`patternizerSteps.design`),
              descr: designElements_item?.data?.filter(x => pattern.design_elements?.includes(x.id)).map(x => x.name).join(", ") ?? "",
            })}
            {CategoryTab({
              value: "Needle",
              text: t('patternizerSteps.requiredNeedle'),
              descr: "",
            })}
            {CategoryTab({
              value: Collections.Gauge,
              text: t(`patternizerSteps.gauge`),
              descr: getFullElements<GaugeCollectionItem>(
                collections.Gauge,
                pattern?.masketett,
              ).map(x => x.thickness).join(", "),
            })}
            {CategoryTab({
              value: "Keywords",
              text: t(`patternizerSteps.keywords`),
              descr: pattern?.keywords?.join(", ") ?? "",
            })}
          </Tabs>
          <TabPanelContainer>
            <TabPanel hidden={Collections.Craft !== currentTab}>
              <Flex>
                {Craft.map((item) => {
                  const isSelected = pattern?.craft === item.id;
                  return <Fragment key={item.id}>
                    <PatternizerButtonSelect
                      // label={item?.name + " - "+ item.id}
                      label={item?.name}
                      checked={isSelected}
                      value={item}
                      onChange={() => {
                        if (!isSelected) onSelectHandler(item, 'craft');
                        else onDeleteHandler(item, 'craft');
                      }}
                    />
                  </Fragment>
                })}
              </Flex>
            </TabPanel>
            <TabPanel hidden={Collections.Projects !== currentTab} sx={{ height: '100%' }}>
              <SecondaryTabsContainer sx={{ height: '100%', margin: 0 }}>
                <Tabs
                  orientation='vertical'
                  variant='scrollable'
                  value={currentProjectTopLevel}
                  onChange={(_e, value) => setCurrentProjectTopLevel(value)}
                  sx={{ width: '30%', height: '100%' }}
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  >
                  {project_toplevel?.data?.map(level =>
                    <SubCategoryTab
                      key={level.id}
                      value={level.id}
                      label={level.name}
                    />
                  )}
                </Tabs>
                <SecondaryTabPanelContainer>
                  <SecondaryTabPanel>
                    <Flex>
                      {projects?.data
                      ?.filter(item => item?.top_level?.map(x => x.project_toplevel_id.id)?.includes(currentProjectTopLevel as number))
                      ?.map((item) => {
                        const isSelected = pattern?.type_of_project?.includes(item.id) ?? false;
                        return <Fragment key={item.id}>
                          <PatternizerButtonSelect
                            // label={item?.name + " - "+ item.id}
                            label={item?.name}
                            checked={isSelected}
                            value={item}
                            onChange={() => {
                              if (!isSelected) onSelectHandler(item, 'type_of_project');
                              else onDeleteHandler(item, 'type_of_project');
                            }}
                          />
                        </Fragment>
                      })}
                    </Flex>
                  </SecondaryTabPanel>
                </SecondaryTabPanelContainer>
              </SecondaryTabsContainer>
            </TabPanel>
            <TabPanel hidden={Collections.AvailableSize !== currentTab} sx={{ height: '100%' }}>
                  <SecondaryTabsContainer sx={{ height: '100%', margin: 0 }}>
                    <Tabs
                      orientation='vertical'
                      variant='scrollable'
                      value={currentSizeTopLevel}
                      onChange={(_e, value) => setCurrentSizeTopLevel(value)}
                      sx={{ width: '30%' }}
                      TabIndicatorProps={{ style: { display: 'none' } }}
                    >
                    {((size_toplevel as any)?.data ?? []).map((x: any) =>
                      <SubCategoryTab
                        label={x.name}
                        value={x.id}
                        key={x.id}
                      />
                    )}
                    </Tabs>
                    <SecondaryTabPanelContainer>
                      <SecondaryTabPanel>
                        <Flex>
                          {sizes?.data
                            ?.filter(item => item.toplevel.map(x => x.size_toplevel_id?.id).includes(currentSizeTopLevel as any))
                            ?.map(item =>
                            <PatternizerButtonSelect
                              key={item.id}
                              label={item.size_name}
                              value={item}
                              onChange={() => {
                                if (!patternAvailableSize?.some(x => x === item.id)) {
                                  dispatch(setAvailableSizeAction(item.id));
                                } else {
                                  if (patternAvailableSize.length === 1) return;
                                  dispatch(
                                    unsetAvailableSizeAction(item.id),
                                  );
                                }
                              }}
                              checked={!!patternAvailableSize?.find((_) => _ === item.id)}
                            />)
                            }
                        </Flex>
                      </SecondaryTabPanel>
                    </SecondaryTabPanelContainer>
                  </SecondaryTabsContainer>
                {/* <TextBlock>{`Available Sizes: ${patternAvailableSize
                  .map((_) => collections.AvailableSize.find(x => x.id === _)?.size_name)
                  .join(', ')}`}</TextBlock> */}

                {/* <DescriptionText>
                  <b>{t('patternizerSteps.availableSizesTemplateWarning')}</b>
                  {t('patternizerSteps.availableSizesTemplateDescription')}
                </DescriptionText>
                <TemplateInput
                  value={pattern.pattern_size_model}
                  onChange={({target}) => dispatch(updatePatternDataAction({ pattern_size_model: target.value }))}
                  placeholder={t('patternizerSteps.availableSizesTemplatePlaceholder')} */}
            </TabPanel>
            <TabPanel hidden={Collections.Suitable !== currentTab}>
              <Flex>
                {Suitable.map((item) => {
                  const isSelected = pattern?.passer_til?.includes(item.id) ?? false;
                  return <Fragment key={item.id}>
                    <PatternizerButtonSelect
                      // label={item?.name + " - "+ item.id}
                      label={item?.name}
                      checked={isSelected}
                      value={item}
                      onChange={() => {
                        if (!isSelected) onSelectHandler(item, 'passer_til');
                        else onDeleteHandler(item, 'passer_til');
                      }}
                    />
                  </Fragment>
                })}
              </Flex>
            </TabPanel>
            <TabPanel hidden={'Design' !== currentTab} sx={{ height: '100%' }}>
              <Flex>
                <SecondaryTabsContainer sx={{ flexGrow: 1, height: '100%' }}>
                  <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={currentDesignTopLevel}
                    onChange={(_e, value) => setCurrentDesignTopLevel(value)}
                    sx={{ width: '30%', height: '100%' }}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                  >
                    {designElements_toplevel?.data.map(x =>
                      <SubCategoryTab
                        key={x.id}
                        value={x.id}
                        label={x.name}
                      />
                    )}
                  </Tabs>
                  <SecondaryTabPanelContainer sx={{ left: '35%' }}>
                    <SecondaryTabPanel sx={{ height: '100%' }}>
                      <SecondaryTabsContainer sx={{ height: '100%' }}>
                        <Tabs
                          orientation='vertical'
                          variant='scrollable'
                          value={currentDesignCategory}
                          onChange={(_e, value) => setCurrentDesignCategory(value)}
                          sx={{ width: '45%', height: '100%' }}
                          TabIndicatorProps={{ style: { display: 'none' } }}
                        >
                          {designElements_toplevel
                            ?.data
                            ?.find(x => x.id === currentDesignTopLevel)
                            ?.design_element_category
                            ?.map(x =>
                              <SubCategoryTab
                                key={x}
                                value={x}
                                label={designElements_category?.data?.find(y => y.id === x)?.name}
                              />)}
                        </Tabs>
                        <SecondaryTabPanelContainer sx={{ left: '50%' }}>
                          <SecondaryTabPanel>
                            <Flex>
                              {designElements_category
                                ?.data
                                ?.find(x => x.id === currentDesignCategory)
                                ?.items
                                ?.map(x => {
                                  const item = designElements_item?.data?.find(y => y.id === x)
                                  if (!item) return;
                                  const isSelected = pattern.design_elements?.includes(x) ?? false;
                                  return <Fragment key={x}>
                                    <PatternizerButtonSelect
                                      label={item?.name}
                                      checked={isSelected}
                                      value={item}
                                      onChange={() => {
                                        if (!isSelected) onSelectHandler(item, 'design_elements');
                                        else onDeleteHandler(item, 'design_elements');
                                      }}
                                    />
                                  </Fragment>
                                })}
                            </Flex>
                          </SecondaryTabPanel>
                        </SecondaryTabPanelContainer>
                      </SecondaryTabsContainer>
                    </SecondaryTabPanel>
                  </SecondaryTabPanelContainer>
                </SecondaryTabsContainer>
              </Flex>
            </TabPanel>
            <TabPanel hidden={Collections.Needle !== currentTab}>
              <Flex>
                <CreateNeedle />
              </Flex>
            </TabPanel>
            <TabPanel hidden={Collections.Gauge !== currentTab}>
              <Flex>
                {Gauge.map((item) => {
                  const isSelected = pattern?.masketett?.includes(item.id) ?? false;
                  return <Fragment key={item.id}>
                    <PatternizerButtonSelect
                      label={item?.thickness + " - "+ item.id}
                      checked={isSelected}
                      value={item}
                      onChange={() => {
                        if (!isSelected) onSelectHandler(item, 'masketett');
                        else onDeleteHandler(item, 'masketett');
                      }}
                    />
                  </Fragment>
                })}
              </Flex>
            </TabPanel>
            <TabPanel hidden={"Keywords" !== currentTab}>
              <Keywords keywords={pattern.keywords} />
            </TabPanel>
          </TabPanelContainer>
        </TabsContainer>
      </PageContainerStyled>
      <NextButton align="right" onPressNext={onPressNextButton} />
    </>
  );
};
