import { InputButtonSelect } from '@/components/input-button-select';
import { setPatternDataAction } from '@/store/reducers/pattern.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {}

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
});

const Container = styled('div')({});

const Title = styled(Typography)({
  fontSize: '1.4rem',
  color: '#0F3319',
  marginBottom:'20px',
});

export const Units: FC<Props> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { UnitMeasurement } = useSelector(getCollectionDataSelector);
  const pattern = useSelector(getPatternDataSelector);

  const onChangeUnits = (id: number) => {
    if (id === pattern.unit_measurement) return;
    dispatch(
      setPatternDataAction({
        ...pattern,
        unit_measurement: id,
      }),
    );
  };

  return (
    <Container>
      <Title>{t('patternizerSteps.unitOfMeasurement')}</Title>
      <CheckboxContainer>
        {UnitMeasurement.map((el) => (
          <InputButtonSelect
            key={el.id}
            label={el.value}
            value={el}
            checked={pattern.unit_measurement === el.id}
            onChange={() => onChangeUnits(el.id)}
          />
        ))}
      </CheckboxContainer>
    </Container>
  );
};
