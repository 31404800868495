import { Collections } from '@/common/interfaces/collection.interface';
import { ItemSearchType } from '@/common/interfaces/search.interface';
import { DesignerSearchItem, PatternSearchItem, ResourceSearchItem, YarnSearchItem } from '@/store/types/search';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';
import { ItemTypeStep } from './components/item-type-step';
import { PatternFilter } from './components/pattern-filter';
import { ResourceFilter } from './components/resource-filter';
import { YarnFilter } from './components/yarn-filter';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DesignerFilter } from './components/designer-filter';
import { useTranslation } from 'react-i18next';

interface Props {
  isShow: boolean;
  itemSearchType: ItemSearchType | null;
  onChangeItemSearchType: (value: ItemSearchType | null) => void;
  onChangeAdditional: (value: number, type: Collections | string) => void;
  result: {
    patterns: PatternSearchItem[];
    yarns: YarnSearchItem[];
    resources: ResourceSearchItem[];
    designers: DesignerSearchItem[];
  };
  isResult: boolean;
}


const Container = styled('div')({});
const Title = styled('div')({});

const TextContainer = styled('div')({
  fontSize: '8vw',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  fontWeight: 600,
  fontStyle: 'uppercase',
  padding: '.5rem 1rem',
  flexDirection: 'row',
  borderBottom: '1px solid #000',
  width: '100%',
  cursor: 'pointer',
});

const FoundText = styled('div')({
  fontSize: '2.5vw',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  opacity: 0.3,
});

const arrowStyle={
  fontSize: '8vw',
  gridColumn: 2,
  verticalAlign: 'bottom'
}

export const Filter: FC<Props> = (props) => {
  const { isResult, itemSearchType, onChangeItemSearchType, onChangeAdditional, result } = props;

const { t } = useTranslation();

  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter((p) => !p);
  }

  const getTitle = () => {
    switch (itemSearchType) {
      case ItemSearchType.Pattern:
        return t('search.titles.pattern');
      case ItemSearchType.Yarn:
        return t('search.titles.yarn');
      case ItemSearchType.Needle:
        return t('search.titles.resource');
      case ItemSearchType.Resource:
        return t('search.titles.needles');
      case ItemSearchType.Designer:
        return t('search.titles.designers');
      default:
        return '';
    }
  };

  const getFoundText = () => {
    switch (itemSearchType) {
      case ItemSearchType.Pattern: {
        const count = result?.patterns?.length ?? 0;
        return t('search.patterns', { count });
      }
      case ItemSearchType.Yarn: {
        const count = result?.yarns?.length ?? 0;
        return t('search.yarns', { count });
      }
      case ItemSearchType.Resource: {
        const count = result?.resources?.length ?? 0;
        return t('search.resources', { count });
      }
      case ItemSearchType.Designer: {
        const count = result?.designers?.length ?? 0;
        return t('search.designers', { count });
      }
    }
  };

  // useEffect(() => {
  //   console.log("itemSearchType: ", itemSearchType)
  // }, [itemSearchType]);

  const EmptyMessageText = styled('section')(() => ({
    margin: 'auto',
    padding: '2rem',
    fontSize:'5vw',
    color:'gray'
  }));

  const emptyMessage = (type: ItemSearchType, data: any[]) => (
    type === itemSearchType && data.length === 0 && <EmptyMessageText>{t('search.emptyMessage')}</EmptyMessageText>
  );

  return (
    <Container>
      {isResult &&<TextContainer>
          {getTitle()}
           <Title onClick={()=>
            handleShowFilter()
          }>
            {showFilter ? <KeyboardArrowDownIcon style={arrowStyle}/> :  <KeyboardArrowRightIcon style={arrowStyle}/>}
          </Title>
        <FoundText>{getFoundText()}</FoundText>
      </TextContainer>}
      <>
        {emptyMessage(ItemSearchType.Yarn, result.yarns)}
        {emptyMessage(ItemSearchType.Pattern, result.patterns)}
        {emptyMessage(ItemSearchType.Resource, result.resources)}
        {emptyMessage(ItemSearchType.Designer, result.designers)}
      </>
      {!itemSearchType && showFilter && <ItemTypeStep onChange={onChangeItemSearchType} />}
      {showFilter && itemSearchType === ItemSearchType.Pattern && (
        <PatternFilter onChangeAdditional={onChangeAdditional} />
      )}
      {showFilter && itemSearchType === ItemSearchType.Yarn && (
        <YarnFilter onChangeAdditional={onChangeAdditional} />
      )}
      {showFilter && itemSearchType === ItemSearchType.Resource && (
        <ResourceFilter onChangeAdditional={onChangeAdditional} />
      )}
    </Container>
  );
};
