import { useCallback, useState } from 'react';

interface ResourceHook<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
  fetchData: () => Promise<void>;
  createOrUpdateData: (data: T, method?: 'PATCH' |  'POST' | 'PUT') => Promise<void>;
}

function useResource<T>(resourceUrl: string): ResourceHook<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_KNITRY_API_V2}/${resourceUrl}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      const jsonData = await response.json() as T & { data: T };
      if (!response.ok) throw new Error('Failed to fetch data');
      setData(jsonData);
    } catch (err) {
      setError('error');
    } finally {
      setLoading(false);
    }
  }, [resourceUrl]);

  const createOrUpdateData = useCallback(async (data: T, method: 'PATCH' | 'POST' | 'PUT' = 'POST') => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_KNITRY_API_V2}/${resourceUrl}`, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json() as T;
      if (!response.ok) throw new Error('Operation failed');
      setData(jsonData);
    } catch (err) {
      setError('error');
    } finally {
      setLoading(false);
    }
  }, [resourceUrl]);

  return { data, loading, error, fetchData, createOrUpdateData };
}

export default useResource;
