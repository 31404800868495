import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { FilesToUpload } from '@/store/types/resource';
import { ImgVideo } from '../img-video/img-video';

export interface FilePreviewProps {
  files?: FilesToUpload;
  onRemoveFile: (fileId: string) => void;
}

const Container = styled('span')({
  display: 'inline-block',
  minHeight: '8rem',
});

const FileItem = styled('span')({
  display: 'inline-block',
  marginRight: '1rem',
});

const ImgEl = styled(ImgVideo)({
  display: 'block',
  width: '6.25rem',
  height: '6.25rem',
  objectFit: 'cover',
  cursor: 'pointer',
});

const Title = styled('span')({
  fontSize: '1rem',
  lineHeight: '1.75rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '6.25rem',
  display: 'block',
  whiteSpace: 'nowrap',
});

export const FilePreview: FC<FilePreviewProps> = ({ files = {}, onRemoveFile }) => {
  const onClickHandler = (fileId: string) => () => onRemoveFile(fileId);

  const itemsRender = useMemo(() => {
    return Object.entries(files).map(([fileId, file]) => {
      //remove file if doesn't exist (after page reload)
      if (!(file instanceof File)) {
        onRemoveFile(fileId);
        return;
      }

      return (
        <FileItem key={fileId}>
          <ImgEl
            src={URL.createObjectURL(file)}
            onClick={onClickHandler(fileId)}
            videoProps={{
              autoPlay: true,
              muted: true,
              loop: true,
            }}
          />
          <Title>{file.name}</Title>
        </FileItem>
      );
    });
  }, [files]);
  return <Container>{itemsRender}</Container>;
};
