import { Button } from '@/components/button/button';
import { ItemContainer, YarnColorName } from '@/components/yarn-color-name';
import { getYarnColorsAction, setYarnColorAction } from '@/store/reducers/yarnivers.reducer';
import {
  getYarniversColorsSelector,
  getYarniversCurrentStepSelector,
  getYarniversDataSelector,
} from '@/store/selectors/yarnivers.selector';
import { Grid, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YarniversStepsKeys } from '../steps';
import { ButtonUploadStyled, TextStyled } from './styles';
import { setYarnColorsAction } from '@/store/sagas/yarnivers.saga';
import { Loader } from '@/components/img-video/styles';
interface Props {
  onPressNext: () => void;
}

export const Colors: FC<Props> = (props) => {
  const { onPressNext } = props;
  const currentStep = useSelector(getYarniversCurrentStepSelector);
  const yarn = useSelector(getYarniversDataSelector);
  const yarnColors = useSelector(getYarniversColorsSelector);
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const [loadingNumOfFiles, setLoadingNumOfFiles] = useState(0);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !yarn.id) {
      return;
    }
  
    const files = Array.from(event.target.files);
    setLoadingNumOfFiles(files.length);
    dispatch(
      setYarnColorsAction({        
        colors: files,
        yarnId: yarn.id as number,
        //name: file.name.split('.')[0],
      }),
    );
    console.log('files', files);
  
    event.target.value = '';
  };

  const onClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    if (currentStep === YarniversStepsKeys.Colors && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
    }
  }, [currentStep]);

  useEffect(() => {
    if (yarnColors && yarnColors.length) setLoadingNumOfFiles(0);
  }, [yarnColors]); 

  return (
    <>
      <Grid m='2rem'>
        <Typography variant='h2'>{yarn.name}</Typography>
        {!yarnColors?.length && (
          <Grid container direction='column'>
            <TextStyled variant='h5'>
              Please be advised that the name of the uploaded yarn shall determine the respective
              file names.
            </TextStyled>
            <ButtonUploadStyled onClick={onClick}>+ Upload colors from folder</ButtonUploadStyled>
          </Grid>
        )}
        <input ref={ref} type='file' hidden accept='image/*' multiple onChange={onChange} />
        <Grid container direction='row' mb='2rem'>
          {yarnColors?.map((el) => (
            <YarnColorName
              key={el.id}
              id={el.id}
              photo={el.image}
              name={el.name}
              yarnId={yarn.id as number}
            />
          ))}
        </Grid>
        {loadingNumOfFiles > 0 && (
            <Grid container direction='row' mb='2rem'>
            {Array.from({ length: loadingNumOfFiles }).map((_, index) => (
                <ItemContainer key={index}>
                    <Loader/> 
                    <img/>
                </ItemContainer>
            ))}
          </Grid>
        )}
        {!!yarnColors?.length && (
          <ButtonUploadStyled onClick={onClick}>+ Add color image</ButtonUploadStyled>
        )}
      </Grid>
      <Button style={{ margin: '2rem' }} colored onClick={onPressNext}>
        Next
      </Button>
    </>
  );
};