import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  height: '2.5rem',
  width: '100%',
  borderTop: '1px solid #000000',
  display: 'flex',
  flexDirection: 'row',
});

export const Price = styled(Typography)({
  fontWeight: 600,
});

export const Region = styled('div')({
  width: '12.5rem',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: '1px solid #000000',
});

export const DeliveryOptions = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100%',
  alignItems: 'center',
  borderRight: '1px solid #000000',
  minWidth: '12.5rem',
  padding: '0 1rem',

  '&:last-child': {
    borderRight: 0,
  },
});
