import { ItemSearchType } from '@/common/interfaces/search.interface';
import { FC } from 'react';
import { ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledButton } from './styles';

import { getMenuItems } from '@/components/header/components/slide-in-menu/menuItems';
import {
  MenuContainer,
  MenuHeader,
  HeaderLabelStyle,
  CloseButton,
  StyledList,
  MenuLinkStyle,
  MenuByline,
} from '@/components/header/components/slide-in-menu/styles';

interface Props {
  onChangeItemSearchType: (value: ItemSearchType | null) => void;
  onChangeShowFilter: () => void;
  onChangeShowFilterSlide: () => void;
  onChangeAdditional: (value: number, type: string) => void;
}

export const SlideInFilter: FC<Props> = (props) => {
  const { t } = useTranslation();
  const menuItems = getMenuItems(t);
  const { onChangeItemSearchType, onChangeAdditional, onChangeShowFilterSlide } = props;

  return (
    <MenuContainer role='presentation'>
      <MenuHeader>
        <HeaderLabelStyle>{t('search.filter')}</HeaderLabelStyle>
        <CloseButton variant='text' onClick={onChangeShowFilterSlide}>
          {t('common.close')}
        </CloseButton>
      </MenuHeader>
      <StyledList>
        {menuItems
          .filter((item) => item.ItemSearchType !== null)
          .map((item, index) => (
            <ListItem key={index} disablePadding>
              {item.active ? (
                <StyledButton
                  onClick={() => {
                    onChangeShowFilterSlide();
                    onChangeAdditional(1, 'Pattern');
                    onChangeItemSearchType(item.ItemSearchType);
                  }}
                >
                  <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                  <MenuByline active={item.active}>{item.byline}</MenuByline>
                </StyledButton>
              ) : (
                <span>
                  <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                  <MenuByline active={item.active}>{item.byline}</MenuByline>
                </span>
              )}
            </ListItem>
          ))}
      </StyledList>
    </MenuContainer>
  );
};
