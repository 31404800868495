import { Pattern } from '@/common/interfaces/pattern.interface';
import { Button } from '@/components/button/button';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Container,
  Currency,
  Description,
  PatternName,
  PriceSet,
  PriceTitle,
  Technique,
  TechniqueTitle,
} from './styles';

interface Props {
  pattern: Pattern;
  onPublish: () => void;
}

export const Info: FC<Props> = (props) => {
  const { pattern, onPublish } = props;

  const { t } = useTranslation();

  const { Technics } = useSelector(getCollectionDataSelector);

  const technics = useMemo(
    () =>
      Technics.filter((el) => pattern.technique?.includes(el.id))
        .map((el) => el.name)
        .join(', '),
    [pattern, Technics],
  );

  return (
    <Container>
      <div>
        <PatternName>{pattern.name}</PatternName>
        <Description>{pattern.description}</Description>
      </div>

      <div>
        <Technique>
          <TechniqueTitle>{t('patternizerSteps.advertisement.techniques')}: {technics}</TechniqueTitle>
        </Technique>

        <PriceTitle>{t('patternizerSteps.advertisement.price')}</PriceTitle>
        <PriceSet>
          <Currency>{pattern.price}, - NOK</Currency>

          <Button onClick={onPublish} colored>
           {t('patternizerSteps.advertisement.publish')}
          </Button>
        </PriceSet>
      </div>
    </Container>
  );
};
