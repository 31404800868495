import { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const SearchPage = lazy(() => import('./search-page'));

export const SearchPageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <SearchPage />
  </Suspense>
);
