import { styled, Typography } from '@mui/material';

export const Container = styled('div')({});

export const Label = styled(Typography)({
  marginBottom: '1rem',
});

export const Title = styled(Typography)({
  marginRight: '0.5rem',
  fontWeight: 'bold',
});
