import { Pattern } from '@/common/interfaces/pattern.interface';
import { setNeedleAction } from '@/store/reducers/collection.reducer';
import { QuantityWithSizeCollectionItemWithStage } from '@/store/types/collection';
import { Button, styled } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  id: number;
  selectedSize: number;
  quantities: QuantityWithSizeCollectionItemWithStage[] | number;
  pattern: Pattern;
  type: 'needle' | 'yarn';
}

const Container = styled('div')({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '1.875rem',
  border: '1px solid #000000',
  fontSize: '1.125rem',
  fontWeight: 600,
});

const Number = styled('div')({
  width: '3.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  borderLeft: '1px solid #000000',
  borderRight: '1px solid #000000',
});

const Control = styled(Button)({
  width: '1.875rem !important',
  minWidth: '1.875rem !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '0',
  fontSize: '1.125rem',
  fontWeight: 600,
  color: '#000',

  '&:hover': {
    borderColor: '#000',
    backgroundColor: '#f6f4ed',
  },
});

export const Quantity: FC<Props> = (props) => {
  const { selectedSize, quantities, id, pattern, type } = props;

  const dispatch = useDispatch();

  const getQuantities = (): number => {
    if (type === 'needle') return (quantities as number) || 0;
    return (
      (quantities as QuantityWithSizeCollectionItemWithStage[]).find(
        (el) => el.size_chart === selectedSize,
      )?.quantity || 0
    );
  };

  const onChange = (value: number) => {
    const quantity = getQuantities();
    if (quantity === 0 && value === -1) return;
    if (type === 'yarn') {
      // dispatch(
      // setQuantityWithSizeAction({
      //   elementId: id,
      //   quantityId: (quantities as QuantityWithSizeCollectionItemWithStage[]).find(
      //     (el) => el.size_chart === selectedSize,
      //   )?.id,
      //   sizeId: selectedSize,
      //   value: quantity + value,
      // }),
      // );
    } else if (type === 'needle') {
      //TODO: tmp solution, waiting for needle flow
      dispatch(
        setNeedleAction({
          needle: {
            id,
            quantity: quantity + value,
          },
          pattern: pattern,
        }),
      );
    }
  };

  return (
    <Container>
      <Control onClick={() => onChange(-1)}>-</Control>
      <Number>{getQuantities()}</Number>
      <Control onClick={() => onChange(1)}>+</Control>
    </Container>
  );
};
