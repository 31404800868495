import { Collections } from '@/common/interfaces/collection.interface';
import { getCollectionsAction } from '@/store/reducers/collection.reducer';
import { resetStoreAction, setCurrentStep } from '@/store/reducers/resource.reducer';
import { getCollectionLoadingSelector } from '@/store/selectors/collection.selector';
import {
    getResourceCreatedSelector,
    getResourceDataSelector,
    getResourceLoadingSelector,
    getResourceStepSelector,
} from '@/store/selectors/resource.selector';
import { ResourceSteps } from '@/store/types/resource';
import { noop } from '@/utils/noop';
import { FC, ReactNode, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CategorizationTab } from './components/categorization-tab';
import { CompleteTab } from './components/complete-tab';
import { ResourceTab } from './components/resource-tab';
import {
    Container,
    Loader,
    LoaderWrapper,
    ResourceName,
    TabEl,
    TabsContainer,
    TabsEl,
    TabsSection,
    TopContainer,
} from './styles';

export interface ResourceUploadProps {
  onSuccess?: () => void;
  resourceId?: number | null;
}

export const ResourceUpload: FC<ResourceUploadProps> = ({ onSuccess = noop, resourceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resourceCreated = useSelector(getResourceCreatedSelector);
  const isLoading = useSelector(getResourceLoadingSelector);
  const currentStep = useSelector(getResourceStepSelector);
  const collectionLoading = useSelector(getCollectionLoadingSelector);
  const { name } = useSelector(getResourceDataSelector);

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    dispatch(setCurrentStep(value as ResourceSteps));
  };

  useEffect(() => {
    dispatch(
      getCollectionsAction([
        Collections.Craft,
        Collections.ResourceContentType,
        Collections.ResourceType,
        Collections.Technics,
      ]),
    );

    return () => {
      dispatch(resetStoreAction());
    };
  }, []);

  useEffect(() => {
    if (resourceCreated) {
      onSuccess();
    }
    dispatch(resetStoreAction());
  }, [resourceCreated]);

  const STEPS: {
    id: ResourceSteps;
    label: string;
    content: ReactNode;
  }[] = [
    {
      id: ResourceSteps.RESOURCE,
      label: t('resourceUpload.resource'),
      content: <ResourceTab />,
    },
    {
      id: ResourceSteps.CATEGORIZATION,
      label: t('resourceUpload.categorization'),
      content: <CategorizationTab />,
    },
    {
      id: ResourceSteps.COMPLETE,
      label: t('resourceUpload.complete'),
      content: <CompleteTab />,
    },
  ];

  const getStepIsBlocked = (stepId: ResourceSteps): boolean => {
    switch (stepId) {
      case ResourceSteps.CATEGORIZATION: {
        return !name;
      }
      case ResourceSteps.COMPLETE: {
        return !name;
      }
      default: {
        return false;
      }
    }
  };

  return (
    <Container>
      <TopContainer>
        <ResourceName>{name}</ResourceName>
      </TopContainer>
      <TabsSection>
        <TabsContainer>
          <TabsEl
            value={currentStep}
            onChange={handleStepChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {STEPS.map(({ label, id }) => (
              <TabEl label={label} key={id} value={id} disabled={getStepIsBlocked(id)} />
            ))}
          </TabsEl>
        </TabsContainer>
      </TabsSection>
      {STEPS.map(({ id, content }) => (
        <div key={id} hidden={currentStep !== id}>
          {content}
        </div>
      ))}
      {(collectionLoading || isLoading) && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Container>
  );
};
