import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceDataFields } from '@/store/types/resource';
import { setResourceData, setCurrentStep } from '@/store/reducers/resource.reducer';
import { getResourceDataSelector } from '@/store/selectors/resource.selector';
import { FilesToUpload, ResourceSteps } from '@/store/types/resource';
import { Button } from '@/components/button/button';
import { FileUpload } from './file-upload';
import { PageContainerEl, NameInput, TextArea } from './styles';

export interface ResourceTabProps {}

export const ResourceTab: FC<ResourceTabProps> = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const resourceData = useSelector(getResourceDataSelector);
  const { name, description, files } = resourceData;

  const onInputChangeHandler = ({
    target,
  }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setResourceData({
        ...resourceData,
        [target.name]: target.value,
      }),
    );
  };

  const onFileChangeHandler = (files: FilesToUpload) => {
    dispatch(
      setResourceData({
        ...resourceData,
        [ResourceDataFields.FILES]: files,
      }),
    );
  };

  const onNextHandler = () => {
    dispatch(setCurrentStep(ResourceSteps.CATEGORIZATION));
  };

  return (
    <PageContainerEl>
      <NameInput
        type='text'
        placeholder={t('resourceUpload.typeNameResource')}
        name={ResourceDataFields.NAME}
        value={name}
        onChange={onInputChangeHandler}
      />
      <FileUpload files={files} onFileChange={onFileChangeHandler} />
      <TextArea
        name={ResourceDataFields.DESCRIPTION}
        value={description}
        onChange={onInputChangeHandler}
        placeholder={t('resourceUpload.typeDescription')}
        rows={10}
      />
      <Button onClick={onNextHandler} colored>
        {t('common.next')}
      </Button>
    </PageContainerEl>
  );
};
