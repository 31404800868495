import Logo from '@/assets/logo.svg';
import { PATH } from '@/common/constants/path';
import { getAuthorizationSelector } from '@/store/selectors/authorization.selector';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Search } from '../search';
import { HeaderMobile } from './components/header-mobile';
import { UserMenu } from './components/user-menu';
import { HeaderEl } from './styles';
import { AppBar, Toolbar, IconButton, Drawer, Box } from '@mui/material';
import { SlideInMenu } from './components/slide-in-menu';
import { SignedOut } from '@clerk/clerk-react';

export interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('tablet'));
  const { isLoggedIn } = useSelector(getAuthorizationSelector);
  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const desktopView = (
    <HeaderEl>
      <AppBar position="fixed" style={{ backgroundColor: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label={t('header.nav.menu')}
            onClick={handleOpen} 
            style={{
              color: '#000',
              fontSize: 16,
              height: 22,
              paddingLeft: 16,
              paddingTop: 14,
              backgroundColor: 'transparent',
            }}>
            {t('header.nav.menu')}
          </IconButton>
          <Drawer open={open} onClose={handleClose} style={{zIndex: 15000}}> 
            <SlideInMenu toggleDrawer={handleClose}/>
          </Drawer>
          <Box style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
            <NavLink to={PATH.HOME}>
              <img src={Logo} alt={t('main.title')} style={{ maxWidth: '130px', marginLeft: 100 }}/> 
            </NavLink>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {!isLoggedIn ? <Authorization /> : <UserMenu />}
          </Box>
        </Toolbar>
      </AppBar>
    </HeaderEl>
  );

  return (
    <>
      {isMobileView ? <HeaderMobile /> : desktopView}
      <Search />
    </>
  );
};

const Authorization = () => {
  const { t } = useTranslation();
  return (
    <>          
      <SignedOut>
        <NavLink style={{ margin: '0 10px', color: 'inherit', textDecoration: 'none'}} to={PATH.SIGNIN}>
          {t('header.nav.signin')}
        </NavLink>     
        <NavLink style={{ margin: '0 10px', color: 'inherit', textDecoration: 'none'}} to={PATH.SIGNUP}>
          {t('header.nav.createacc')}
        </NavLink>    
      </SignedOut>
    </>
  );
};
