import { RootState } from '..';

export const getPatternLoadingSelector = (state: RootState) => state.pattern.isLoading;

export const getPatternDataSelector = (state: RootState) => state.pattern.data;

export const getPatternAvailableSizesSelector = (state: RootState) => state.pattern.AvailableSize;

export const getUploadPatternParamsSelector = (state: RootState) =>
  state.pattern.uploadPatternParams;

export const getPatternStepsSelector = (state: RootState) => state.pattern.steps;
export const getRessourcesSelector = (state: RootState) => state.resourceAll.ressources;

export const getPatternLanguagesSelector = (state: RootState) => state.pattern.languagesList;

export const getPatternSelectedLanguageSelector = (state: RootState) =>
  state.pattern.selectedLanguage;

export const getPatternLanguageLoadingSelector = (state: RootState) =>
  state.pattern.languagesLoading;

export const getPatternImagesSelector = (state: RootState) => state.pattern.images;

export const getPatternDesignerSelector = (state: RootState) => state.pattern.designer;
